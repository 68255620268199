import React, { useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Row } from 'antd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
const GRID_GAP = 5

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: GRID_GAP,
  margin: `0 0 ${GRID_GAP}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightblue' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightgrey' : 'white',
  padding: GRID_GAP
})

/**
 * @param {Array} list [{ id: String, content: String }]
 */
export const DraggableList = ({ list, onChangeList, onRenderContent }) => {
  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const items = reorder(list, result.source.index, result.destination.index)

      onChangeList(items)
    },
    [onChangeList, list]
  )
  const renderItem = useCallback(
    (item, index) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <Row
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            className="draggable-item"
          >
            {onRenderContent ? onRenderContent(item) : item.content}
          </Row>
        )}
      </Draggable>
    ),
    [onRenderContent]
  )
  const renderDroppableChildren = useCallback(
    (provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={getListStyle(snapshot.isDraggingOver)}
        className="droppable-list"
      >
        {list.map(renderItem)}
        {provided.placeholder}
      </div>
    ),
    [list, renderItem]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">{renderDroppableChildren}</Droppable>
    </DragDropContext>
  )
}
