import { useMutation } from 'react-query'
import * as Sentry from '@sentry/react'

import { api, queryClient } from './api'
import { USERS_KEY } from './users'

const fetchLogin = ({ login, password }) => {
  return api.post({ path: `${USERS_KEY}/signin`, data: { login, password } })
}

const fetchLogout = () => {
  return api.post({ path: `${USERS_KEY}/signout` })
}

export function useMutateLogin() {
  return useMutation(fetchLogin, {
    onMutate: () => queryClient.clear()
  })
}

const logout = () => {
  queryClient.clear()
  Sentry.configureScope(scope => scope.setUser(null))
}

export function useMutateLogout() {
  return useMutation(fetchLogout, {
    onSuccess: logout
  })
}

// TODO: для входа без авторизации - надо будет переделать
const getToken = () => localStorage.getItem('token') || ''
const setToken = token => localStorage.setItem('token', token)
const removeToken = () => localStorage.removeItem('token')

export const auth = {
  logout,
  getToken,
  setToken,
  removeToken
}
