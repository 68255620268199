import { inboxFiltersAtom, inboxSorterAtom, inboxSettingsAtom } from './atoms'
import { selector } from 'recoil'

export const inboxSettingsSelector = selector({
  key: 'allInboxSettings',
  get: ({ get }) => {
    const inboxSettings = get(inboxSettingsAtom)
    const filters = get(inboxFiltersAtom)
    const sorter = get(inboxSorterAtom)

    return {
      ...inboxSettings,
      filters,
      sorter
    }
  }
})
