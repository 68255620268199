import { useContext, useEffect } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'

import { appConfig } from '../constants/appConfig'
import { ConfigContext } from '../contexts/configContext'
import { useRefsByCode } from '../api/reference'

export const useReferences = ({
  cities = false,
  citizenships = false,
  requestTypes = false,
  mediums = false,
  sources = false,
  inboxStates = false,
  utms = false,
  utmsQuery = ''
}) => {
  const {
    references: { setData }
  } = useContext(ConfigContext)

  const { status: statusCities, data: dataCities } = useRefsByCode(appConfig.references.cities, {
    enabled: !!cities
  })
  const { status: statusCitizenships, data: dataCitizenships } = useRefsByCode(
    appConfig.references.citizenships,
    { enabled: !!citizenships }
  )
  const { status: statusRequestTypes, data: dataRequestTypes } = useRefsByCode(
    appConfig.references.requestTypes,
    { enabled: !!requestTypes }
  )
  const { status: statusMediums, data: dataMediums } = useRefsByCode(appConfig.references.mediums, {
    enabled: !!mediums
  })
  const { status: statusSources, data: dataSources } = useRefsByCode(appConfig.references.sources, {
    enabled: !!sources
  })
  const { status: statusInboxStates, data: dataInboxStates } = useRefsByCode(
    appConfig.references.inboxStates,
    { enabled: !!inboxStates }
  )
  const { status: statusUtms, data: dataUtms } = useRefsByCode(
    appConfig.references.utms + utmsQuery,
    { enabled: !!utms }
  )

  useEffect(() => {
    if (statusCities === 'success') {
      setData({ cities: dataCities.data })
    } else if (statusCities === 'error') {
      message.error('Ошибка загрузки городов')
    }
  }, [dataCities, statusCities, setData])

  useEffect(() => {
    if (statusCitizenships === 'success') {
      setData({ citizenships: dataCitizenships.data })
    } else if (statusCitizenships === 'error') {
      message.error('Ошибка загрузки гражданств')
    }
  }, [dataCitizenships, statusCitizenships, setData])

  useEffect(() => {
    if (statusRequestTypes === 'success') {
      setData({ requestTypes: dataRequestTypes.data })
    } else if (statusRequestTypes === 'error') {
      message.error('Ошибка загрузки типов отклика')
    }
  }, [dataRequestTypes, statusRequestTypes, setData])

  useEffect(() => {
    if (statusMediums === 'success') {
      setData({ mediums: dataMediums.data })
    } else if (statusMediums === 'error') {
      message.error('Ошибка загрузки UTM меток')
    }
  }, [dataMediums, statusMediums, setData])

  useEffect(() => {
    if (statusSources === 'success') {
      setData({ sources: dataSources.data })
    } else if (statusSources === 'error') {
      message.error('Ошибка загрузки UTM меток')
    }
  }, [dataSources, statusSources, setData])

  useEffect(() => {
    if (statusUtms === 'success') {
      setData({ utms: dataUtms.data })
    } else if (statusUtms === 'error') {
      message.error('Ошибка загрузки UTM меток')
    }
  }, [dataUtms, statusUtms, setData])

  useEffect(() => {
    if (statusInboxStates === 'success') {
      setData({ inboxStates: dataInboxStates.data })
    } else if (statusInboxStates === 'error') {
      message.error('Ошибка загрузки статусов')
    }
  }, [dataInboxStates, statusInboxStates, setData])
}
useReferences.propTypes = {
  cities: PropTypes.bool,
  citizenships: PropTypes.bool,
  requestTypes: PropTypes.bool,
  mediums: PropTypes.bool,
  sources: PropTypes.bool,
  inboxStates: PropTypes.bool,
  utms: PropTypes.bool,
  utmsQuery: PropTypes.string
}
