import { Typography, Button, message, Modal, Row, Col } from 'antd'
import React, { useContext, useEffect, useMemo, useState } from 'react'
const { Text } = Typography
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { useMutateDeleteOrgUnit } from '../../../../../api/orgStructure'
import { FolderFilled } from '@ant-design/icons'
import orgDeleteUnitImg from '../../../../../img/orgStructure/remove.svg'
import { noOrgStructureName, penultimateIndex } from '../../../../../constants/orgStructure'
import { useDepartmentsQuery } from '../../../../../api/department'

function DeleteUnit({ selectedUnits }) {
  const orgStructure = useContext(OrgStructureContext)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [departments, setDepartments] = useState([])

  const {
    data: departmentsData,
    isSuccess: isSuccessDepartments,
    isError: isErrorDepartments
  } = useDepartmentsQuery(
    { parentOrganizationUnits: [selectedUnits?.[selectedUnits?.length - 1]?._id] },
    { enabled: isModalVisible }
  )

  useEffect(() => {
    if (isSuccessDepartments && departmentsData?.data) {
      setDepartments(departmentsData?.data)
    }
    if (isErrorDepartments) message.error('Ошибка загрузки орг. единиц')
  }, [departmentsData, isSuccessDepartments, isErrorDepartments])

  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure,
    isLoading: isLoadingOrgStructure
  } = useMutateDeleteOrgUnit()

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
      setIsModalVisible(false)
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  const handleClose = () => setIsModalVisible(false)

  const isDeletedUnitExist = useMemo(
    () =>
      !!(
        selectedUnits?.[selectedUnits?.length - penultimateIndex]?.children || orgStructure.units
      )?.find(unit => unit.deleted),
    [orgStructure, selectedUnits]
  )
  const hasChildren = useMemo(
    () => !!selectedUnits?.[selectedUnits?.length - 1]?.children?.length,
    [selectedUnits]
  )
  const isDeletable = useMemo(
    () =>
      (!selectedUnits?.[selectedUnits?.length - 1]?.children?.length &&
        selectedUnits?.length === 1) ||
      selectedUnits?.length > 1,
    [selectedUnits]
  )

  const handleSave = () => {
    const units = { children: [...orgStructure.units] }

    const data = {
      tree: {
        layers: orgStructure.layers,
        units: units.children
      },
      units: [selectedUnits?.[selectedUnits.length - 1]?._id]
    }
    mutateOrgStructure(data)
  }
  const withChildren = isDeletedUnitExist ? (
    // заменить у всех рестиков выбранный _id на _id соседа у которого deleted=true
    <Text>
      Вложенная структура у &quot;{selectedUnits?.[selectedUnits.length - 1]?.name}&quot; будет
      перенесена в &quot;{noOrgStructureName}&quot;.
    </Text>
  ) : (
    // установить выбраному орг юниту deleted=true и name=''
    <Text>
      Название &quot;{selectedUnits?.[selectedUnits.length - 1]?.name}&quot; будет изменено на
      &quot;{noOrgStructureName}&quot;.
    </Text>
  )

  const withoutChildren = departments.length ? (
    // удалить у всех рестиков выбранный _id
    <>
      <Text>
        Прикрепленные пользователи сохранят привязку. Следующие орг единицы будут прикреплены к
        &quot;
        {selectedUnits?.[selectedUnits.length - penultimateIndex]?.name || noOrgStructureName}
        &quot;:
      </Text>
      <Row className="orgUnit-departments">
        <Col>
          {departments.map(department => (
            <Row key={department._id}>
              {department.name} - {department.address}
            </Row>
          ))}
        </Col>
      </Row>
    </>
  ) : (
    // удалить выбранный _id
    <Text>
      Вложенные структуры не найдены. Вы уверены что хотите удалить &quot;
      {selectedUnits?.[selectedUnits.length - 1]?.name}&quot;
    </Text>
  )

  if (!selectedUnits?.length || !isDeletable) return null
  return (
    <div className="">
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsModalVisible(true)}
      >
        <img src={orgDeleteUnitImg} alt="button remove unit" />
        <Text>Удалить</Text>
      </Button>
      <Modal
        title={
          <div className="modal-title">
            <FolderFilled />
            Удалить {selectedUnits?.[selectedUnits.length - 1]?.name}
          </div>
        }
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={handleClose}
        className=""
        okText="Удалить"
        okButtonProps={{ loading: isLoadingOrgStructure }}
        cancelButtonProps={{ disabled: isLoadingOrgStructure }}
        cancelText="Отмена"
      >
        {hasChildren ? withChildren : withoutChildren}
      </Modal>
    </div>
  )
}
export default DeleteUnit
