import { atom } from 'recoil'
import { initialPagination } from '../helpers'

//  инициализация фильтров из 'localStorage'
const filteredInfo = localStorage.getItem('filterModel') || '{}'
const sortedInfo = localStorage.getItem('sortModel') || '{}'

export const initPageSettings = {
  // кандидаты рекрутера
  inbox: {
    current: initialPagination.current,
    pageSize: initialPagination.pageSize,
    search: '',
    mine: false,
    activeTab: ''
  },
  // поиск кандидата
  find: {
    findNumber: '',
    activeTab: ''
  },
  // кандидаты директора
  hiringManager: {
    search: '',
    activeTab: ''
  }
}
const localStorageEffect =
  key =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const findSettingsAtom = atom({
  key: 'findSettings',
  default: initPageSettings.find
})

export const hiringManagerSettingsAtom = atom({
  key: 'hiringManagerSettings',
  default: initPageSettings.hiringManager
})

export const inboxSettingsAtom = atom({
  key: 'inboxSettings',
  default: initPageSettings.inbox
})

export const inboxFiltersAtom = atom({
  key: 'inboxFilter',
  default: JSON.parse(filteredInfo),
  effects: [localStorageEffect('filterModel')]
})

export const inboxSorterAtom = atom({
  key: 'inboxSort',
  default: JSON.parse(sortedInfo),
  effects: [localStorageEffect('sortModel')]
})
