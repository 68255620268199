import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'

const ONBOARDING_KEY = 'onboarding'
const mutateOptions = {
  onSuccess: () => {
    queryClient.refetchQueries(ONBOARDING_KEY)
  }
}
const getOnboardingCheck = () => {
  return api.get({ path: `${ONBOARDING_KEY}/check` })
}

const postOnboardingSeed = () => {
  return api.post({ path: `${ONBOARDING_KEY}/seed` })
}

const postOnboardingRetrySend = () => {
  return api.post({ path: `${ONBOARDING_KEY}/resend` })
}

const deleteOnboarding = () => {
  return api.del({ path: `${ONBOARDING_KEY}/delete` })
}

export function useGetOnboardingCheck(options) {
  return useQuery([ONBOARDING_KEY], getOnboardingCheck, options)
}

export function useMutateOnboardingSeed() {
  return useMutation(postOnboardingSeed, mutateOptions)
}

export function useMutateOnboardingRetrySend() {
  return useMutation(postOnboardingRetrySend, mutateOptions)
}

export function useMutateDeleteOnboarding() {
  return useMutation(deleteOnboarding, mutateOptions)
}
