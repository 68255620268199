import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'

import layersIcon from '../../../../../img/orgStructure/layers-blue.svg'
import UnitItem from './UnitItem'
import { simpleReducer } from '../../../../../helpers'
import AddUnitItem from '../Actions/AddUnitItem'
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'

function OrgStructureUnit({ layerIndex, data, onSelect, selectedUnits, lastSelectedId }) {
  const { layers } = useContext(OrgStructureContext)
  const initialState = {
    currentItem: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleSelect = item => {
    setState({ currentItem: item })
    onSelect(layerIndex, item)
  }
  useEffect(() => {
    if (!selectedUnits?.[layerIndex]) {
      setState({ currentItem: null })
    }
  }, [selectedUnits, layerIndex])

  const isShownAddItem = useMemo(() => {
    const prevIndex = layerIndex - 1

    if (layerIndex === 0) {
      return true
    } else if (selectedUnits?.[prevIndex]) {
      return true
    }
    return false
  }, [selectedUnits, layerIndex])

  return (
    <div className="layer">
      <div className="layerHeader">
        <img src={layersIcon} alt="layer" />
        {layers[layerIndex]}
      </div>
      <div className="layerBody">
        {data?.map(unit => (
          <UnitItem
            key={unit._id}
            isLastSelected={unit._id === lastSelectedId}
            isLastLayer={layers?.length - 1 === layerIndex}
            unit={unit}
            onSelect={handleSelect}
            currentItem={state.currentItem}
          />
        ))}
        {isShownAddItem && <AddUnitItem layerIndex={layerIndex} selectedUnits={selectedUnits} />}
      </div>
    </div>
  )
}

OrgStructureUnit.defaultProps = {
  onSelect: () => {},
  selectedUnits: null,
  layer: null,
  layers: [],
  data: null
}

OrgStructureUnit.propTypes = {
  layerIndex: PropTypes.number,
  lastSelectedId: PropTypes.string,
  onSelect: PropTypes.func,
  selectedUnits: PropTypes.array,
  data: PropTypes.array
}

export default OrgStructureUnit
