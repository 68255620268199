import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import { colSettings } from '../../../constants'

const EmailItem = ({
  size = 'middle',
  labelCol = colSettings.full,
  wrapperCol = colSettings.full,
  labelAlign = 'right',
  required = false,
  disabled = false,
  label = 'Email',
  autoComplete = 'on',
  onChange = () => {},
  name = 'email',
  placeholder = 'mail@mail.ru'
}) => {
  return (
    <Form.Item
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      labelAlign={labelAlign}
      rules={[
        {
          required,
          message: 'Укажите почту'
        }
      ]}
    >
      <Input
        size={size}
        type="email"
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        onChange={onChange}
      />
    </Form.Item>
  )
}

EmailItem.propTypes = {
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  size: PropTypes.oneOf(['middle', 'small', 'large']),
  labelAlign: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string
}

export default EmailItem
