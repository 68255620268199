import React from 'react'
import { Switch, Form } from 'antd'
import PropTypes from 'prop-types'
import { colSettings } from '../../../constants'

function ActiveFlagItem({
  size = 'middle',
  labelCol = colSettings.six,
  wrapperCol = colSettings.full
}) {
  return (
    <Form.Item
      label="Активен"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name="active"
      labelAlign="left"
      valuePropName="checked"
      initialValue
    >
      <Switch size={size} />
    </Form.Item>
  )
}
ActiveFlagItem.propTypes = {
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  size: PropTypes.oneOf(['middle', 'small', 'large'])
}
export default ActiveFlagItem
