import { useMutation } from 'react-query'
import { api, queryClient } from '../api'
import { PLAN_KEY, PLANS_KEY } from '../plan'

const putPlanComment = ({ id, data }) => {
  return api.put({ path: `plans/${id}/comments`, data })
}

const deletePlanComment = ({ id, commentId }) => {
  return api.del({ path: `plans/${id}/comments/${commentId}` })
}

const patchPlanComment = ({ id, commentId, data }) => {
  return api.patch({ path: `plans/${id}/comments/${commentId}`, data })
}

const onSuccessMutatePlanComment = data => {
  // Один и тот же план может запрашиваться по ид или периоду. Обновим сразу 2 кэша для одного плана.
  queryClient.setQueryData([PLAN_KEY, data.data?._id], { data: data.data })
  queryClient.setQueryData([PLAN_KEY, `${data.data?.year}/${data.data?.month}`], {
    data: data.data
  })
  // @TODO setQueryData([PLANS_KEY, ...
  queryClient.refetchQueries(PLANS_KEY)
}

export function useMutateCreatePlanComment() {
  return useMutation(putPlanComment, {
    onSuccess: onSuccessMutatePlanComment
  })
}

export function useMutateDeletePlanComment() {
  return useMutation(deletePlanComment, {
    onSuccess: onSuccessMutatePlanComment
  })
}

export function useMutateUpdatePlanComment() {
  return useMutation(patchPlanComment, {
    onSuccess: onSuccessMutatePlanComment
  })
}
