import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

function ActionButton(props) {
  return (
    <Button
      key={props.actionId}
      type={props.styles.type}
      className={props.styles.className}
      onClick={() => props.onAction(props.actionId)}
      disabled={props.disabled}
    >
      {props.message}
    </Button>
  )
}
export default ActionButton

ActionButton.propTypes = {
  actionId: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    className: PropTypes.string, // 'grey', 'green', 'red' - colors for button
    type: PropTypes.oneOf(['default', 'primary', 'text'])
  }).isRequired,
  message: PropTypes.string.isRequired,
  onAction: PropTypes.func,
  disabled: PropTypes.bool
}
ActionButton.defaultProps = {
  styles: {
    className: '',
    type: 'default'
  },
  onAction: () => {},
  disabled: false
}
