import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'

import { candidateShape } from '../../../../../constants/propTypesShapes'

const { Text } = Typography

const CandidatePreferences = ({ title, candidate, wrapperClass }) => {
  return (
    <div className={wrapperClass}>
      <Text type="secondary">{title}</Text>
      <div className="item">Желаемая ставка: {candidate.preferredRate}</div>
      <div className="item">Желаемое место работы: {candidate.preferredDepartment}</div>
      <div className="item">Время работы: {candidate?.preferredTime?.join(', ') || ''}</div>
      <div className="item">Комментарий для директора: {candidate.comment}</div>
    </div>
  )
}

CandidatePreferences.defaultProps = {
  title: 'Заполнено рекрутером:',
  candidate: {},
  wrapperClass: ''
}

CandidatePreferences.propTypes = {
  title: PropTypes.string,
  candidate: candidateShape,
  wrapperClass: PropTypes.string
}

export default CandidatePreferences
