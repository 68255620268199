import { message, Modal } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { appConfig } from '../../../../constants/appConfig'
import { ConfigContext } from '../../../../contexts/configContext'
import { useMutateSettingsByCode } from '../../../../api/setting'
import { useMutateOnboardingSeed } from '../../../../api/testData'
import { withFeatureToggle } from '../../../../components/Common/FeatureToggle'

function SeedDataModal() {
  const [isModalVisible, setIsModalVisible] = useState(true)
  const {
    settings: { data: settingsData }
  } = useContext(ConfigContext)

  const { mutate: saveSettingByCode } = useMutateSettingsByCode()

  const saveSeedSetting = useCallback(() => {
    saveSettingByCode({
      code: appConfig.settings.seedPopup,
      value: 'true'
    })
  }, [saveSettingByCode])

  const {
    mutate: mutateOnboardingSeed,
    isSuccess: isSuccessOnboardingSeed,
    isError: isErrorOnboardingSeed,
    isLoading: isLoadingOnboardingSeed
  } = useMutateOnboardingSeed()

  useEffect(() => {
    if (isSuccessOnboardingSeed) {
      message.info('Тестовые данные добавлены')
      saveSeedSetting()
    } else if (isErrorOnboardingSeed) {
      message.error('Ошибка добавления тестовых данных')
    }
  }, [saveSeedSetting, isSuccessOnboardingSeed, isErrorOnboardingSeed])

  const handleOk = () => {
    setIsModalVisible(false)
    mutateOnboardingSeed()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    saveSeedSetting()
  }

  if (settingsData[appConfig.settings.seedPopup] === 'true') return null

  return (
    <Modal
      title="Создание тестовых данных"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className=""
      okText="Добавить тестовые данные"
      okButtonProps={{ loading: isLoadingOnboardingSeed }}
      cancelButtonProps={{ disabled: isLoadingOnboardingSeed }}
      cancelText="Пропустить"
    >
      Вы можете создать тестовых кандидатов и пользователей, чтобы протестировать систему и
      ознакомиться с функционалом каждой роли. Если сейчас Вы пропустите этот шаг, то вернуться к
      созданию можно будет на экране Настройки.
    </Modal>
  )
}

export default withFeatureToggle(SeedDataModal, appConfig.features.testData)
