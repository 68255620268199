import React, { useCallback } from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { colSettings } from '../../../constants'

const PhoneItem = ({
  className = '',
  label = 'Телефон',
  name = 'phone',
  labelCol = colSettings.full,
  wrapperCol = colSettings.full,
  labelAlign = 'right',
  required = false,
  autoComplete = 'off',
  onChange
}) => {
  const PHONE_VALIDATION_MESSAGE = 'Укажите телефон в формате +7 (000) 123 45 67'

  const validatePhone = (rule, value, callback) => {
    !(value && value.replace(/\D/g, '').length === 11)
      ? callback(new Error(PHONE_VALIDATION_MESSAGE))
      : callback()
  }

  const handleChange = useCallback(() => onChange?.(), [onChange])

  return (
    <Form.Item
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      name={name}
      rules={[
        {
          required,
          message: PHONE_VALIDATION_MESSAGE,
          validator: required ? validatePhone : undefined
        }
      ]}
    >
      <NumberFormat
        format="+# (###) ### ## ##"
        allowEmptyFormatting
        mask="_"
        className={`ant-input ${className}`}
        autoComplete={autoComplete}
        onChange={handleChange}
      />
    </Form.Item>
  )
}

PhoneItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  labelAlign: PropTypes.oneOf(['left', 'right']),
  required: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string
}

export default PhoneItem
