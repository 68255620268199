import { lazy } from 'react'

/**
 * Layout components
 */
export { default as Header } from './Layout/Header'
export { default as Sidebar } from './Layout/Sidebar'

/**
 * Candidate components
 */
export { default as CandidateItem } from './Candidate/CandidateItem'
export { default as CandidatePanel } from './Candidate/CandidatePanel'
export { default as ProfileCreate } from './Candidate/ProfileCreate'
export { default as CandidateGroupMenu } from './Candidate/CandidateGroupMenu'
export { default as Resume } from './Candidate/Resume'
export { default as History } from './Candidate/History'
export { default as CurrentSituation } from './Candidate/CurrentSituation'
export { default as CandidateRejectForm } from './Candidate/CandidateRejectForm'
export { default as RecallForm } from './Candidate/RecallForm'
export { default as CandidateDrawer } from './Candidate/CandidateDrawer'
// InfoBlock
export { default as CandidateInfo } from './Candidate/InfoBlock/CandidateInfo'
export { default as CandidateProfileEdit } from './Candidate/InfoBlock/CandidateProfileEdit'
// PreferencesBlock
export { default as CandidatePreferences } from './Candidate/PreferencesBlock/CandidatePreferences'
export { default as CandidatePreferencesEdit } from './Candidate/PreferencesBlock/CandidatePreferencesEdit'
// ActionsBlock
export { default as Actions } from './Candidate/ActionsBlock/Actions'
export { default as ActionForm } from './Candidate/ActionsBlock/ActionForm'
export { default as ActionButton } from './Candidate/ActionsBlock/ActionButton'

/**
 * Department components
 */
export { default as DepartmentsListWithPlans } from './Departments/DepartmentsListWithPlans'
export { default as NewDepartment } from './Departments/NewDepartment'

/**
 * OrgStructure components
 */
export { default as OrgStructureLayers } from './OrgStructure/Layers/OrgStructureLayers'
export { default as OrgStructureUnits } from './OrgStructure/Units/OrgStructureUnits'
export { default as EditLayers } from './OrgStructure/Actions/EditLayers'

/**
 * Integrations components
 */
export { default as IntegrationsStatus } from './Integrations/IntegrationsStatus'
export { default as IntegrationsUsers } from './Integrations/IntegrationsUsers'

/**
 * Other components
 */
export { default as SearchFilter } from './SearchFilter/SearchFilter'
export { default as PlanFactBlock } from './PlanFactBlock/PlanFactBlock'
export { default as SchedulerCustom } from '../../../components/Scheduler/SchedulerCustom'
export { default as PlanningPersonal } from './PlanningPersonal/PlanningPersonal'
export { default as PlanningStatus } from './PlanningStatus/PlanningStatus'
export { default as PasswordChange } from './PasswordChange/PasswordChange'
export { default as WorkTimeScheduler } from './WorkTimeScheduler/WorkTimeScheduler'
export const ExcelData = lazy(() => import('./ExcelData/ExcelData'))
export { default as EditingParagraph } from './EditingParagraph/EditingParagraph'
export { default as SortableStates } from './SortableStates/SortableStates'
