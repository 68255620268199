import { appConfig } from '../constants/appConfig'
import { getEnvironmentByHost } from './index'
export const parseArray = value => (value ? JSON.parse(value) : [])

export const stringifyRejectionSetting = (list = []) =>
  JSON.stringify(list.filter(item => item.trim().length))

export function getSaveMessage(data) {
  let target
  switch (true) {
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.candidate):
      target = ' кандидата'
      break
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.department):
      target = ' директора'
      break
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.recruiter):
      target = ' рекрутера'
      break
    default:
      target = ''
  }
  return `Настройка отказов${target} сохранена`
}

export function getSentryOptions() {
  const environment = getEnvironmentByHost()

  return {
    dsn: 'https://821549adadef4d388b10fa8a5568ad31@sentry.improvity.ru/6',
    environment,
    release: 'solvopro@' + window.solvopro?.version,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  }
}

export const closeIframe = () => {
  /**
   * отправляем сообщение нашему расширению чтобы удалить iframe с приложением из дома стороннего сайта
   */
  window.parent?.postMessage(
    { name: appConfig.externalButtonCodes.closeSolvoproExternalButton },
    '*'
  )
}

/**
 * Сравниваем значения в 'state' и 'context' по ключу 'key'
 * Если различаются то сохраняем значения из 'state' в БД
 *
 * @param {String} key
 * @param {Object} state
 * @param {Object} context
 * @param {Function} updateData
 * @param {Function} updateValue - необязательный
 */
export const checkSettingsChangesAndUpdate = async ({
  key,
  state,
  context,
  updateData,
  updateValue
}) => {
  if (!key || !state || !context || !updateData) {
    return console.error('Проверьте что корректно передали все параметры')
  }
  const value = updateValue ? updateValue(state[key]) : state[key]
  if (value !== context[key]) {
    await updateData({
      code: key,
      value
    })
  }
}
