import React, {
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense,
  useMemo,
  useCallback,
  useReducer
} from 'react'
import './Actions.css'
import { appConfig } from '../../../../../constants/appConfig'
import { ActionButton, ActionForm } from '../../index'
import { Button, Form, message, Spin } from 'antd'
import { generatePath, useHistory } from 'react-router-dom'
import moment from 'moment'

import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { useMutateFlowAction } from '../../../../../api/flow/candidate'
import { CANDIDATE_PAGE } from '../../../../../constants/routes'
import {
  getDepartmentListPropsByCandidate,
  getScheduleProps,
  isActionSameState
} from '../../../../../helpers/action'
import { UserContext } from '../../../../../contexts/userContext'
import { areArraysIntersect, simpleReducer } from '../../../../../helpers'
import { useMutateReuseCandidate } from '../../../../../api/candidate'
import { UtmModal } from '../UtmModal'
import { useReferences } from '../../../../../hooks/useReferences'

const RecruiterActions = lazy(() => import('./RecruiterActions'))
const HiringManagerActions = lazy(() => import('./HiringManagerActions'))

function Actions({ isSearch }) {
  const [utmForm] = Form.useForm()
  const history = useHistory()
  const [action, setAction] = useState(null)
  const { candidate } = useContext(CandidatePanelContext)
  const { user, isHiringManager, isManager, isRecruiter } = useContext(UserContext)
  const isValidRole = useMemo(
    () => isHiringManager || isManager || isRecruiter,
    [isHiringManager, isManager, isRecruiter]
  )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [utms, setUtms] = useReducer(simpleReducer, {
    requestType: null,
    utm_medium: null
  })

  useReferences({
    requestTypes: true,
    utms: true,
    utmsQuery: utms.requestType
  })

  // Метод апи для работы с flow actions
  const { mutate, data: dataMutate, isSuccess, isError, isLoading } = useMutateFlowAction()

  useEffect(() => {
    if (isSuccess) {
      // При успешном флоу переходим на соответствующий роут текущей роли.
      if (isHiringManager) {
        // В случае удачного флоу директора, для некоторых статусов не нужен переход на список
        const target = [
          appConfig.statuses.values.interviewScheduled,
          appConfig.statuses.values.cameForAnInterview
        ]
        if (target.includes(dataMutate.data.candidate.state?.name)) {
          setAction(null)
        } else {
          history.push(generatePath(CANDIDATE_PAGE, { id: null }))
        }
      } else history.push(generatePath(CANDIDATE_PAGE, { id: null }))
    } else if (isError) {
      message.error('Ошибка при переводе кандидата в другой статус')
    }
  }, [isSuccess, isError, history, dataMutate, isHiringManager])

  // Обработчик нажатия на кнопку экшена
  const handleAction = (action, payload) => {
    if (action?.executeImmediately) return postAction(action, {})
    if (!action) {
      setAction(null)
    } else if (!payload) {
      setAction(action)
    } else {
      postAction(action, payload)
    }
  }

  // Отправка данных в апи
  const postAction = async (act, payload) => {
    const data = {
      candidateId: candidate._id,
      ...payload
    }
    const path = (act.prefixUrl || appConfig.workFlows.candidate.prefixUrl) + act.actionId

    await mutate({ path, data })
  }
  const {
    mutate: reuseCandidate,
    isLoading: isLoadingReuseCandidate,
    isError: isErrorReuseCandidate,
    isSuccess: isSuccessReuseCandidate
  } = useMutateReuseCandidate()

  useEffect(() => {
    if (isErrorReuseCandidate) {
      message.error('Ошибка переиспользования кандидата')
    }
    if (isSuccessReuseCandidate) {
      message.success('Кандидат успешно переиспользован')
    }
  }, [isErrorReuseCandidate, isSuccessReuseCandidate])

  const handleReUseCandidate = useCallback(async () => {
    const { requestType, utm_source, utm_medium } = utmForm.getFieldsValue()
    const data = {
      requestType: requestType || null,
      utm_source: utm_source || null,
      utm_medium: utm_medium || null,
      utm_campaign: null,
      utm_term: null
    }
    setIsModalVisible(false)
    utmForm.setFieldsValue({
      requestType: null,
      utm_medium: null,
      utm_source: null
    })
    await reuseCandidate({ id: candidate?._id, data })
  }, [utmForm, candidate, reuseCandidate])

  const handleSetRequestType = value => {
    setUtms({
      requestType: value,
      utm_medium: null
    })
    utmForm.setFieldsValue({
      utm_medium: null,
      utm_source: null
    })
  }
  const handleSetUtmMedium = value => {
    setUtms({
      ...utms,
      utm_medium: value
    })
    utmForm.setFieldsValue({
      utm_source: null
    })
  }

  // Непосредственная отрисовка кнопки экшена. Набор кнопок лежит в соответствующем роли компоненте (_ROLE_Actions)
  const renderButton = action => {
    const message = isActionSameState(action.actionId, candidate.state?.name)
      ? 'Перепланировать собеседование'
      : action.message
    const calls = candidate?.possibleActions?.length
      ? [appConfig.workFlows.call.actions.later, appConfig.workFlows.call.actions.missed]
      : []
    const candidateActions =
      isSearch && !candidate?.activeStatus
        ? []
        : candidate?.possibleActions
            ?.filter(action => areArraysIntersect(action.roles, user.roles))
            ?.map(action => action?.name) || []

    if ([...candidateActions, ...calls]?.includes(action.actionId)) {
      const handleOnAction = () => handleAction(actionData)
      let actionData = {
        ...action,
        roles: candidate?.possibleActions?.find(action => action.name === action.actionId)?.roles
      }
      return (
        <ActionButton
          key={action.actionId}
          {...action}
          message={message}
          onAction={handleOnAction}
        />
      )
    }
  }

  // Для экшенов Интервью используется календарь. Футер календаря с кнопками.
  const renderFooter = (action, payload) => {
    const { start, end } = payload.events?.find(e => e.eventType === 'reserve') || {}
    const startDate = start
      ?.clone()
      .utcOffset(action?.department?.timeOffset || moment().utcOffset(), true)
      .utc()
    const data = {
      /**
       * Чтобы передать в бэк время в UTC, учитываем timeOffset
       * Если в экшене не указан департамент, значит собеседование хотят в локальном времени компьютера
       * Пример: "Интервью с HR" для АУП. У юзера нет департамента и его никто не выбирает,
       * значит собеседование по локальному времени юзера
       */
      interviewStart: startDate?.format(),
      interviewEnd: end
        ?.clone()
        .utcOffset(action?.department?.timeOffset || moment().utcOffset(), true)
        .utc()
        .format(),
      ...(action.department?._id && {
        // если есть РИ то РР надо брать из кандидата так как в экшине будет РИ
        departmentId: action?.interviewerDepartmentId
          ? candidate?.application.department
          : action.department._id,
        interviewerDepartmentId:
          action.department?.plan?.interviewerDepartment || action?.interviewerDepartmentId
      })
    }
    return (
      <Spin spinning={isLoading} size="large">
        <div className="btn-wrap mt-3">
          <ActionButton
            {...action}
            message="Записать"
            styles={{
              type: 'primary',
              className: 'green'
            }}
            onAction={() => handleAction(action, data)}
            disabled={!start || moment().subtract(1, 'days').endOf('day').isAfter(startDate)}
          />
          {action.department?._id && (
            <ActionButton
              {...action}
              message="Назад к списку"
              styles={{ className: 'green' }}
              onAction={() => handleAction({ ...action, department: null, step: 1 }, null)}
            />
          )}
        </div>
      </Spin>
    )
  }
  const handleReuse = useCallback(() => {
    if (user.agency) handleReUseCandidate()
    else setIsModalVisible(true)
  }, [handleReUseCandidate, user.agency])

  // Общие для всех экшенов Интервью пропсы календаря и формы выбора ресторана
  const scheduleProps = getScheduleProps({ footer: renderFooter })
  const departmentListProps = getDepartmentListPropsByCandidate(candidate)

  if (!isValidRole) return null
  return (
    <div className="recruiter-actions mt-3">
      {action ? (
        <ActionForm
          action={action}
          candidate={candidate}
          onAction={handleAction}
          {...scheduleProps}
          {...departmentListProps}
          interview={
            !isActionSameState(action.actionId, candidate.state?.name) &&
            candidate?.state?.interview
          }
        />
      ) : (
        <Spin spinning={isLoading} size="large">
          <Suspense fallback={<></>}>
            {isSearch && !candidate?.activeStatus && (
              <div className="btn-wrap mt-3">
                <Button type="primary" onClick={handleReuse} loading={isLoadingReuseCandidate}>
                  Переиспользовать
                </Button>
              </div>
            )}
            {isRecruiter && <RecruiterActions renderButton={renderButton} />}
            {isHiringManager && (
              <HiringManagerActions
                renderButton={renderButton}
                handleAction={handleAction}
                isSearch={isSearch}
              />
            )}
          </Suspense>
        </Spin>
      )}
      <UtmModal
        visible={isModalVisible}
        onOk={handleReUseCandidate}
        onCancel={() => setIsModalVisible(false)}
        form={utmForm}
        requestType={utms.requestType}
        utmMedium={utms.utm_medium}
        onRequestTypeChange={handleSetRequestType}
        onUtmMediumChange={handleSetUtmMedium}
      />
    </div>
  )
}
export default Actions
