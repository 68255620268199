import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, message, Row, Spin } from 'antd'
import { useSetRecoilState } from 'recoil'
import addCandidate from '../../../../img/addCandidate.png'
import './ProfileCreate.less'
import { appConfig } from '../../../../constants/appConfig'
import {
  BirthAndAgeItem,
  CitizenshipItem,
  CommentItem,
  DadataAddressItem,
  EmailItem,
  FioItem,
  PhoneItem,
  RequestTypeItem,
  SysVacanciesItem,
  UtmMediumItem,
  UtmSourceItem
} from '../FormItems/CandidateProfileItems'
import {
  useFindSimilar,
  useMutateCreateCandidate,
  useMutateReuseCandidate
} from '../../../../api/candidate'
import { UserContext } from '../../../../contexts/userContext'
import { useReferences } from '../../../../hooks/useReferences'
import { debounce, simpleReducer } from '../../../../helpers'
import { getValidSysVacancies } from '../../../../helpers/vacancy'
import { useSystemVacancies } from '../../../../hooks/useSystemVacancies'
import { hiringManagerSettingsAtom, inboxSettingsAtom } from '../../../../recoil/atoms'
import CandidateDuplicateItem from './Duplicate/CandidateDuplicateItem'
import { UtmModal } from './UtmModal'

const initialState = {
  vacancyList: [],
  currentVacancy: null,
  dadataAddress: null,
  utms: {
    requestType: null,
    utm_medium: null
  },
  phone: undefined,
  selectedCandidate: undefined,
  candidates: []
}

const ProfileCreate = props => {
  const setHiringManagerSettings = useSetRecoilState(hiringManagerSettingsAtom)
  const setInboxSettings = useSetRecoilState(inboxSettingsAtom)
  const { isHiringManager, user } = useContext(UserContext)
  const [form] = Form.useForm()
  const [utmForm] = Form.useForm()
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const isExternalHiringManager = useMemo(() => Boolean(user.agency), [user])

  useReferences({
    citizenships: true,
    requestTypes: true,
    utms: true,
    utmsQuery: state.utms.requestType
  })

  /**
   * Create candidate
   */
  const { mutate, status, isLoading } = useMutateCreateCandidate()

  useEffect(() => {
    if (status === 'success') {
      setHiringManagerSettings(oldValues => ({
        ...oldValues,
        activeTab: null
      }))
      setInboxSettings(oldValues => ({
        ...oldValues,
        activeTab: null
      }))
      props.onSave()
    }
    if (status === 'error') {
      message.error('Ошибка добавления кандидата')
    }
  }, [setInboxSettings, setHiringManagerSettings, status, props])

  const {
    mutate: reuseCandidate,
    isLoading: isLoadingReuse,
    isSuccess: isSuccessReuse,
    isError: isErrorReuse
  } = useMutateReuseCandidate()

  useEffect(() => {
    if (isSuccessReuse) {
      props.onSave()
    } else if (isErrorReuse) {
      message.error('Ошибка переиспользования кандидата')
    }
  }, [isSuccessReuse, isErrorReuse, props])

  const { data: sysVacanciesData, isLoading: isLoadingSysVacancies } = useSystemVacancies()

  useEffect(() => {
    if (sysVacanciesData) {
      const list = getValidSysVacancies(sysVacanciesData)
      // Если одно значение селекта, то сразу выбрать, если нет то юзер выберет сам
      const currentVacancy = list.length === 1 ? list[0] : null
      form.setFieldsValue({
        vacancy: currentVacancy?._id
      })
      setState({
        vacancyList: list,
        currentVacancy
      })
    }
  }, [sysVacanciesData, form])

  const handleFillAddress = data => setState({ dadataAddress: data })

  const handleAddUser = async values => {
    values.birthday = values?.birthday?.format(appConfig.formats.shortDateApi)
    values.city = state.dadataAddress?.city
    values.similar = state.candidates.map(item => item._id)
    await mutate({ data: values })
  }

  const handleSetRequestType = value => {
    setState({
      utms: {
        requestType: value,
        utm_medium: null
      }
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null,
      utm_campaign: null
    })
  }
  const handleSetUtmMedium = value => {
    setState({
      utms: {
        ...state.utms,
        utm_medium: value
      }
    })
    form.setFieldsValue({
      utm_source: null,
      utm_campaign: null
    })
  }

  const handleSysVacancies = value => {
    setState({
      currentVacancy: state.vacancyList.find(vacancy => vacancy._id === value)
    })
  }

  const { data: dataFindSimilar } = useFindSimilar(
    { phone: state.phone },
    {
      enabled: !!state.phone
    }
  )

  const handleSetPhone = useCallback(() => {
    const phone = form.getFieldValue('phone')
    setState({ phone })
  }, [form])

  const debounceSetPhone = useMemo(() => debounce(handleSetPhone, 800), [handleSetPhone])

  useEffect(() => {
    const data = { candidates: dataFindSimilar ? dataFindSimilar.data.similar : [] }
    if (!state.selectedCandidate && data.candidates.length > 0) {
      data.selectedCandidate = data.candidates[0]
    }
    if (state.selectedCandidate && data.candidates.length === 0) {
      data.selectedCandidate = undefined
    }
    setState(data)
  }, [dataFindSimilar, state.selectedCandidate])

  const handleSelectCandidate = candidate => {
    setState({ selectedCandidate: candidate })
  }

  const handleReUseCandidate = async () => {
    const { requestType, utm_source, utm_medium } = utmForm.getFieldsValue()
    const data = {
      requestType: requestType || null,
      utm_source: utm_source || null,
      utm_medium: utm_medium || null,
      utm_campaign: null,
      utm_term: null
    }
    setIsModalVisible(false)
    utmForm.setFieldsValue({
      requestType: null,
      utm_medium: null,
      utm_source: null
    })
    await reuseCandidate({ id: state.selectedCandidate?._id, data })
  }

  const handleReuse = useCallback(() => {
    if (user.agency) handleReUseCandidate()
    else setIsModalVisible(true)
  }, [handleReUseCandidate, user.agency])

  return (
    <div className="profile-create-wrap">
      <Spin spinning={isLoading || isLoadingSysVacancies || isLoadingReuse} size="large">
        <Form form={form} onFinish={handleAddUser} scrollToFirstError>
          <Row gutter={8}>
            <Col span={10} className="profile-create-block">
              <div className="block-title">НОВЫЙ КАНДИДАТ</div>
              <PhoneItem required onChange={debounceSetPhone} />
              <SysVacanciesItem vacancyList={state.vacancyList} onChange={handleSysVacancies} />
              <FioItem required />
              <EmailItem />
              <BirthAndAgeItem form={form} />
              <CitizenshipItem />
              {!isHiringManager && <DadataAddressItem onChange={handleFillAddress} />}
              {!isExternalHiringManager && (
                <RequestTypeItem value={state.utms.requestType} onChange={handleSetRequestType} />
              )}
              {!isExternalHiringManager && (
                <UtmMediumItem value={state.utms.utm_medium} onChange={handleSetUtmMedium} />
              )}
              {!isExternalHiringManager && <UtmSourceItem utmMedium={state.utms.utm_medium} />}
              <CommentItem />
            </Col>
            <Col span={12} className="duplicate-block" offset={2}>
              {state.candidates.length === 0 ? (
                <img src={addCandidate} alt="add candidate" className="addCandidate" />
              ) : (
                <CandidateDuplicateItem
                  candidates={state.candidates}
                  candidate={state.selectedCandidate}
                  selectCandidate={handleSelectCandidate}
                  wrapperClassName="second-block"
                />
              )}
            </Col>
          </Row>
          <div className="button-block">
            <Row gutter={16}>
              <Col span={10} className="button-wrapper-white">
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Col>
              <Col span={12} className="button-wrapper-gray" offset={2}>
                <Button
                  type="primary"
                  htmlType="button"
                  disabled={!state.selectedCandidate || state.selectedCandidate?.activeStatus}
                  onClick={handleReuse}
                >
                  Использовать повторно
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <UtmModal
          visible={isModalVisible}
          onOk={handleReUseCandidate}
          onCancel={() => setIsModalVisible(false)}
          form={utmForm}
          requestType={state.utms.requestType}
          utmMedium={state.utms.utm_medium}
          onRequestTypeChange={handleSetRequestType}
          onUtmMediumChange={handleSetUtmMedium}
        />
      </Spin>
    </div>
  )
}

ProfileCreate.propTypes = {
  onSave: PropTypes.func.isRequired
}

export default ProfileCreate
