const workTime = ['полный день', 'не полный день']
const statusesValues = {
  rookie: 'Rookie',
  interviewScheduled: 'InterviewScheduled',
  cameForAnInterview: 'CameForAnInterview',
  notCameForAnInterview: 'NotCameForAnInterview',
  sentToMedicalTest: 'SentToMedicalTest',
  passedMedicalTest: 'PassedMedicalTest',
  readyToWork: 'ReadyToWork',
  workStarted: 'WorkStarted',
  rejectedByDepartment: 'RejectedByDepartment',
  rejectedByRecruiter: 'RejectedByRecruiter',
  rejectedByCandidate: 'RejectedByCandidate',
  inReserve: 'InReserve',
  preInterview: 'PreInterview',
  sentToSecurityCheck: 'SentToSecurityCheck',
  sentToInternship: 'SentToInternship',
  cameForAnInternship: 'CameForAnInternship',
  notCameForAnInternship: 'NotCameForAnInternship',
  passedInternship: 'PassedInternship',
  passedSecurityCheck: 'PassedSecurityCheck',
  duplicate: 'Duplicate'
}
const statusesValuesInboxOrder = [
  // в этом порядке будут показываться статусы в инбоксе
  statusesValues.preInterview,
  statusesValues.sentToMedicalTest,
  statusesValues.passedMedicalTest,
  statusesValues.inReserve,
  statusesValues.notCameForAnInterview,
  statusesValues.interviewScheduled,
  statusesValues.duplicate
]
const applicationStatuses = [
  { key: statusesValues.rookie, label: 'Новые (директор)' },
  { key: statusesValues.interviewScheduled, label: 'Собеседование' },
  { key: statusesValues.cameForAnInterview, label: 'Пришел на собеседование' },
  { key: statusesValues.notCameForAnInterview, label: 'Не пришел на собеседование' },
  { key: statusesValues.sentToInternship, label: 'Отправлен на стажировку' },
  { key: statusesValues.cameForAnInternship, label: 'Пришел на стажировку' },
  { key: statusesValues.notCameForAnInternship, label: 'Не пришел на стажировку' },
  { key: statusesValues.passedInternship, label: 'Прошел стажировку' },
  { key: statusesValues.sentToSecurityCheck, label: 'Проверка СБ' },
  { key: statusesValues.passedSecurityCheck, label: 'Прошел проверку СБ' },
  { key: statusesValues.sentToMedicalTest, label: 'Отправлен на медосмотр' },
  { key: statusesValues.passedMedicalTest, label: 'Прошел медосмотр' },
  { key: statusesValues.readyToWork, label: 'Отправлен на оформление' },
  { key: statusesValues.rejectedByDepartment, label: 'Отказ директора' },
  { key: statusesValues.rejectedByRecruiter, label: 'Отказ рекрутера' },
  { key: statusesValues.rejectedByCandidate, label: 'Отказ кандидата' }
]

const planStatuses = {
  new: 'New',
  approved: 'Approved',
  rejected: 'Rejected',
  updatedBySystem: 'UpdatedBySystem',
  changedByHiringManager: 'ChangedByHiringManager'
}

const departmentStatusesKeys = {
  opened: 'opened',
  closed: 'closed',
  warning: 'warning'
}

const needsVacanciesValues = {
  crewman: 'crewman',
  cook: 'cook',
  cashier: 'cashier',
  director: 'director',
  trainer: 'trainer',
  deputyDirector: 'deputyDirector',
  shiftManager: 'shiftManager',
  dayCleaner: 'dayCleaner',
  nightCleaner: 'nightCleaner',
  driverCourier: 'driverCourier'
}

const timeNames = {
  morning: 'Утро',
  noon: 'День',
  evening: 'Вечер',
  weekend: 'Выходные'
}

const roles = {
  recruiter: 'recruiter', //не может быть в мультироли
  admin: 'admin', // центральный менеджер
  hiringManager: 'hiringManager', // директор
  manager: 'manager', // менеджер с указанием позиции
  prioritySelector: 'prioritySelector' // Установка приоритетов подбора
}

const possibleActions = {
  scheduleInterview: 'scheduleInterview',
  cameForAnInterview: 'cameForAnInterview',
  notCameForAnInterview: 'notCameForAnInterview',
  sendToMedicalTest: 'sendToMedicalTest',
  passedMedicalTest: 'passedMedicalTest',
  readyToWork: 'readyToWork',
  workStarted: 'workStarted',
  rejectedByCandidate: 'rejectedByCandidate',
  rejectedByDepartment: 'rejectedByDepartment',
  rejectedByRecruiter: 'rejectedByRecruiter',
  preInterview: 'preInterview',
  sendToSecurityCheck: 'sendToSecurityCheck',
  sendToInternship: 'sendToInternship',
  cameForAnInternship: 'cameForAnInternship',
  notCameForAnInternship: 'notCameForAnInternship',
  passedInternship: 'passedInternship',
  passedSecurityCheck: 'passedSecurityCheck',
  toReserve: 'toReserve',
  markAsDuplicate: 'markAsDuplicate',
  createdByHiringManager: 'createdByHiringManager'
}

/**
 * Коды также используются в public/extensionButton/content_script.js, там нельзя экспортить/импортить
 * там используются в виде magic number
 */
export const externalButtonCodes = {
  closeSolvoproExternalButton: 'close-solvopro-external-button',
  errorSolvoproExternalButton: 'error-solvopro-external-button',
  errorRejectSolvoproExternalButton: 'error-reject-solvopro-external-button',
  rejectSolvoproExternalButton: 'reject-solvopro-external-button',
  duplicateSolvoproExternalButton: 'duplicate-solvopro-external-button',
  addSolvoproExternalButton: 'add-solvopro-external-button'
}
export const langCases = {
  nominative: 'nominative', //именительный
  genitive: 'genitive', //родительный
  dative: 'dative', //дательный
  grand: 'grand', //винительный
  instrumental: 'instrumental', //творительный
  prepositional: 'prepositional' //предложный
}

export const appConfig = {
  formats: {
    shortDate: 'DD.MM.YYYY',
    fullMonthDate: 'DD MMMM YYYY',
    shortTime: 'HH:mm',
    shortTimeWithSeconds: 'HH:mm:ss',
    shortDateAndTime: 'DD.MM.YYYY HH:mm',
    dateAndTime: 'DD.MM.YYYY в HH:mm',
    shortDateApi: 'YYYY-MM-DD',
    shortDateCheckupApi: 'YYYY.MM.DD',
    shortDateAndTimeApi: 'YYYY-MM-DDTHH:mm:ss',
    excelShortDate: 'dd.mm.yyyy;@',
    excelShortTime: 'HH:mm;@'
  },
  interview: {
    defaultStartTime: '06:00',
    defaultEndTime: '22:00'
  },
  roles,
  rolesLabels: {
    [roles.recruiter]: 'Рекрутер',
    [roles.admin]: 'Центральный менеджер',
    [roles.hiringManager]: 'Директор',
    [roles.manager]: 'Менеджер',
    [roles.prioritySelector]: 'Установка приоритетов подбора'
  },
  planStatuses,
  planFactStatuses: {
    [planStatuses.new]: 'Новый',
    [planStatuses.approved]: 'Утверждено',
    [planStatuses.rejected]: 'Отклонено',
    [planStatuses.updatedBySystem]: 'Обновлено системой',
    [planStatuses.changedByHiringManager]: 'Не утверждено'
  },
  schedulerWeekNavigate: {
    today: 'Сегодня',
    previous: 'Предыдущая',
    next: 'Следующая'
  },
  preferences: {
    rates: workTime,
    times: Object.values(timeNames).map(name => name.toLowerCase())
  },
  statuses: {
    values: statusesValues,
    inboxOrder: statusesValuesInboxOrder,
    application: applicationStatuses,
    candidate: [
      ...applicationStatuses,
      { key: statusesValues.inReserve, label: 'В резерве' },
      { key: statusesValues.duplicate, label: 'Дубль' },
      { key: statusesValues.preInterview, label: 'Преинтервью' },
      { key: statusesValues.workStarted, label: 'Вышел на работу' }
    ]
  },
  noAuthActions: [],
  workFlows: {
    candidate: {
      prefixUrl: 'flow/candidate/',
      actions: possibleActions
    },
    call: {
      prefixUrl: 'flow/call/',
      actions: {
        later: 'later', // перезвонить позже
        missed: 'missed' // не дозвонился
      }
    }
  },
  references: {
    citizenships: 'citizenships',
    utms: 'utm?requestType=',
    requestTypes: 'requestTypes',
    sources: 'utm/sources',
    mediums: 'utm/mediums',
    inboxStates: 'inboxStates',
    cities: 'cities',
    positions: 'positions'
  },
  utmTypes: {
    requestType: 'requestType',
    utmSource: 'utmSource',
    utmMedium: 'utmMedium'
  },
  interviewHours: {
    start: 9,
    end: 22
  },
  settings: {
    sms: 'SMS',
    interviewScheduler: {
      start: 'INTERVIEW_SCHEDULE_HOUR_START',
      end: 'INTERVIEW_SCHEDULE_HOUR_END'
    },
    rejectionTypes: {
      candidate: 'REJECTED_BY_CANDIDATE',
      department: 'REJECTED_BY_DEPARTMENT',
      recruiter: 'REJECTED_BY_RECRUITER'
    },
    departmentType: 'DEPARTMENT_TYPE',
    departmentTypes: 'DEPARTMENT_TYPES',
    seedPopup: 'SEED_POPUP',
    sortableStates: 'SORTABLE_STATES'
  },
  nextPlanningAvailableDate: 25,
  needsVacancies: needsVacanciesValues,
  timeNames,
  userFormModes: {
    create: 'create',
    edit: 'edit'
  },
  reportSourceTypes: {
    main: 'main',
    workSchedule: 'workSchedule'
  },
  // На инбоксе будут использоваться фильтры, всевозможные значения которых будут получаться из запроса.
  inboxFilters: {
    requestType: 'requestType',
    utmSource: 'utm_source'
  },
  requestTypeCareerSite: 'Отклик из карьерного сайта',
  departmentStatusesKeys,
  departmentStatuses: [
    {
      key: departmentStatusesKeys.opened,
      value: 'Активен',
      actionValue: 'Активен',
      color: 'success'
    },
    {
      key: departmentStatusesKeys.closed,
      value: 'Неактивен',
      actionValue: 'Неактивен',
      color: 'danger'
    }
  ],
  departmentWarningStatus: {
    key: departmentStatusesKeys.warning,
    value: 'Требует внимания',
    actionValue: 'Требует внимания',
    color: 'warning'
  },
  usersStatuses: [
    {
      key: 'activate',
      actionValue: 'Активировать',
      value: false
    },
    {
      key: 'deactivate',
      actionValue: 'Деактивировать',
      value: true
    }
  ],
  features: {
    analytics: 'ANALYTICS',
    testData: 'TEST_DATA',
    orgLayers: 'ORG_LAYERS',
    agencies: 'AGENCIES',
    integrations: 'INTEGRATIONS'
  },
  filterPeriods: {
    lastWeek: 'lastWeek',
    currentMonth: 'currentMonth',
    lastMonth: 'lastMonth',
    quarter: 'quarter',
    custom: 'custom'
  },
  integrationStatuses: {
    none: 'none',
    actionRequired: 'actionRequired',
    online: 'online',
    error: 'error'
  },
  externalButtonCodes
}
