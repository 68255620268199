import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'
import { AGENCY_KEY } from './agency'

export const USERS_KEY = 'users'

const fetchProfile = () => {
  return api.get({ path: `${USERS_KEY}/profile` })
}

export function useGetProfileMutate(options) {
  return useMutation(fetchProfile, options)
}

const patchProfile = data => {
  return api.patch({ path: `${USERS_KEY}/profile`, data })
}

export function useMutateProfile() {
  return useMutation(patchProfile)
}

const fetchRequestPassword = data => {
  return api.post({ path: `${USERS_KEY}/password/request`, data })
}

export function useMutateRequestPassword() {
  return useMutation(fetchRequestPassword)
}

const fetchUpdatePassword = data => {
  return api.post({ path: `${USERS_KEY}/password/update`, data })
}

export function useMutateUpdatePassword() {
  return useMutation(fetchUpdatePassword)
}

const patchUser = ({ id, data }) => {
  return api.patch({ path: `${USERS_KEY}/${id}`, data })
}

export function useMutateUser() {
  return useMutation(patchUser, {
    onSuccess: () => {
      queryClient.refetchQueries(USERS_KEY)
      queryClient.refetchQueries(AGENCY_KEY, { active: true })
    }
  })
}

const putUser = data => {
  return api.put({ path: `${USERS_KEY}/signup`, data })
}

export function useMutateCreateUser() {
  return useMutation(putUser, {
    onSuccess: () => {
      queryClient.refetchQueries(USERS_KEY)
    }
  })
}

const getUsers = ({ queryKey }) => {
  const [, /* key */ { pagination, filters }] = queryKey
  const data = {}
  if (pagination) {
    data.limit = pagination.pageSize
    data.page = pagination.current
  }

  if (filters) {
    data.role = filters.role
    data.position = filters.position
    data.name = filters.name
    data.email = filters.email
    data.text = filters.text
    data.orgUnit = filters.orgUnit
  }

  return api.post({ path: `${USERS_KEY}/find`, data })
}

export function useUsers(data = {}, options) {
  return useQuery([USERS_KEY, data], getUsers, options)
}

// const fetchGetRecruiters = ({ queryKey }) => {
//   const [, /* key */ scope] = queryKey
//   return api.get({ path: `${USERS_KEY}/recruiters/${scope}` })
// }

// export function useGetRecruiters(scope, options) {
//   return useQuery([`${USERS_KEY}/recruiters`, scope], fetchGetRecruiters, options)
// }
