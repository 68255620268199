import { createContext } from 'react'
import PropTypes from 'prop-types'
import { candidateShape } from '../constants/propTypesShapes'

export const CandidatePanelContext = createContext({
  candidate: {},
  setCandidate: () => {},
  showPanelTab: () => {}
})

CandidatePanelContext.Provider.propTypes = {
  candidate: candidateShape,
  setCandidate: PropTypes.func,
  showPanelTab: PropTypes.func
}
