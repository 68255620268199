import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

const TOPIC_AUTH = 'integration.jobId.cmd.setup'
const TOPIC_USERS = 'state.jobId.cmd.get'
const TOPIC_LINK_USERS = 'users.jobId.cmd.link'
const TOPIC_JOBSITES_LIST = 'integrations.jobsites.cmd.list'
const TOPIC_ACTION_AUTH = 'action.jobId.cmd.start'
const TOPIC_UTM_TAGS_SET = 'tags.jobId.cmd.set'

// универсальный медот - использовать его передавай внутрь уникальный топик для сервиса
const fetchIntegration = ({ topic, data }) => api.post({ path: `integrations/${topic}`, data })

const authJobSite = ({ jobId, data }) =>
  fetchIntegration({ topic: TOPIC_AUTH.replace('jobId', jobId), data })
const linkUsersJobSite = ({ jobId, data }) =>
  fetchIntegration({ topic: TOPIC_LINK_USERS.replace('jobId', jobId), data })
const usersJobSite = ({ queryKey }) => {
  return fetchIntegration({ topic: TOPIC_USERS.replace('jobId', queryKey[1]) })
}
const jobSites = () => fetchIntegration({ topic: TOPIC_JOBSITES_LIST })
const actionAuth = ({ jobId, data }) =>
  fetchIntegration({
    topic: TOPIC_ACTION_AUTH.replace('jobId', jobId),
    data: { ...data, type: 'authorize' }
  })

const saveUtms = ({ integrationId, data }) =>
  fetchIntegration({ topic: TOPIC_UTM_TAGS_SET.replace('jobId', integrationId), data })

export const useAuthJobSite = () => useMutation(authJobSite)
export const useActionAuth = () => useMutation(actionAuth)
export const useSaveUtms = () => useMutation(saveUtms)
export const useLinkUsersJobSite = jobId =>
  useMutation(linkUsersJobSite, {
    onSuccess: () => {
      queryClient.refetchQueries([TOPIC_USERS, jobId])
    }
  })
export const useGetUsersJobSite = (jobId, options) =>
  useQuery([TOPIC_USERS, jobId], usersJobSite, options)
export const useGetJobSites = options => useQuery(TOPIC_JOBSITES_LIST, jobSites, options)
