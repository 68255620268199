import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Row, Col, Spin } from 'antd'
import moment from 'moment'

import * as helper from '../../../../../helpers'
import { appConfig } from '../../../../../constants/appConfig'
import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { UserContext } from '../../../../../contexts/userContext'
import './CandidateProfileEdit.css'
import { useMutateUpdateCandidate } from '../../../../../api/candidate'
import {
  FioItem,
  PhoneItem,
  CitizenshipItem,
  BirthAndAgeItem,
  EmailItem,
  ProfileUrlItem,
  RequestTypeItem,
  UtmMediumItem,
  UtmSourceItem,
  UtmCampaignItem,
  UtmTermItem,
  DadataAddressItem,
  SysVacanciesItem,
  PreferredRateItem,
  PreferredDepartmentItem,
  PreferredTimeItem,
  CommentItem,
  CurrentPositionItem,
  CurrentOrganizationItem
} from '../../FormItems/CandidateProfileItems'
import { useReferences } from '../../../../../hooks/useReferences'
import { simpleReducer } from '../../../../../helpers'
import { getValidSysVacancies } from '../../../../../helpers/vacancy'
import { useSystemVacancies } from '../../../../../hooks/useSystemVacancies'

const CandidateProfileEdit = props => {
  const [form] = Form.useForm()
  const { candidate } = useContext(CandidatePanelContext)
  const { user } = useContext(UserContext)

  const initialState = {
    vacancyList: [],
    dadataAddress: null,
    utms: {
      requestType: candidate.requestType || null,
      utm_medium: candidate.utm_medium || null
    },
    isRequestTypeIsCareerSite: false
  }
  const [state, dispatch] = useReducer(simpleReducer, initialState)

  useReferences({
    citizenships: true,
    requestTypes: true,
    utms: true,
    utmsQuery: state.utms.requestType
  })

  const { data: sysVacanciesData, isLoading: isLoadingSysVacancies } = useSystemVacancies()

  useEffect(() => {
    if (sysVacanciesData) {
      dispatch({
        vacancyList: getValidSysVacancies(sysVacanciesData)
      })
    }
  }, [sysVacanciesData])

  /**
   * Mutate Candidate and update queryById if update isSuccess
   */
  const { mutate, status: statusEdit, isLoading } = useMutateUpdateCandidate()

  const filterFields = useCallback(
    (name, value) => {
      if (name === 'phone') {
        return value.substring(1) !== candidate[name]
      }
      if (name === 'vacancy') {
        return value !== candidate.vacancy._id
      }
      return value !== candidate[name]
    },
    [candidate]
  )
  const handleEditUser = async () => {
    const changedValues = Object.fromEntries(
      Object.entries(form.getFieldsValue(null, meta => meta.touched)).filter(([key, value]) =>
        filterFields(key, value)
      )
    )
    if (changedValues.birthday) {
      const birthday = changedValues?.birthday?.format(appConfig.formats.shortDateApi)
      if (birthday !== candidate?.birthday) {
        changedValues.birthday = birthday
      } else {
        delete changedValues.birthday
      }
    }
    if (state.dadataAddress?.city !== candidate?.city) {
      changedValues.city = state.dadataAddress?.city
    }
    await mutate({ id: candidate._id, data: changedValues })
  }

  useEffect(() => {
    if (statusEdit === 'success') {
      props.onSave()
    }
  }, [statusEdit, props])

  useEffect(() => {
    form.setFieldsValue({
      ...candidate,
      address: candidate.address || candidate.city,
      birthday: candidate.birthday
        ? moment(candidate.birthday, appConfig.formats.shortDateApi)
        : '',
      phone: candidate.phone ? helper.formatPhoneNumberForApi(candidate.phone) : '',
      vacancy: state.vacancyList.find(vacancy => vacancy?._id === candidate.vacancy?._id)?._id
    })
  }, [candidate, form, user?.department?.city, state.vacancyList])

  useEffect(() => {
    const isRequestTypeIsCareerSite =
      candidate.requestType?.trim().toLowerCase() ===
      appConfig.requestTypeCareerSite.trim().toLowerCase()
    dispatch({ isRequestTypeIsCareerSite })
  }, [candidate])

  const handleFillAddress = data => dispatch({ dadataAddress: data })

  const handleSetRequestType = value => {
    dispatch({
      utms: {
        requestType: value,
        utm_medium: null
      }
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null,
      utm_campaign: null
    })
  }
  const handleSetUtmMedium = value => {
    dispatch({
      utms: {
        ...state.utms,
        utm_medium: value
      }
    })
    form.setFieldsValue({
      utm_source: null,
      utm_campaign: null
    })
  }

  return (
    <Spin spinning={isLoadingSysVacancies}>
      <Form
        form={form}
        onFinish={handleEditUser}
        className="candidate-profile-form"
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col span={12}>
            <SysVacanciesItem vacancyList={state.vacancyList} />
            <FioItem required />
            <CitizenshipItem />
            <PhoneItem required />
            <EmailItem />
            <BirthAndAgeItem form={form} />

            <DadataAddressItem
              onChange={handleFillAddress}
              address={candidate.address || candidate.city}
            />

            <RequestTypeItem
              value={state.utms.requestType}
              onChange={handleSetRequestType}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEditSources}
            />
            <UtmMediumItem
              value={state.utms.utm_medium}
              onChange={handleSetUtmMedium}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEditSources}
            />
            <UtmSourceItem
              utmMedium={state.utms.utm_medium}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEditSources}
            />
            <UtmCampaignItem
              utmMedium={state.utms.utm_medium}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEditSources}
            />
            <UtmTermItem disabled={!candidate.canEditSources} />
            <ProfileUrlItem />
          </Col>

          <Col span={12}>
            <CurrentPositionItem />
            <CurrentOrganizationItem />

            <PreferredRateItem />
            <PreferredDepartmentItem />
            <PreferredTimeItem />

            <CommentItem />
          </Col>
        </Row>

        <div className="btn-wrap" style={{ marginTop: 20 }}>
          <Button
            htmlType="submit"
            style={{ marginRight: 20 }}
            disabled={isLoading}
            loading={isLoading}
          >
            Сохранить
          </Button>
          <Button type="danger" disabled={isLoading} onClick={props.onClose}>
            Отмена
          </Button>
        </div>
      </Form>
    </Spin>
  )
}

CandidateProfileEdit.defaultProps = {
  city: {},
  cities: []
}

CandidateProfileEdit.propTypes = {
  city: PropTypes.object,
  cities: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default CandidateProfileEdit
