import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Typography, Checkbox, Input, Row, Col } from 'antd'

import { candidateShape } from '../../../../../constants/propTypesShapes'
import { appConfig } from '../../../../../constants/appConfig'
import { debounce } from '../../../../../helpers'

const { Option } = Select
const { Text } = Typography
const formItemLayout = {
  labelCol: {
    span: 10
  },
  wrapperCol: {
    span: 14
  }
}

const CandidatePreferencesEdit = ({ title, candidate, wrapperClass, onEdit }) => {
  const handleValuesChange = useMemo(
    () => debounce((changedFields, allFields) => onEdit(allFields), 1000),
    [onEdit]
  )
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(candidate)
  }, [form, candidate])

  return (
    <div className={wrapperClass}>
      <Text type="secondary">{title}</Text>

      <Form
        name="candidate_preferences"
        {...formItemLayout}
        form={form}
        labelAlign="left"
        size="small"
        className="item flow-root"
        onValuesChange={handleValuesChange}
      >
        <Form.Item name="preferredRate" label="Желаемая ставка" colon={false}>
          <Select placeholder="">
            {appConfig.preferences.rates.map(rate => (
              <Option key={rate} value={rate}>
                {rate}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="preferredDepartment" label="Желаемое место работы" colon={false}>
          <Input />
        </Form.Item>

        <Form.Item name="preferredTime" label="Время работы" colon={false}>
          <Checkbox.Group>
            <Row className="max-content">
              {appConfig.preferences.times.map(time => (
                <Col key={time}>
                  <Checkbox value={time} className="checkbox-line-height-24">
                    {time}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          name="comment"
          label="Комментарий для директора"
          className="overflow-visible"
          colon={false}
        >
          <Input maxLength={300} />
        </Form.Item>
      </Form>
    </div>
  )
}

CandidatePreferencesEdit.defaultProps = {
  title: 'Необходимо заполнить:',
  candidate: {},
  wrapperClass: ''
}

CandidatePreferencesEdit.propTypes = {
  title: PropTypes.string,
  candidate: candidateShape,
  wrapperClass: PropTypes.string,
  onEdit: PropTypes.func.isRequired
}

export default CandidatePreferencesEdit
