import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import moment from 'moment'

import { defaultTitle, emailDefaultTitle } from '../../../../../constants'
import { appConfig } from '../../../../../constants/appConfig'
import {
  formatPhoneNumber,
  numberDeclension,
  getCandidateResponsible
} from '../../../../../helpers'
import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { CandidatePreferences, Actions, CurrentSituation } from '../../index'
import CandidateComments from '../../../../../components/Common/CandidateComments/CandidateComments'

import './CandidateInfo.css'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

const { Text } = Typography

const CandidateInfo = ({
  isSearch,
  title,
  wrapperInfoClass,
  headerInfo,
  phonePrefix,
  wrapperInfoSecondaryClass,
  headerPreferences,
  onlyView
}) => {
  const { candidate } = useContext(CandidatePanelContext)
  const { departmentType } = useConfigContextData()

  return (
    <>
      <div className="ant-row candidateInfo" data-title={title}>
        <div className={wrapperInfoClass}>
          <div className="item">
            <b>Вакансия:</b> {candidate.vacancy?.displayName || defaultTitle}
          </div>
          <Text type="secondary">{headerInfo}</Text>
          <div className="item">{candidate.name}</div>
          {candidate.phone && (
            <div className="item">
              <a
                href={`tel:${
                  phonePrefix ? candidate.phone.replace('+7', phonePrefix) : candidate.phone
                }`}
              >
                {formatPhoneNumber(candidate.phone)}
              </a>
            </div>
          )}
          {candidate.email && (
            <div className="item">
              <a href={`mailto:${candidate.email}`}>{candidate.email || emailDefaultTitle}</a>
            </div>
          )}
          {candidate?.age > 0 && (
            <div className="item">
              Возраст: {candidate.age} {numberDeclension(candidate.age, ['год', 'года', 'лет'])}
            </div>
          )}
          {candidate.birthday && (
            <div className="item">
              Дата рождения: {moment(candidate.birthday).format(appConfig.formats.shortDate)}
            </div>
          )}
          <div className="item">Гражданство: {candidate.citizenship || defaultTitle}</div>
          <div className="item">Город: {candidate.city || defaultTitle}</div>
          <div className="item">Адрес: {candidate.address || defaultTitle}</div>
          <div className="item">Тип обращения: {candidate.requestType || defaultTitle}</div>
          <div className="item">Группа источников: {candidate.utm_medium || defaultTitle}</div>
          <div className="item">Источник: {candidate.utm_source || defaultTitle}</div>
          <div className="item">Utm Campaign: {candidate.utm_campaign || defaultTitle}</div>
          <div className="item">Utm Term: {candidate.utm_term || defaultTitle}</div>
          {candidate.profileUrl && (
            <div className="item white-space-nowrap">
              <a href={candidate.profileUrl} rel="noreferrer noopener" target="_blank">
                {candidate.profileUrl}
              </a>
            </div>
          )}
          <div className="item">
            Ответственный рекрутер: {getCandidateResponsible(candidate.responsible)}
          </div>
        </div>

        <div className={wrapperInfoSecondaryClass}>
          <CurrentSituation candidate={candidate} />

          <CandidatePreferences candidate={candidate} title={headerPreferences} />
          <div className="mt-3">
            <div className="item">
              {departmentType} работа: {candidate?.application?.department?.name || defaultTitle}
            </div>
            <div className="item">
              {departmentType} собеседование:{' '}
              {candidate?.application?.interviewerDepartment?.name ||
                candidate?.application?.department?.name ||
                defaultTitle}
            </div>
          </div>
        </div>
      </div>
      {!onlyView && <Actions isSearch={isSearch} />}
      {candidate?._id && <CandidateComments candidate={candidate} avatarColSpan={2} />}
    </>
  )
}

CandidateInfo.defaultProps = {
  headerInfo: 'Из резюме кандидата:',
  headerPreferences: undefined,
  onEdit: undefined,
  onlyView: false,
  wrapperInfoClass: 'ant-col-12',
  wrapperInfoSecondaryClass: 'ant-col-12'
}

CandidateInfo.propTypes = {
  title: PropTypes.string,
  headerInfo: PropTypes.string,
  headerPreferences: PropTypes.string,
  phonePrefix: PropTypes.string,
  onEdit: PropTypes.func,
  wrapperInfoClass: PropTypes.string,
  wrapperInfoSecondaryClass: PropTypes.string,
  onlyView: PropTypes.bool
}

export default CandidateInfo
