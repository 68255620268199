import { useEffect } from 'react'
import { message } from 'antd'

import { useGetSystemVacancies } from '../api/vacancy'

export const useSystemVacancies = () => {
  const { data, isError, isLoading } = useGetSystemVacancies()

  useEffect(() => {
    if (isError) {
      message.error('Ошибка загрузки данных для вакансий')
    }
  }, [isError])

  return {
    data: data?.data,
    isLoading
  }
}
