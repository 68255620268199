import React, { useContext, Suspense, lazy } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Spin } from 'antd'
import PropTypes from 'prop-types'

import { Auth, ForgotPassword, PasswordRecovery } from './modules/massRecruitment/Screens'
import { ExternalMain, ExternalCandidateEdit } from './modules/ExternalButton'
const NoAuthCandidate = lazy(() => import('./modules/NoAuthCandidate'))

import { useRoutes } from './hooks/useRoutes'
import { UserContext } from './contexts/userContext'
import {
  PASSWORD_RECOVERY,
  FORGOT_PASSWORD,
  EXTERNAL_BUTTON,
  EXTERNAL_BUTTON_CANDIDATE_PAGE,
  NO_AUTH_CANDIDATE_PAGE
} from './constants/routes'
import { modules, noAuthPrefix } from './constants'

function Routes({ module }) {
  const { user } = useContext(UserContext)
  const { routes } = useRoutes(user, module)
  const { pathname } = useLocation()
  const isUserLogged = Object.keys(user).length

  const getRedirect = () => {
    /**
     * we add redirect to user for prevent routes not for his role
     * and for unregister (without token) for redirect to Auth
     */
    return (routes || !isUserLogged) && <Redirect to="/" />
  }

  return (
    <Suspense
      fallback={
        !pathname.includes(noAuthPrefix) ? (
          <Spin spinning size="large" className="full-screen-spin suspense" />
        ) : (
          <div />
        )
      }
    >
      <Switch>
        {/* ExternalButton routes: Should be FIRST because contain own simple auth page */}
        <Route path={EXTERNAL_BUTTON} exact component={ExternalMain} />
        <Route path={EXTERNAL_BUTTON_CANDIDATE_PAGE} exact component={ExternalCandidateEdit} />
        {/* End ExternalButton routes */}
        <Route path="/" exact component={Auth} />
        <Route path={PASSWORD_RECOVERY} exact component={PasswordRecovery} />
        <Route path={FORGOT_PASSWORD} exact component={ForgotPassword} />
        <Route path={NO_AUTH_CANDIDATE_PAGE} exact component={NoAuthCandidate} />
        {routes?.map(({ path, exact = true, component }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        {getRedirect()}
      </Switch>
    </Suspense>
  )
}
export default Routes

Routes.propTypes = {
  module: PropTypes.oneOf(Object.values(modules)).isRequired
}
Routes.defaultProps = {}
