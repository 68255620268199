import React from 'react'
import PropTypes from 'prop-types'

import Comments from '../../../components/Common/Comments/Comments'
import { candidateShape } from '../../../constants/propTypesShapes'
import {
  useMutateCreateCandidateComment,
  useMutateUpdateCandidateComment,
  useMutateDeleteCandidateComment
} from '../../../api/comments/candidateComment'

function CandidateComments({ candidate, avatarColSpan }) {
  return (
    <Comments
      avatarColSpan={avatarColSpan}
      entity={candidate}
      onDelete={useMutateDeleteCandidateComment}
      onCreate={useMutateCreateCandidateComment}
      onUpdate={useMutateUpdateCandidateComment}
    />
  )
}

export default CandidateComments

CandidateComments.defaultProps = {
  avatarColSpan: 3
}

CandidateComments.propTypes = {
  candidate: candidateShape.isRequired,
  avatarColSpan: PropTypes.number
}
