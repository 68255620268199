import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { appConfig } from '../../../../../constants/appConfig'
import { colSettings } from '../../../../../constants'

const { Option } = Select

function RoleItem({
  roles,
  disabled = false,
  adminOptionDisabled = false,
  size = 'middle',
  labelCol = colSettings.six,
  wrapperCol = colSettings.full,
  disabledRoles = []
}) {
  return (
    <Form.Item
      label="Роль"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign="left"
      required
      name="roles"
      rules={[
        {
          required: true,
          message: 'Выберите роль'
        }
      ]}
    >
      <Select size={size} placeholder="Выберите роль" disabled={disabled} mode="multiple">
        {roles.map(role => (
          <Option
            key={role}
            value={role}
            disabled={
              (adminOptionDisabled && role === appConfig.roles.admin) ||
              disabledRoles.includes(role)
            }
          >
            {appConfig.rolesLabels[role]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

RoleItem.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.oneOf(appConfig.roles)).isRequired,
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  size: PropTypes.oneOf(['middle', 'small', 'large']),
  disabled: PropTypes.bool
}

export default RoleItem
