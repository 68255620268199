import React, { useEffect } from 'react'
import { Form } from 'antd'

import { convertCrewmenToObject } from '../../../../helpers/plan'
import RequestNeedTable from '../PlanningPersonal/RequestNeedTable'

import { planShape } from '../../../../constants/propTypesShapes'

const COLUMNS_SIZE = {
  name: 140,
  partTime: 40,
  fullTime: 40,
  forAdultsOnly: 40,
  morning: 40,
  noon: 40,
  evening: 40,
  night: 40,
  weekend: 40,
  cis: 40,
  male: 40,
  female: 40
}

function DepartmentPlan({ plan }) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (plan) {
      form.setFieldsValue(convertCrewmenToObject(plan))
    }
  }, [form, plan])

  return (
    <Form form={form} className="planningPersonal" colon={false}>
      <RequestNeedTable
        readOnly
        data={plan.crewmen}
        size="small"
        columnsSize={COLUMNS_SIZE}
        plan={plan}
      />
    </Form>
  )
}

export default DepartmentPlan

DepartmentPlan.propTypes = { plan: planShape }
