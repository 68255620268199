import React from 'react'
import PropTypes from 'prop-types'

import Comments from '../../../../components/Common/Comments/Comments'
import { planShape } from '../../../../constants/propTypesShapes'
import {
  useMutateDeletePlanComment,
  useMutateUpdatePlanComment,
  useMutateCreatePlanComment
} from '../../../../api/comments/planComment'

function PlanComments({ plan }) {
  return (
    <Comments
      title=""
      saveButtonTitle="Сохранить комментарий"
      entity={plan}
      onDelete={useMutateDeletePlanComment}
      onCreate={useMutateCreatePlanComment}
      onUpdate={useMutateUpdatePlanComment}
    />
  )
}

PlanComments.propTypes = {
  plan: PropTypes.shape(planShape).isRequired
}

export default PlanComments
