import React from 'react'
import Text from 'antd/lib/typography/Text'
import { Tag } from 'antd'

export const defaultTitle = <Text type="secondary">не указан</Text>
export const emptyTitle = <Text type="secondary">отсутствует</Text>
export const emailDefaultTitle = <Text type="secondary">email не указан</Text>
export const apiErrorSaveMsg = <Text type="danger">Ошибка сохранения данных</Text>

export const nbsp = '\xa0'
export const textTrue = 'да'
export const textFalse = 'нет'

/**
 * Dadata constants
 */
export const dadataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
export const dadataToken = 'e4e40464a235cf175752186948ab1982e64eddcb'

export const emptyFilter = { text: '<Нет значения>', value: null }

export const threeMinutes = 3 * 60 * 1000
export const recoveryKeyPrefix = '?recoveryKey='

export const modules = {
  massRecruitment: 'massRecruitment'
}

export const externalName = 'solvopro-external-button'
export const noAuthPrefix = 'noAuth'
export const emailSignatureDelimiter = '\n\n--\n\n'
export const imagesPrefix = '/images/'

export const minSymbolForCollapseOnExternalFrame = 200

/**
 * При вводе в инпут селектора количество симвоов обычно ограничено
 * После выбора любой из опций в инпут попадает ID выбранного элемента что приводит к лишнему запросу с этим ID
 * @type {number}
 */
export const countOfSymbolsInSearchFieldLikeId = 21

export const defaultQueryOptions = {
  retry: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  staleTime: Infinity
}
export const colSettings = {
  full: { span: 24 },
  half: { span: 12 },
  six: { span: 6 }
}
export const emptyRouteParams = {
  id: null,
  year: null,
  month: null,
  departmentId: null
}
export const backArrow = <>&larr;</>
export const breadcrumbSeparator = <span className="ant-breadcrumb-separator">&gt;</span>
export const noValue = 'N/A'
export const dashboardNoData = 'Кандидаты по выбранным фильтрам не найдены'
export const countRowsForVirtualTable = 30

export const statusActive = <Tag color="success">Активен</Tag>
export const statusInactive = <Tag>Неактивен</Tag>
export const on = <Tag color="success">On</Tag>
export const off = <Tag>Off</Tag>
