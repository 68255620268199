import { useQuery, useMutation } from 'react-query'
import { api, downloadFile, queryClient } from './api'
import { APPOINTMENTS_KEY } from './appointment'
import { makeFilterParams, makeSearchParamByPhoneOrName } from '../helpers'

export const CANDIDATE_KEY = 'candidate'
export const CANDIDATES_KEY = 'candidates'
export const CANDIDATES_SEARCH_KEY = 'candidates_search'
export const CANDIDATES_STATES_KEY = 'candidates_states'
export const CANDIDATES_MINE = 'candidates_mine'
export const CANDIDATES_REPORT = 'candidates_report'
export const CANDIDATES_RESPONSIBLES_KEY = 'candidates_responsibles'
export const CANDIDATE_MESSAGES_KEY = 'candidate_messages'
export const CANDIDATES_FILTER_VALUES_KEY = 'candidates_filter_values'
export const CANDIDATES_FIND_SIMILAR = 'candidates_find_similar'
export const CANDIDATES_CONFLICTS_KEY = 'candidates_conflicts'

const fetchGetCandidateById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `candidates/${id}` })
}

const fetchGetCandidatesBySearch = ({ queryKey }) => {
  const [, /* key */ value] = queryKey
  return api.get({ path: `candidates/search/${value}` })
}

const fetchPatchCandidateById = ({ id, data }) => {
  return api.patch({ path: `candidates/${id}`, data })
}

const fetchPostMarkProcessedSubmission = ({ id, submissionId }) => {
  return api.post({ path: `candidates/${id}/submissions/markProcessed/${submissionId}` })
}

const fetchPutCandidate = ({ data }) => {
  return api.put({ path: 'candidates', data })
}

const fetchFindSimilar = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: 'candidates/findSimilar', data })
}

// все статусы в которых есть кандидаты
const getCandidatesStates = () => {
  return api.get({ path: 'candidates/states' })
}

const getCandidatesMine = () => {
  return api.get({ path: 'candidates/mine' })
}

const getCandidatesReport = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: 'candidates/report', data })
}
const getCandidatesReportXlsx = params => {
  const urlParams = new URLSearchParams(Object.entries(params).filter(([, value]) => !!value))
  const path = 'candidates/report/xlsx?' + urlParams
  return downloadFile({ path, filename: 'Отчет.xlsx' })
}

// статусы которые отображаются на инбоксе
const getCandidatesStatesBySearch = ({ queryKey }) => {
  const [, /* key */ { mine, searchValue }] = queryKey
  const data = {
    ...(mine && { mine }),
    ...makeSearchParamByPhoneOrName(searchValue)
  }
  return api.post({ path: 'candidates/states/find', data })
}

const getCandidatesResponsible = () => {
  return api.get({ path: 'candidates/responsible' })
}

const fetchGetCandidates = ({ queryKey }) => {
  const [
    ,
    /* key */ { pagination, filters = {}, sorter = {}, statusCandidate, mine, searchValue }
  ] = queryKey
  const path = 'candidates'
  const data = {
    ...(pagination && { limit: pagination.pageSize, page: pagination.current }),
    ...(sorter.order && { order: sorter.order, orderField: sorter.orderField }),
    ...makeFilterParams(filters),
    ...(mine && { mine }),
    ...(statusCandidate && { state: statusCandidate }),
    ...makeSearchParamByPhoneOrName(searchValue)
  }
  return api.post({ path, data })
}

const getCandidateMessages = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `candidates/${id}/messages` })
}

const getCandidatesFilterValues = ({ queryKey }) => {
  const [, /* key */ { filterName, searchValue, filters = {}, statusCandidate, mine }] = queryKey
  const data = {
    ...makeFilterParams(filters),
    ...(mine && { mine }),
    ...(statusCandidate && { state: statusCandidate }),
    ...makeSearchParamByPhoneOrName(searchValue)
  }
  return api.post({ path: `candidates/filterValues/${filterName}`, data })
}

const getCandidatesConflicts = () => {
  return api.get({ path: `candidates/conflicts` })
}

const fetchReuseCandidateById = ({ id, data }) => {
  return api.post({ path: `candidates/${id}/reuse`, data })
}

export function useCandidatesQuery(data, options) {
  // data = { pagination, filters, sorter }
  return useQuery([CANDIDATES_KEY, data], fetchGetCandidates, options)
}

export function useCandidateByIdQuery(id, options) {
  return useQuery([CANDIDATE_KEY, id], fetchGetCandidateById, options)
}

export function useCandidatesBySearch(value, options) {
  return useQuery([CANDIDATES_SEARCH_KEY, value], fetchGetCandidatesBySearch, options)
}

export function useMutateUpdateCandidate() {
  return useMutation(fetchPatchCandidateById, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([CANDIDATE_KEY, variables.id], data)
      queryClient.refetchQueries(CANDIDATES_KEY)
      queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
      queryClient.refetchQueries(APPOINTMENTS_KEY)
      queryClient.refetchQueries(CANDIDATES_MINE)
      queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
    }
  })
}

export function useMutateCreateCandidate() {
  return useMutation(fetchPutCandidate, {
    onSuccess: () => {
      queryClient.refetchQueries(APPOINTMENTS_KEY)
      queryClient.refetchQueries(CANDIDATES_MINE)
      queryClient.refetchQueries(CANDIDATES_KEY)
      queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_STATES_KEY)
      queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
    }
  })
}

export function useFindSimilar(data, options) {
  return useQuery([CANDIDATES_FIND_SIMILAR, data], fetchFindSimilar, options)
}

export function useCandidatesStates(options) {
  return useQuery([CANDIDATES_STATES_KEY], getCandidatesStates, options)
}

export function useCandidatesMine(options) {
  return useQuery([CANDIDATES_MINE], getCandidatesMine, options)
}

export function useCandidatesReport(data, options) {
  return useQuery([CANDIDATES_REPORT, data], getCandidatesReport, options)
}

export function useCandidatesReportXlsx() {
  return useMutation(getCandidatesReportXlsx)
}

export function useCandidatesStatesBySearch(data, options) {
  return useQuery([CANDIDATES_STATES_KEY, data], getCandidatesStatesBySearch, options)
}

export function useCandidatesResponsible(options) {
  return useQuery([CANDIDATES_RESPONSIBLES_KEY], getCandidatesResponsible, options)
}

export function useCandidateMessages(id, options) {
  return useQuery([CANDIDATE_MESSAGES_KEY, id], getCandidateMessages, options)
}

export function useCandidatesFilterValues(data, options) {
  return useQuery([CANDIDATES_FILTER_VALUES_KEY, data], getCandidatesFilterValues, options)
}

export function useCandidatesConflicts(options) {
  return useQuery([CANDIDATES_CONFLICTS_KEY], getCandidatesConflicts, options)
}

export function useMutateMarkProcessedSubmission() {
  return useMutation(fetchPostMarkProcessedSubmission, {
    onSuccess: () => {
      queryClient.refetchQueries(CANDIDATES_CONFLICTS_KEY)
    }
  })
}

export function useMutateReuseCandidate() {
  return useMutation(fetchReuseCandidateById, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([CANDIDATE_KEY, variables.id], data)
      queryClient.refetchQueries(CANDIDATES_KEY)
      queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
      queryClient.refetchQueries(APPOINTMENTS_KEY)
      queryClient.refetchQueries(CANDIDATES_MINE)
      queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
    }
  })
}
