import { Typography, Button, Drawer, Row, Col, Divider, Input, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import orgLayersImg from '../../../../../img/orgStructure/layers-grey.svg'
import orgLayersLightImg from '../../../../../img/orgStructure/layers-light-grey.svg'
const { Text } = Typography
import { layers as defaultLayers, noOrgStructureName } from '../../../../../constants/orgStructure'
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { useMutateAddOrgStructure } from '../../../../../api/orgStructure'

function EditLayers() {
  const orgStructure = useContext(OrgStructureContext)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [orgLayers, setOrgLayers] = useState([])
  const handleChangeLevel = ({ target }) => {
    orgLayers[target.id].value = target.value
    setOrgLayers([...orgLayers])
  }

  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure,
    isLoading: isLoadingOrgStructure
  } = useMutateAddOrgStructure()

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
      setIsDrawerOpen(false)
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  useEffect(() => {
    if (isDrawerOpen) {
      const data = defaultLayers.map((item, index) => ({
        ...item,
        value: orgStructure?.layers?.[index]
      }))
      setOrgLayers(data)
    } else {
      setOrgLayers([])
    }
  }, [isDrawerOpen, orgStructure])

  const setLayer = (data, layerIndex = 0) => {
    if (layerIndex >= orgLayers.length) return null

    data?.children?.forEach(item => {
      item.type = orgLayers[layerIndex]?.value || noOrgStructureName
      setLayer(item, layerIndex + 1)
    })
  }
  const handleClose = () => setIsDrawerOpen(false)
  const handleSave = () => {
    const units = { children: [...orgStructure.units] }
    setLayer(units)
    const data = {
      layers: orgLayers
        .map(
          (item, index) => item.value || (orgStructure.layers.length > index && noOrgStructureName)
        )
        .filter(Boolean),
      units: units.children
    }
    mutateOrgStructure(data)
  }

  return (
    <div className="">
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsDrawerOpen(true)}
      >
        <img src={orgLayersLightImg} alt="button layers" />
        <Text>Уровни</Text>
      </Button>
      <Drawer
        closable={false}
        width={500}
        placement="right"
        onClose={handleClose}
        visible={isDrawerOpen}
      >
        <div className="orgStructure-layers-edit">
          <Row justify="space-between" align="large">
            <div className="block-title filters">Уровни</div>
            <Col>
              <Button
                type="link"
                size="medium"
                onClick={handleClose}
                disabled={isLoadingOrgStructure}
              >
                Отмена
              </Button>
              <Button
                type="primary"
                size="medium"
                onClick={handleSave}
                loading={isLoadingOrgStructure}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
          <Divider className="small" />
          {orgLayers.map((level, index) => {
            const disabled = index && !orgLayers[index - 1]?.value
            return (
              <Input
                key={level._id || level.name}
                id={index}
                className="layer"
                placeholder={disabled ? level.name : level.placeholder}
                onChange={handleChangeLevel}
                value={level.value}
                prefix={<img src={orgLayersImg} alt="input prefix" />}
              />
            )
          })}
          <div className="mt-3">
            <Text>
              Нужно больше уровней? <a href="">Дайте нам знать...</a>
            </Text>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
export default EditLayers
