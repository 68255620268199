import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import {
  DepartmentsListWithPlans,
  CandidateRejectForm,
  RecallForm,
  SchedulerCustom
} from '../../index'
import { appConfig } from '../../../../../constants/appConfig'
import { UtmModal } from '../UtmModal'
import { Form } from 'antd'
import { useReferences } from '../../../../../hooks/useReferences'
import { simpleReducer } from '../../../../../helpers'

const {
  rejectedByCandidate,
  rejectedByDepartment,
  rejectedByRecruiter,
  scheduleInterview,
  preInterview,
  createdByHiringManager
} = appConfig.workFlows.candidate.actions

const { later } = appConfig.workFlows.call.actions

/**
 * Need replace all cases and returned Components
 */
function ActionForm(props) {
  const [form] = Form.useForm()
  const initUtms = {
    requestType: null,
    utm_medium: null
  }
  const [utms, setUtms] = useReducer(simpleReducer, initUtms)

  useEffect(() => {
    /**
     * Очищаем форму от предыдущего профиля
     * так как в новом профиле может не быть некоторых полей например skype
     * мы предотвращаем сохранение этих полей от предыдущего профиля
     */
    form.resetFields()
    setUtms({
      requestType: props.candidate.requestType,
      utm_medium: props.candidate.utm_medium
    })
  }, [form, props.candidate])

  useReferences({
    requestTypes: true,
    utms: true,
    utmsQuery: utms.requestType
  })
  const handleSetRequestType = value => {
    setUtms({
      requestType: value,
      utm_medium: null
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null
    })
  }
  const handleSetUtmMedium = value => {
    setUtms({
      ...utms,
      utm_medium: value
    })
    form.setFieldsValue({
      utm_source: null
    })
  }
  switch (props.action.actionId) {
    case rejectedByCandidate:
    case rejectedByDepartment:
    case rejectedByRecruiter:
      return (
        <CandidateRejectForm
          {...props}
          buttonsProps={{ ok: { className: 'green' }, cancel: { className: 'red' } }}
        />
      )
    case scheduleInterview:
      if (props.action.step === 1) {
        return <DepartmentsListWithPlans {...props} title={undefined} tableHeight={350} />
      }
      if (props.action.step === 2) {
        return <SchedulerCustom {...props} department={props.action.department} />
      }
      break
    case later:
      return <RecallForm {...props} />
    case preInterview:
    case createdByHiringManager:
      return (
        <UtmModal
          visible={true}
          onOk={() => {
            const values = form.getFieldsValue()
            props.onAction(props.action, values)
          }}
          onCancel={() => {
            props.onAction(null, null)
          }}
          form={form}
          requestType={utms?.requestType}
          utmMedium={utms?.utm_medium}
          onRequestTypeChange={handleSetRequestType}
          onUtmMediumChange={handleSetUtmMedium}
        />
      )
    default:
      return null
  }
}
export default ActionForm

ActionForm.propTypes = {
  action: PropTypes.shape().isRequired
}
ActionForm.defaultProps = {
  action: {}
}
