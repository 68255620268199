import moment from 'moment'
import { numberDeclension } from './index'
import { appConfig } from '../constants/appConfig'

export function passedTimeRu(dateString) {
  const date = moment(dateString)
  const now = moment()

  // Если разница в секундах положительная, то заданная дата из будущего, просто покажем её пусть видят
  const diffInSeconds = date.diff(now, 's')
  if (diffInSeconds > 0) {
    return date.format(appConfig.formats.shortDateAndTime)
  }

  // Если разница в секундах отрицательная, то заданная дата из прошлого, посчитаем разницу уже без учета знака
  const diffInDays = Math.abs(date.diff(now, 'd'))
  const diffInHours = Math.abs(date.diff(now, 'h'))
  const diffInMinutes = Math.abs(date.diff(now, 'm'))
  // Несколько минут назад, полных часов, дней еще нет
  if (diffInHours === 0 && diffInDays === 0) {
    return `${diffInMinutes} ${numberDeclension(diffInMinutes, [
      'минуту',
      'минуты',
      'минут'
    ])} назад`
  }
  // Несколько часов назад, дней еще нет, минуты уже не важны
  if (diffInHours > 0 && diffInHours < 24) {
    return `${diffInHours} ${numberDeclension(diffInHours, ['час', 'часа', 'часов'])} назад`
  }
  // Больше дня назад, часы и минуты не важны
  return date.format(appConfig.formats.shortDateAndTime)
}

export function passedDaysRu(dateString) {
  const date = moment(dateString)
  const now = moment()

  const days = Math.abs(date.diff(now, 'd'))
  return `${days} ${numberDeclension(days, ['день', 'дня', 'дней'])}`
}

export function formatDateTime(dateString) {
  return moment(dateString).format(appConfig.formats.dateAndTime)
}

export function getAge(birthday) {
  return moment().diff(birthday, 'years')
}

/**
 * Создает дефаултный массив рабочих полных суток на всю неделю.
 * Чтобы было отличие от null при сложении с реальным диапазоном рабочих дней
 */
export function makeWeekFullWorkingDays() {
  return Array.from({ length: 7 }, () => ({ start: '00:00', end: '23:59' }))
}

// Показывает разницу в датах словами, если не задана вторая дата, то будет сравнение с "сейчас"
export const getStringDiffDates = (firstDate, secondDate) => {
  let message = ''
  const firstMoment = moment(firstDate)
  const secondMoment = secondDate ? moment(secondDate) : moment()
  const days = firstMoment.diff(secondMoment, 'd')
  const hours = firstMoment.diff(secondMoment, 'h')
  let minutes = firstMoment.diff(secondMoment, 'm')

  if (hours < 24) {
    minutes = minutes - 60 * hours
  }

  if (days >= 1) {
    message = 'Истекает ' + firstMoment.format(appConfig.formats.shortDate)
  } else if (days < 1 && days >= 0 && hours < 24 && minutes > 0) {
    message = 'Истекает через ' + (hours ? hours + ' ч. ' : '') + minutes + ' мин.'
  } else if (days <= 0 && (hours < 0 || minutes < 0)) {
    if (days < 0) {
      message = 'Просрочено на ' + Math.abs(days) + ' д.'
    } else {
      message = 'Просрочено на ' + Math.abs(hours) + ' ч. ' + Math.abs(minutes) + ' мин.'
    }
  }
  return message
}
