import { Form, Modal } from 'antd'
import { RequestTypeItem, UtmMediumItem, UtmSourceItem } from '../FormItems/CandidateProfileItems'
import React, { useCallback } from 'react'

export function UtmModal({
  onOk,
  onCancel,
  visible,
  form,
  requestType,
  utmMedium,
  onRequestTypeChange,
  onUtmMediumChange
}) {
  const handleRequestTypeChange = useCallback(
    value => {
      form.setFieldsValue({
        utm_medium: null,
        utm_source: null,
        utm_campaign: null
      })
      onRequestTypeChange?.(value)
    },
    [form, onRequestTypeChange]
  )
  return (
    <Modal
      width={480}
      title="Укажите источник"
      visible={visible}
      cancelText="Отмена"
      okText="Готово"
      onOk={onOk}
      onCancel={onCancel}
      className="userModalForm"
    >
      <Form form={form}>
        <RequestTypeItem value={requestType} onChange={handleRequestTypeChange} />
        <UtmMediumItem value={utmMedium} onChange={value => onUtmMediumChange?.(value)} />
        <UtmSourceItem utmMedium={utmMedium} />
      </Form>
    </Modal>
  )
}
