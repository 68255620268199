import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, message, Row, Spin, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { generatePath, useHistory } from 'react-router-dom'
import { DEPARTMENT_PAGE } from '../../../../constants/routes'
import { useMutateCreateDepartment } from '../../../../api/department'
import { CommentItem, DadataAddressItem, EmailItem } from '../FormItems/CandidateProfileItems'
import { DepartmentStatusItem } from '../FormItems/DepartmentItems'
import { colSettings } from '../../../../constants'
import { appConfig, langCases } from '../../../../constants/appConfig'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import CurrentOrgUnits from '../OrgStructure/Actions/CurrentOrgUnits'
import { getOrgUnitsIds } from '../../../../helpers/orgStructure'
import { ConfigContext } from '../../../../contexts/configContext'

const { confirm } = Modal

function NewDepartment({ onOpenOrgUnits = () => {}, onCancel = () => {}, selectedOrgUnits }) {
  const { departmentType, getDepartmentSingularName } = useConfigContextData()
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const history = useHistory()
  const [form] = Form.useForm()
  const [address, setAddress] = useState(null)

  const { mutate, isSuccess, isError, isLoading, error } = useMutateCreateDepartment()

  useEffect(() => {
    form.resetFields()
  }, [form])

  useEffect(() => {
    if (isSuccess) {
      history.push(generatePath(DEPARTMENT_PAGE, { id: null }))
    }
    if (isError) {
      let msg = 'Ошибка добавления орг. единицы'
      if (error?.response?.status === 409) {
        msg = `Пользователь "${error?.response?.data?.user}" уже является директором ресторана "${error?.response?.data?.department}"`
      }
      message.error(msg)
    }
  }, [history, isSuccess, isError, error])

  const handleSave = useCallback(
    formData => {
      if (!address) {
        return form.setFields([{ name: 'address', errors: ['Выберите адрес из списка'] }])
      }
      const data = {
        name:
          formData.name ||
          address?.city + (address?.street_with_type ? `, ${address?.street_with_type}` : ''),
        status: formData.status || appConfig.departmentStatusesKeys.opened,
        address: formData.address,
        comment: formData.comment,
        city: address?.city,
        hiringManager: {
          name: formData.hiringManager,
          email: formData.hiringManagerEmail
        },
        manager: formData.manager,
        timeOffset: address?.timezone,
        location: {
          type: 'Point',
          coordinates: [Number(address?.geo_lon), Number(address?.geo_lat)]
        },
        parentOrganizationUnits: getOrgUnitsIds(selectedOrgUnits)
      }
      mutate({ data })
    },
    [form, mutate, address, selectedOrgUnits]
  )
  const handleFillAddress = useCallback(
    data => {
      if (!form.getFieldValue('name')) {
        form.setFieldsValue({
          name: data?.city + (data?.street_with_type ? `, ${data?.street_with_type}` : '')
        })
      }
      form.setFieldsValue([{ name: 'address', errors: [] }])
      setAddress(data)
    },
    [form]
  )

  const showConfirm = useCallback(
    formData => {
      confirm({
        title: 'Нет привязки к оргструктуре',
        icon: <ExclamationCircleOutlined />,
        content: `Данные ${getDepartmentSingularName(
          langCases.prepositional
        )?.toLowerCase()} будут созданы, но не будет привязки к оргструтуре`,
        okText: 'Подтвердить',
        cancelText: 'Отмена',
        onOk() {
          handleSave(formData)
        }
      })
    },
    [getDepartmentSingularName, handleSave]
  )

  const validateOrgUnits = useCallback(
    formData => {
      if (features?.includes(appConfig.features.orgLayers) && !selectedOrgUnits?.length) {
        showConfirm(formData)
      } else {
        handleSave(formData)
      }
    },
    [handleSave, selectedOrgUnits, showConfirm, features]
  )

  const handleOpenOrgUnits = useCallback(
    () => onOpenOrgUnits(selectedOrgUnits),
    [onOpenOrgUnits, selectedOrgUnits]
  )

  return (
    <div className="main__wrap new-department">
      <Spin spinning={isLoading}>
        <Form form={form} scrollToFirstError onFinish={validateOrgUnits}>
          <Row justify="space-between" align="middle">
            <Col>
              <div className="block-title">НОВЫЙ {departmentType}</div>
            </Col>
            <Col>
              <Button type="link" className="mr-3" onClick={onCancel} disabled={isLoading}>
                Отмена
              </Button>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                Сохранить
              </Button>
            </Col>
          </Row>
          <Divider />
          <div className="content">
            <div className="sub-title">
              Информация {getDepartmentSingularName(langCases.prepositional)?.toLowerCase()}
            </div>
            <CurrentOrgUnits onOpen={handleOpenOrgUnits} selectedOrgUnits={selectedOrgUnits} />
            <Row>
              <Col span={24}>
                <Form.Item labelCol={colSettings.full} wrapperCol={colSettings.full} name="name">
                  <Input placeholder="Введите короткое название" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DadataAddressItem
                  onChange={handleFillAddress}
                  onResetAddress={() => setAddress(null)}
                  label=""
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DepartmentStatusItem label="" />
              </Col>
            </Row>
            <div className="sub-title">{appConfig.rolesLabels[appConfig.roles.hiringManager]}</div>
            <Row>
              <Col span={24}>
                <Form.Item
                  labelCol={colSettings.full}
                  wrapperCol={colSettings.full}
                  name={appConfig.roles.hiringManager}
                  rules={[
                    {
                      required: true,
                      message: 'Введите фамилию'
                    }
                  ]}
                >
                  <Input placeholder="Введите фамилию" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <EmailItem name={appConfig.roles.hiringManager + 'Email'} required label={''} />
              </Col>
            </Row>
          </div>
          <div className="sub-title">Комментарий</div>
          <CommentItem label="" placeholder="Комментарий" />
        </Form>
      </Spin>
    </div>
  )
}
export default NewDepartment
