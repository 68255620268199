import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { colSettings } from '../../../../../constants'

const { Option } = Select

function SysVacanciesItem({ onChange, vacancyList }) {
  return (
    <Form.Item
      label="Вакансия"
      required
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="vacancy"
      rules={[{ required: true, message: 'Укажите вакансию' }]}
    >
      <Select size="middle" placeholder="Выберите вакансию" onChange={onChange}>
        {vacancyList.map(vacancy => (
          <Option key={vacancy._id} value={vacancy._id}>
            {vacancy.displayName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

SysVacanciesItem.defaultProps = {
  onChange: () => {}
}

SysVacanciesItem.propTypes = {
  onChange: PropTypes.func,
  vacancyList: PropTypes.array.isRequired
}

export default SysVacanciesItem
