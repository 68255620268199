import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, message, Row, Spin } from 'antd'
import { passedTimeRu } from '../../../helpers/time'
import { commentShape, candidateShape, planShape } from '../../../constants/propTypesShapes'
import { UserContext } from '../../../contexts/userContext'
import CommentForm from './CommentForm'
import UserAvatar from '../UserAvatar'

function CommentsItem({ entity, comment, avatarColSpan, onDelete, onCreate, onUpdate }) {
  const { user } = useContext(UserContext)
  const [isUpdate, setIsUpdate] = useState(false)

  const {
    mutate: deleteComment,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
    isLoading: isLoadingDelete
  } = onDelete()

  useEffect(() => {
    if (isSuccessDelete) {
      message.info('Комментарий удален')
    } else if (isErrorDelete) {
      message.error('Ошибка удаления комментария')
    }
  }, [isSuccessDelete, isErrorDelete])

  const handleDeleteComment = () => {
    deleteComment({ id: entity._id, commentId: comment._id })
  }

  const handleUpdateComment = () => {
    setIsUpdate(true)
  }

  const handleSaveComment = () => {
    setIsUpdate(false)
  }
  // authorName - имя в плане; author - имэйл в плане
  // user.name - имя в кандидате; user.email - имэйл в кандидате
  const commentingUser =
    comment?.authorName || comment?.author || comment?.user?.name || comment?.user?.email

  return (
    <>
      {isUpdate ? (
        <CommentForm
          entity={entity}
          comment={comment}
          afterSave={handleSaveComment}
          avatarColSpan={avatarColSpan}
          onCreate={onCreate}
          onUpdate={onUpdate}
        />
      ) : (
        <Spin spinning={isLoadingDelete}>
          <div className="comment-item">
            <Row align="middle">
              <Col span={avatarColSpan}>
                <UserAvatar size={35} userName={commentingUser} />
              </Col>
              <Col className="secondary mr-3 comment-head">{commentingUser}</Col>
              <Col className="secondary ml-3 comment-head">{passedTimeRu(comment.createdAt)}</Col>
            </Row>
            <Row>
              <Col span={avatarColSpan}>&nbsp;</Col>
              <Col span={24 - avatarColSpan}>
                <div className="comment-text">{comment.text}</div>
                {user.login === comment.author && (
                  <div className="actions">
                    <Button type="link" onClick={handleUpdateComment}>
                      Изменить
                    </Button>
                    <Button type="link" onClick={handleDeleteComment}>
                      Удалить
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Spin>
      )}
    </>
  )
}

CommentsItem.defaultProps = {
  avatarColSpan: 3
}

CommentsItem.propTypes = {
  entity: PropTypes.oneOfType([candidateShape, planShape]).isRequired,
  comment: commentShape.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  avatarColSpan: PropTypes.number
}

export default CommentsItem
