import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Tabs } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useRecoilState } from 'recoil'

import { appConfig } from '../../../../constants/appConfig'
import { CandidateItem } from '../'
import * as helper from '../../../../helpers'
import { UserContext } from '../../../../contexts/userContext'
import { debounce, sortByLastHistory } from '../../../../helpers'
import { hiringManagerSettingsAtom, findSettingsAtom } from '../../../../recoil/atoms'

const { TabPane } = Tabs
const { Search } = Input

const CandidateGroupMenu = ({
  groups,
  showProfile,
  status = 'candidate',
  searchKey = 'search',
  searchByButton = false,
  isSearch = false // компонент используется на странице поиска
}) => {
  const { user } = useContext(UserContext)
  const [hiringManagerSettings, setHiringManagerSettings] =
    useRecoilState(hiringManagerSettingsAtom)
  const [findSettings, setFindSettings] = useRecoilState(findSettingsAtom)
  const [settings, setSettings] = useMemo(
    () =>
      isSearch
        ? [findSettings, setFindSettings]
        : [hiringManagerSettings, setHiringManagerSettings],
    [findSettings, setFindSettings, hiringManagerSettings, setHiringManagerSettings, isSearch]
  )

  const [search, setSearch] = useState(settings?.[searchKey] || '')

  const setContextSearch = useCallback(
    value =>
      setSettings(oldValue => ({
        ...oldValue,
        [searchKey]: value
      })),
    [searchKey, setSettings]
  )

  const handleSearch = useCallback(
    value => setContextSearch(value.replace(/\D/g, '')),
    [setContextSearch]
  )

  const debounceSearch = useMemo(
    () =>
      debounce(value => {
        setContextSearch(value)
      }, 800),
    [setContextSearch]
  )

  const handleChangeSearch = useCallback(
    ({ target }) => {
      setSearch(target.value)
      !searchByButton && debounceSearch(target.value.replace(/\D/g, ''))
    },
    [searchByButton, debounceSearch]
  )

  const groupsState = useMemo(
    () => helper.groupBy(groups.sort(sortByLastHistory()), 'state.name'),
    [groups]
  )

  const existApplicationStatusList = useMemo(
    () => appConfig.statuses[status].filter(item => groupsState[item.key]?.length > 0),
    [groupsState, status]
  )

  const activeTab = useMemo(() => {
    return (
      existApplicationStatusList.find(status => {
        return status.key === settings?.activeTab
      })?.key || existApplicationStatusList[0]?.key
    )
  }, [existApplicationStatusList, settings])

  // @TODO использовать из хелпера getStringDiffDates
  const getDueDateRes = useCallback(groupItem => {
    let dueDateRes = {}
    const currentState = groupItem?.history[groupItem?.history?.length - 1]
    if (currentState) {
      const now = moment()
      const departmentDate = moment(currentState.expireAt)
      const days = departmentDate.diff(now, 'd')
      const hours = departmentDate.diff(now, 'h')
      let minutes = departmentDate.diff(now, 'm')

      if (hours < 24) {
        minutes = minutes - 60 * hours
      }

      dueDateRes = { msg: '', style: 'green' }

      if (days >= 1) {
        dueDateRes.msg = 'Истекает ' + departmentDate.format(appConfig.formats.shortDate)
      } else if (days < 1 && days >= 0 && hours < 24 && minutes > 0) {
        dueDateRes.msg = 'Истекает через ' + (hours ? hours + ' ч. ' : '') + minutes + ' мин.'
      } else if (days <= 0 && (hours < 0 || minutes < 0)) {
        dueDateRes.style = 'red'
        if (days < 0) {
          dueDateRes.msg = 'Просрочено на ' + Math.abs(days) + ' д.'
        } else {
          dueDateRes.msg = 'Просрочено на ' + Math.abs(hours) + ' ч. ' + Math.abs(minutes) + ' мин.'
        }
      }
    }
    return dueDateRes
  }, [])

  const getStatusWthDate = useCallback(
    (status, groupItem) => {
      if (groupItem.state === appConfig.statuses.values.interviewScheduled && groupItem.interview) {
        const departmentDate = moment(groupItem.interview.start)
          ?.clone()
          .utcOffset(user?.department?.timeOffset || 0)
          .format(appConfig.formats.dateAndTime)
        return `${status.label} ${departmentDate}`
      }
      return status.label
    },
    [user?.department?.timeOffset]
  )

  const handleTabsChange = useCallback(
    value =>
      setSettings(oldValue => ({
        ...oldValue,
        activeTab: value
      })),
    [setSettings]
  )

  const renderTabs = useMemo(() => {
    return (
      <Tabs className="custom-tabs" onChange={handleTabsChange} activeKey={activeTab}>
        {existApplicationStatusList.map(status => {
          const count = groupsState[status.key]?.length || 0
          const groupList = groupsState[status.key] || []
          return (
            <TabPane key={status.key} tab={`${status.label} (${count})`}>
              {groupList.map(groupItem => (
                <CandidateItem
                  key={groupItem._id}
                  candidate={groupItem}
                  dueDate={getDueDateRes(groupItem)}
                  showProfile={showProfile}
                  status={getStatusWthDate(status, groupItem)}
                  interview={groupItem.state.interview}
                  interviewerDepartment={groupItem.application?.interviewerDepartment}
                  department={groupItem.application?.department}
                  isSearch={isSearch}
                />
              ))}
            </TabPane>
          )
        })}
      </Tabs>
    )
  }, [
    activeTab,
    existApplicationStatusList,
    getDueDateRes,
    getStatusWthDate,
    groupsState,
    handleTabsChange,
    showProfile,
    isSearch
  ])

  return (
    <>
      <Search
        type="tel"
        name="phone"
        size="large"
        placeholder="Номер телефона"
        value={search}
        onSearch={handleSearch}
        onChange={handleChangeSearch}
        className="searchInput"
        enterButton={
          <Button type="primary" disabled={!search} icon={<SearchOutlined />} size="small" />
        }
        allowClear
      />
      {renderTabs}
    </>
  )
}

CandidateGroupMenu.propTypes = {
  groups: PropTypes.array,
  showProfile: PropTypes.func,
  status: PropTypes.string,
  searchByButton: PropTypes.bool,
  isSearch: PropTypes.bool
}

export default CandidateGroupMenu
