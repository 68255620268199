import React, { useCallback, useEffect, useState } from 'react'
import { Tree, Typography } from 'antd'

const { Text } = Typography
const fieldNames = { title: 'name', key: '_id', children: 'children' }

function OrgUnitTree({ isVisible, tree, expanded, initChecked, onChangeChecked = () => {} }) {
  const [checkedKeys, setCheckedKeys] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])

  useEffect(() => {
    if (isVisible) {
      setCheckedKeys(initChecked)
      setExpandedKeys(expanded)
    }
  }, [isVisible, expanded, initChecked])

  const onExpand = useCallback(expandedKeysValue => setExpandedKeys(expandedKeysValue), [])

  const onCheck = useCallback(
    checkedKeysValue => {
      const ids = []
      const countChildren = item => {
        if (item.isDepartment && checkedKeysValue.includes(item._id)) {
          ids.push(item._id)
        }
        item.children?.forEach(child => countChildren(child))
      }
      tree?.forEach(unit => countChildren(unit))
      onChangeChecked(ids)
      setCheckedKeys(checkedKeysValue)
    },
    [onChangeChecked, tree]
  )

  if (!tree?.[0]?._id) return <Text type="secondary">По введенному адресу ничего не найдено</Text>
  return (
    <Tree
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      defaultExpandAll
      autoExpandParent
      treeData={tree}
      fieldNames={fieldNames}
    />
  )
}
export default OrgUnitTree
