import React from 'react'
import { Form, Input } from 'antd'
import { colSettings } from '../../../../../constants'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

function PreferredDepartmentItem() {
  const { departmentType } = useConfigContextData()
  return (
    <Form.Item
      label={`Желаемый ${departmentType?.toLowerCase()}`}
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="preferredDepartment"
    >
      <Input size="middle" />
    </Form.Item>
  )
}

export default PreferredDepartmentItem
