import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'

export const DEPARTMENT_KEY = 'department'
const DEPARTMENT_SEARCH_KEY = 'departments_with_params'
export const DEPARTMENT_APPROVED_DEMAND_KEY = 'department_approved_demand'
const DEPARTMENT_PREFIX = 'departments'

const fetchGetDepartments = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  let path = DEPARTMENT_PREFIX
  return api.get({ path, params: data })
}

const fetchGetDepartmentById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: DEPARTMENT_PREFIX + '/' + id })
}

const fetchGetHQDepartments = () => {
  return api.get({ path: DEPARTMENT_PREFIX + '/hq' })
}

const fetchPostDepartmentFind = ({ queryKey }) => {
  const [, /* key */ address, search] = queryKey
  // можно передавать либо одно, либо второе. если передаешь name, то address игнорируется
  const data = {
    ...(search ? { name: search } : { address })
  }
  return api.post({ path: DEPARTMENT_PREFIX + '/find', data })
}

const patchDepartmentSettings = data => {
  return api.patch({ path: DEPARTMENT_PREFIX + '/settings', data })
}

const getDepartmentsNoDemand = ({ queryKey }) => {
  const [, , /* key */ /* second key */ params] = queryKey
  return api.get({ path: DEPARTMENT_PREFIX + `/noDemand/${params.year}/${params.month}` })
}

const postDepartmentFindWithApprovedDemand = ({ queryKey }) => {
  const [, /* key */ address, search, limit] = queryKey
  // можно передавать либо одно, либо второе. если передаешь name, то address игнорируется
  const data = {
    ...(search ? { name: search } : { address }),
    limit
  }
  return api.post({ path: DEPARTMENT_PREFIX + '/findWithApprovedDemand', data })
}

const patchDepartment = ({ id, data }) => {
  return api.patch({ path: `${DEPARTMENT_PREFIX}/${id}`, data })
}

export function useMutateDepartment() {
  return useMutation(patchDepartment, {
    onSuccess: () => {
      queryClient.refetchQueries(DEPARTMENT_KEY, { active: true })
      queryClient.refetchQueries(DEPARTMENT_SEARCH_KEY, { active: true })
    }
  })
}

const putCreateDepartment = ({ data }) => {
  return api.put({ path: DEPARTMENT_PREFIX, data })
}

export function useMutateCreateDepartment() {
  return useMutation(putCreateDepartment, {
    onSuccess: () => {
      queryClient.refetchQueries(DEPARTMENT_KEY, { active: true })
      queryClient.refetchQueries(DEPARTMENT_SEARCH_KEY)
    }
  })
}

export function useDepartmentsQuery(data, options) {
  return useQuery([DEPARTMENT_SEARCH_KEY, data], fetchGetDepartments, options)
}

export function useDepartmentByIdQuery(id, options) {
  return useQuery([DEPARTMENT_KEY, id], fetchGetDepartmentById, options)
}

export function useDepartmentFindQuery(address, search, options) {
  return useQuery([DEPARTMENT_KEY, address, search], fetchPostDepartmentFind, options)
}

export function useHQDepartmentsQuery(options) {
  return useQuery([DEPARTMENT_KEY, 'hq'], fetchGetHQDepartments, options)
}

export function useMutateDepartmentSettings() {
  return useMutation(patchDepartmentSettings, {
    onSuccess: data => {
      queryClient.setQueryData([DEPARTMENT_KEY, data.data._id], { data: data.data })
    }
  })
}

export function useDepartmentsNoDemand(params, options) {
  return useQuery([DEPARTMENT_KEY, 'noDemand', params], getDepartmentsNoDemand, options)
}

export function useDepartmentFindWithApprovedDemand({ address, search, limit = 10 }, options) {
  return useQuery(
    [DEPARTMENT_APPROVED_DEMAND_KEY, address, search, limit],
    postDepartmentFindWithApprovedDemand,
    options
  )
}
