import React, { useContext, useEffect, useReducer, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import * as RBC from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { message, Typography } from 'antd'
import moment from 'moment'

import dragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { simpleReducer } from '../../helpers'
import Toolbar from './Toolbar'
import { appConfig } from '../../constants/appConfig'
import { appointmentShape, departmentShape } from '../../constants/propTypesShapes'
import { useAppointmentsQuery } from '../../api/appointment'
import { useDepartmentByIdQuery } from '../../api/department'
import { useGetSettings } from '../../api/setting'
import { ConfigContext } from '../../contexts/configContext'
import { makeWeekFullWorkingDays } from '../../helpers/time'
import { nbsp } from '../../constants'

const DragAndDropCalendar = dragAndDrop(RBC.Calendar)
const localizer = RBC.momentLocalizer(moment)
const { Title, Text } = Typography

const SchedulerCustom = ({
  action,
  onSelectEvent,
  selectable,
  onMoveEvent,
  events,
  hideHeader,
  hideTitle,
  title,
  footer,
  department,
  disableSelect,
  defaultView,
  interview
}) => {
  const initialState = {
    city: null,
    director: null,
    interviewStart: moment()
      .hour(appConfig.interviewHours.start)
      .minute(0)
      .second(0)
      .format(appConfig.formats.shortTimeWithSeconds),
    interviewEnd: moment()
      .hour(appConfig.interviewHours.end)
      .minute(0)
      .second(0)
      .format(appConfig.formats.shortTimeWithSeconds),
    eventSelected: false,
    events,
    interviewHours:
      (department?.plan?.interviewerDepartment?._id
        ? department?.plan?.interviewerDepartment?.settings?.interviewHours
        : department?.settings?.interviewHours) || makeWeekFullWorkingDays(),
    week: {
      start: moment().startOf('week').toString(),
      end: moment().endOf('week').toString()
    }
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { settings } = useContext(ConfigContext)

  const {
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    data: settingsData
  } = useGetSettings()

  useEffect(() => {
    if (
      isSuccessSettings &&
      settingsData?.data &&
      JSON.stringify(settings.data) !== JSON.stringify(settingsData.data)
    ) {
      settings.setData(settingsData.data)
    } else if (isErrorSettings) {
      message.error('Ошибка получения настроек')
    }
  }, [settings, settingsData, isErrorSettings, isSuccessSettings])

  const activeDepartmentId =
    department?.plan?.interviewerDepartment?._id || interview?.department?._id
      ? department?.plan?.interviewerDepartment?._id || interview?.department?._id
      : department?._id

  const {
    data: appointmentsData,
    status: appointmentsStatus
    // isLoading: appointmentsIsLoading
  } = useAppointmentsQuery(
    {
      start: state.week.start,
      end: state.week.end,
      departmentId: activeDepartmentId
    },
    {
      retry: false
    }
  )
  const prepareEvents = useCallback(
    data =>
      data.map(appointment => ({
        ...appointment,
        /**
         * Время от бэка нам приходит в UTC, для корректного показа учитываем timeOffset
         * Если в appointment не указан департамент, значит собеседование хотят в локальном времени компьютера
         *
         * не проверяем "department?.plan?.interviewerDepartment" так как подразумевается что РР и РИ в одном часовом поясе
         */
        start: moment(appointment.interview.start)
          .utcOffset(
            appointment.interview?.timeOffset || department?.timeOffset || moment().utcOffset()
          )
          .format(appConfig.formats.shortDateAndTimeApi),
        end: moment(appointment.interview.end)
          .utcOffset(
            appointment.interview?.timeOffset || department?.timeOffset || moment().utcOffset()
          )
          .format(appConfig.formats.shortDateAndTimeApi),
        eventType: appointment?.hasFeedback && 'hasFeedback'
      })),
    []
  )

  useEffect(() => {
    if (
      appointmentsStatus === 'success' &&
      appointmentsData?.data?.length &&
      !state.eventSelected
    ) {
      setState({ events: prepareEvents(appointmentsData.data.filter(app => app.interview)) })
    }
  }, [appointmentsStatus, appointmentsData, prepareEvents, state.eventSelected])

  const {
    data: departmentData,
    status: departmentStatus
    // isLoading: departmentIsLoading
  } = useDepartmentByIdQuery(activeDepartmentId, {
    enabled:
      (!!department?._id && !department?.settings) ||
      (!!department?.plan?.interviewerDepartment?._id &&
        !department?.plan?.interviewerDepartment?.settings),
    retry: false
  })

  useEffect(() => {
    if (departmentStatus === 'success' && departmentData?.data?.settings?.interviewHours) {
      const { interviewHours } = departmentData.data.settings
      setState({ interviewHours })
    }
  }, [departmentStatus, departmentData])

  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (appointmentsStatus === 'error') {
      message.error('Ошибка получения расписания')
    }
    if (departmentStatus === 'error') {
      message.error('Ошибка получения орг. единиц')
    }
  }, [appointmentsStatus, departmentStatus])

  const handleSelect = useCallback(
    ({ start, end }) => {
      /**
       * if start === end so user are clicked on incorrect time slot (ex: empty slot under date)
       */
      if (start === end) return
      const eventType = 'reserve'
      const newEvents = [
        ...state.events.filter(e => e.eventType !== eventType),
        {
          start: moment(start),
          end: moment(end),
          eventType
        }
      ]

      setState({
        eventSelected: true,
        events: newEvents
      })
    },
    [state.events]
  )

  const handleNavigate = useCallback(start => {
    const week = {
      start: moment(start).startOf('week').toString(),
      end: moment(start).endOf('week').toString()
    }

    setState({ week })
  }, [])

  // const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

  const applyHoursSettings = useCallback(
    interviewHours => {
      const settingsStart = settings.data[appConfig.settings.interviewScheduler.start]
      const settingsEnd = settings.data[appConfig.settings.interviewScheduler.end]
      if (interviewHours && settingsStart && settingsEnd) {
        return interviewHours.map(day => {
          if (day.start === '0' || day.end === '0') {
            return day
          } else {
            const dayStart = moment(day.start, appConfig.formats.shortTime)
            const settingStart = moment(settingsStart, appConfig.formats.shortTime)
            const dayEnd = moment(day.end, appConfig.formats.shortTime)
            const settingEnd = moment(settingsEnd, appConfig.formats.shortTime)
            return {
              start: moment.max(dayStart, settingStart).format(appConfig.formats.shortTime),
              end: moment.min(dayEnd, settingEnd).format(appConfig.formats.shortTime)
            }
          }
        })
      }
      return interviewHours
    },
    [settings.data]
  )

  const fillDisabledDays = useCallback(
    (min, max) => {
      const workTime = applyHoursSettings(state.interviewHours)
      if (workTime) {
        const currentCalendar = document.getElementsByClassName(`department-${department?._id}`)

        // на экране может быть 2 календаря на основной странице и на дровере(модалке)
        for (const calendar of currentCalendar) {
          const header = calendar?.getElementsByClassName('rbc-time-header-cell')
          const dayCell = calendar?.getElementsByClassName('rbc-allday-cell')
          const daySlots = calendar?.getElementsByClassName('rbc-time-content')
          const monthRow = calendar?.getElementsByClassName('rbc-month-row')
          let days = []
          let cells = []
          let slots = []

          if (header && header.length > 0) {
            days = header[0].children
            cells = dayCell[0].children
            slots = daySlots[0].children

            if (workTime && days.length > 0 && cells.length > 0 && slots.length > 0) {
              for (let i = 0; i < days.length; i++) {
                if (workTime[i]?.start === '0' && workTime[i]?.end === '0') {
                  days[i].className += ' disable_day'
                  slots[i + 1].className += ' disable_day' // тут 0 - это время
                  cells[0].children[i].className += ' disable_day'
                } else {
                  const min2 = moment().startOf('day').add(moment.duration(workTime[i]?.start))
                  const max2 = moment().startOf('day').add(moment.duration(workTime[i]?.end))
                  const diffStart = min2.diff(min, 'h')
                  const diffEnd = max.diff(max2, 'h')
                  const innerSlots = slots[i + 1].getElementsByClassName('rbc-timeslot-group')

                  for (let s = 0; s < diffStart; s++) {
                    innerSlots[s].className += ' disable_day' // тут 0 - это время
                  }

                  for (let e = innerSlots.length - diffEnd; e < innerSlots.length; e++) {
                    if (!innerSlots[e]) return
                    innerSlots[e].className += ' disable_day' // тут 0 - это время
                  }
                }
              }
            }
          } else if (monthRow && monthRow.length > 0) {
            for (let i = 0; i < monthRow.length; i++) {
              let week = monthRow[i].getElementsByClassName('rbc-row-bg')
              week = week[0].getElementsByClassName('rbc-day-bg')

              for (let j = 0; j < workTime.length; j++) {
                if (workTime[j].start === 0 && workTime[j].end === 0) {
                  week[j].className += ' disable_day'
                }
              }
            }
          }
        }
      }
    },
    [applyHoursSettings, department?._id, state.interviewHours]
  )

  const handleViewChange = useCallback(() => {
    const min = moment().startOf('day').add(moment.duration(state.interviewStart))
    const max = moment().startOf('day').add(moment.duration(state.interviewEnd))
    fillDisabledDays(min, max)
  }, [fillDisabledDays, state.interviewEnd, state.interviewStart])

  const handleMoveEvent = useCallback(
    data => {
      const eventType = 'reserve'
      if (data.event.eventType === eventType) {
        const newEvents = [
          ...state.events.filter(e => e.eventType !== eventType),
          {
            start: moment(data.start),
            end: moment(data.end),
            eventType
          }
        ]

        setState({
          events: newEvents
        })
      }
      if (onMoveEvent && window.confirm('Изменить дату и время события?')) {
        onMoveEvent(data)
      }
    },
    [onMoveEvent, state.events]
  )

  const eventPropGetter = useCallback(ev => ({ className: ev.eventType }), [])

  const min = moment().startOf('day').add(moment.duration(state.interviewStart))
  const max = moment().startOf('day').add(moment.duration(state.interviewEnd))

  useLayoutEffect(() => {
    fillDisabledDays(min, max)
  }, [fillDisabledDays, min, max])

  return (
    <div className={`department-${department?._id}`}>
      {!hideTitle && (
        <Title level={4} className={''}>
          {title}
        </Title>
      )}
      {department?.plan?.interviewerDepartment?._id && (
        <Text className="danger-text">
          ВНИМАНИЕ: Собеседование будет проходить в {nbsp}
          <b>{department?.plan?.interviewerDepartment?.name}</b>
        </Text>
      )}
      <DragAndDropCalendar
        events={state.events}
        startAccessor={ev => new Date(ev.start)}
        endAccessor={ev => new Date(ev.end)}
        titleAccessor={ev => {
          // отображение заголовка для событий больше одного дня
          const start = moment(ev.start).format(appConfig.formats.shortTime)
          const end = moment(ev.end).format(appConfig.formats.shortTime)
          return `${start} - ${end}`
        }}
        resourceIdAccessor={ev => ev.candidateId}
        eventPropGetter={eventPropGetter}
        selectable={selectable}
        step={30}
        localizer={localizer}
        min={min.toDate()}
        max={max.subtract(1, 'minutes').toDate()} // .subtract - чтобы не отображать строку с последним часом на который нельзя назначить собес
        defaultView={defaultView}
        scrollToTime={new Date(2017, 1, 1, 6)}
        defaultDate={new Date()}
        onSelectEvent={onSelectEvent} // Клик по событию в календаре
        onSelectSlot={disableSelect ? null : handleSelect} // Выделение области onDragStart={console.log}
        onView={handleViewChange}
        onEventDrop={handleMoveEvent} // перетаскивание
        views={[RBC.Views.WORK_WEEK, RBC.Views.WEEK, RBC.Views.MONTH]}
        messages={appConfig.schedulerWeekNavigate}
        components={hideHeader ? { toolbar: Toolbar } : {}}
        onNavigate={handleNavigate} // переключение недель
      />
      {state.director && !state.eventSelected && <Text type="danger">* Выберите слот</Text>}
      {footer && footer(action, state)}
    </div>
  )
}

SchedulerCustom.defaultProps = {
  hideHeader: false,
  disableSelect: false,
  hideTitle: false,
  selectable: true,
  onSelectEvent: () => {},
  onMoveEvent: null,
  department: null,
  events: [],
  title: 'Перепланировать собеседование',
  footer: null,
  action: null,
  defaultView: RBC.Views.WEEK
}

SchedulerCustom.propTypes = {
  onSelectEvent: PropTypes.func,
  onMoveEvent: PropTypes.func,
  hideHeader: PropTypes.bool,
  disableSelect: PropTypes.bool,
  hideTitle: PropTypes.bool,
  selectable: PropTypes.bool,
  events: PropTypes.array,
  title: PropTypes.string,
  department: departmentShape,
  interview: appointmentShape.interview,
  action: PropTypes.shape(),
  footer: PropTypes.node,
  defaultView: PropTypes.string
}

export default SchedulerCustom
