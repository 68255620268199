import { api, queryClient } from '../api'
import { useMutation, useQuery } from 'react-query'
import {
  CANDIDATE_KEY,
  CANDIDATES_KEY,
  CANDIDATES_SEARCH_KEY,
  CANDIDATES_STATES_KEY,
  CANDIDATES_FILTER_VALUES_KEY,
  CANDIDATES_MINE
} from '../candidate'
import { APPOINTMENTS_KEY, APPOINTMENTS_USER_KEY } from '../appointment'
import {
  CANDIDATE_STATUSES_KEY,
  CURRENT_MONTH_INTERVIEWS_KEY,
  INTERVIEWS_KEY,
  LEADS_KEY,
  REJECT_REASONS_KEY,
  REQUIREMENT_KEY,
  SOURCES_AND_STATUSES_KEY
} from '../dashboard'

const WORKFLOW_STATES = 'workflowStates'
const fetchFlowAction = ({ path, data }) => {
  return api.post({ path, data })
}
const fetchWorkflowStates = () => {
  return api.get({ path: 'workflow/candidateStates' })
}

export function useMutateWorkflowStates(options) {
  return useQuery([WORKFLOW_STATES], fetchWorkflowStates, options)
}
export function useMutateFlowAction() {
  return useMutation(fetchFlowAction, {
    onSuccess: (data, variables) => {
      // update lists for hiringManager and recruiter
      // TODO: use setQueryData ?? не все данные приходят распопулированными
      queryClient.refetchQueries(CANDIDATES_KEY)
      queryClient.refetchQueries(CANDIDATES_MINE)
      queryClient.refetchQueries(CANDIDATES_STATES_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
      queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
      // update appointments on a calendar component
      queryClient.refetchQueries(APPOINTMENTS_KEY)
      queryClient.refetchQueries(APPOINTMENTS_USER_KEY, { active: true })

      queryClient.setQueryData(
        [CANDIDATE_KEY, variables.data?.candidateId || data.data.candidate._id],
        { data: data.data.candidate }
      )
      // при переводе кандидата по статусам сбрасываем кэш аналитики
      queryClient.invalidateQueries(REQUIREMENT_KEY)
      queryClient.invalidateQueries(INTERVIEWS_KEY)
      queryClient.invalidateQueries(LEADS_KEY)
      queryClient.invalidateQueries(CURRENT_MONTH_INTERVIEWS_KEY)
      queryClient.invalidateQueries(REJECT_REASONS_KEY)
      queryClient.invalidateQueries(CANDIDATE_STATUSES_KEY)
      queryClient.invalidateQueries(SOURCES_AND_STATUSES_KEY)
    }
  })
}
