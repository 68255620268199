import { noOrgStructureName } from '../constants/orgStructure'

export const getOrgUnitsIds = listUnits => {
  return Array.isArray(listUnits)
    ? listUnits?.reduce((prev, cur) => {
        prev.push(cur?._id || cur)
        return prev
      }, [])
    : null
}

export const getSelectedUnitsByIds = (ids, orgStructure) => {
  if (ids?.length && orgStructure) {
    const initUnits = []
    ids.reduce((prev, curId) => {
      const unit = prev?.find(item => item._id === curId)
      unit && initUnits.push(unit)
      return unit?.children
    }, orgStructure?.units || [])
    return initUnits
  }
  return null
}

export const addDepartmentsToBranch = (data, departmentsList) => {
  // parentOrganizationUnits - вся цепочка орг единиц(ищется по всем родителям)
  // а сам департамент принадлежит к последней орг единице
  const departments = departmentsList?.filter(
    e => e?.parentOrganizationUnits?.[e?.parentOrganizationUnits?.length - 1] === data._id
  )
  // если орг единицы нет ни в одном из департаментов то значит ничего на орг единицу не назначено
  if (!departmentsList?.find(e => e?.parentOrganizationUnits?.includes(data._id))) {
    data.disabled = true
  }
  if (data.deleted) {
    data.name = noOrgStructureName
  }
  // обогащаем орг структуру департаментами
  if (departments?.length) {
    data.children = data.children.concat(
      departments.map(department => ({
        ...department,
        title: department.name,
        isDepartment: true
      }))
    )
  }
  data.children
    .filter(child => !child.isDepartment)
    .forEach(child => addDepartmentsToBranch(child, departmentsList))
  return data
}
