import React, { useReducer, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, AutoComplete, message, Spin } from 'antd'

import { useDadataSearch } from '../../../../../api/dadata'
import { debounce, simpleReducer } from '../../../../../helpers'
import { colSettings } from '../../../../../constants'

const DadataAddressItem = ({ onChange, address, label, onResetAddress }) => {
  const [state, setState] = useReducer(simpleReducer, {
    address,
    suggestions: []
  })
  const handleChange = value => {
    const data = state.suggestions?.find(s => s.value === value)?.data
    onChange?.(data)
  }

  const {
    status: statusDadataSearch,
    data: dadataSearch,
    isLoading
  } = useDadataSearch(state.address, { enabled: !!state.address })

  useEffect(() => {
    if (statusDadataSearch === 'success') {
      setState({ suggestions: dadataSearch.data?.suggestions })
    } else if (statusDadataSearch === 'error') {
      message.error('Ошибка получения подсказок Dadata')
    }
  }, [dadataSearch, statusDadataSearch])

  const getSuggestions = useMemo(
    () =>
      debounce(query => {
        onResetAddress?.()
        setState({ address: query })
      }, 800),
    [onResetAddress]
  )

  return (
    <Form.Item
      label={label}
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="address"
      value={state.address}
      required
      rules={[
        {
          required: true,
          message: 'Укажите адрес'
        }
      ]}
    >
      <AutoComplete
        options={state.suggestions}
        onSelect={handleChange}
        onSearch={getSuggestions}
        placeholder="Москва, ул. Ленина, д. 1"
        dropdownMatchSelectWidth={false}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
      />
    </Form.Item>
  )
}

export default DadataAddressItem

DadataAddressItem.defaultProps = {
  address: '',
  label: 'Адрес'
}

DadataAddressItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  address: PropTypes.string,
  label: PropTypes.string
}
