import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Spin } from 'antd'
import ExtraBlock from './ExtraBlock'
import { UserContext } from '../../../../contexts/userContext'
import { periodShape, planShape } from '../../../../constants/propTypesShapes'
import { convertObjectToArray, getObjectValueByPath } from '../../../../helpers'
import { convertCrewmenToObject, isRejected } from '../../../../helpers/plan'
import ManagerButtons from './ManagerButtons'
import RequestNeedTable from './RequestNeedTable'
import { useSystemVacancies } from '../../../../hooks/useSystemVacancies'

function PlanningPersonal({
  data = {},
  onSaveMutate,
  isLoading,
  isNewPlan,
  activePeriod,
  plan,
  departmentForNewPlanId = null,
  departmentId
}) {
  const { user, isManager, isAdmin, isHiringManager } = useContext(UserContext)
  const [year, month] = (activePeriod?.id || '').split('/')
  const [wasFormEdited, setWasFormEdited] = useState(false)
  const [form] = Form.useForm()
  const { data: sysVacanciesData, isLoading: isLoadingSysVacancies } = useSystemVacancies()

  /**
   * Если текущий пользователь не может редактировать, блокируем поля. Прошлый период редактировать нельзя вообще
   */
  const isEditableByUser = useCallback(
    plan => {
      if (isManager || isAdmin) {
        return !isRejected(plan)
      }
      return isHiringManager
    },
    [isManager, isAdmin, isHiringManager]
  )
  const readOnly = useMemo(
    () => !isEditableByUser(data) || activePeriod?.isPast,
    [activePeriod?.isPast, data, isEditableByUser]
  )

  const handleFinish = values => {
    Object.keys(values.crewmen).forEach(key => {
      values.crewmen[key] = {
        ...values.crewmen[key],
        name: key,
        citizenship: [].concat(values.crewmen[key].citizenship?.cis ? ['cis'] : [])
      }
    })

    onSaveMutate(
      {
        ...values,
        ...vacanciesToArray(values),
        departmentId: data?.department?._id || user?.department?._id,
        // из бэка возвращается обьект при выборе в селекторе выставляется в значение только ID
        interviewerDepartment:
          values?.interviewerDepartment?._id || values?.interviewerDepartment || null,
        month: month || data?.month,
        year: year || data?.year,
        planId: data?._id,
        ...(departmentId && { departmentId })
      },
      {
        onError: () => {
          setWasFormEdited(true)
        },
        onSuccess: () => {
          setWasFormEdited(false)
        }
      }
    )
  }

  const n = field => (field === Infinity ? 0 : field || 0)
  /**
   * Если в данных плана в блоке updatedBySystem отсутствует какое-либо поле, нужное для расчётов, разблокируем его
   */
  const fieldIsEditable = useCallback(
    field => {
      return getObjectValueByPath(data.updatedBySystem, field) === undefined
    },
    [data]
  )

  /**
   * Получение поля для расчётов.
   * Если план новый (данные нового плана копируются из предыдущего месяца в блок filledByDirector.
   * План считается новым, пока его не сохранил директор),
   * либо поле отсутствует в блоке updatedBySystem, берём значение из блока filledByDirector
   */
  const getFieldName = useCallback(
    field => {
      return isNewPlan || fieldIsEditable(field) ? 'filledByDirector' : 'updatedBySystem'
    },
    [isNewPlan, fieldIsEditable]
  )

  const calculateEstimate = useCallback(
    data => {
      const ratesFullTime = data?.rates?.fullTime || {}
      const ratesPartTime = data?.rates?.partTime || {}
      const averageFullTime =
        data?.[getFieldName(['averageOutput', 'fullTime'])]?.averageOutput?.fullTime
      const averagePartTime =
        data?.[getFieldName(['averageOutput', 'partTime'])]?.averageOutput?.partTime
      const tradeTurnover = n(data?.[getFieldName('tradeTurnover')]?.tradeTurnover)
      const spmh = n(data?.[getFieldName('spmh')]?.spmh)
      const estimate = data?.estimate || {}

      const planWorkingHours = Math.round(tradeTurnover / spmh)
      const planFullTime = Math.round(
        (planWorkingHours - n(estimate.planPartTime) * n(averagePartTime)) / n(averageFullTime)
      )
      const factWorkingHours = Math.round(
        n(ratesFullTime.employees) * n(averageFullTime) -
          n(ratesFullTime.dismiss) * n(averageFullTime) -
          n(ratesFullTime.training) * n(averageFullTime) +
          (n(ratesPartTime.employees) * n(averagePartTime) -
            n(ratesPartTime.dismiss) * n(averagePartTime) -
            n(ratesPartTime.training) * n(averagePartTime))
      )

      const workingHoursPercent = Math.round((factWorkingHours / planWorkingHours) * 100)
      const staffFullnessPercent = Math.round(
        ((n(ratesFullTime.employees) -
          n(ratesFullTime.dismiss) -
          n(ratesFullTime.training) +
          (n(ratesPartTime.employees) - n(ratesPartTime.dismiss) - n(ratesPartTime.training))) /
          (n(planFullTime) + n(estimate.planPartTime))) *
          100
      )
      const demandFullTime = Math.round(
        n(planFullTime) -
          n(ratesFullTime.employees) +
          n(ratesFullTime.dismiss) +
          n(ratesFullTime.training)
      )
      const demandPartTime = Math.round(
        n(estimate.planPartTime) -
          n(ratesPartTime.employees) +
          n(ratesPartTime.dismiss) +
          n(ratesPartTime.training)
      )

      return {
        ...data,
        estimate: {
          ...estimate,
          planWorkingHours, // Плановое количество часов линейки
          planFullTime, // План по ФТ
          factWorkingHours, // Фактическое количество часов
          workingHoursPercent, // Выработка часов линейки
          staffFullnessPercent, // Заполненность штата
          demandFullTime, // Потребность по ФТ
          demandPartTime // Потребность по ПТ
        }
      }
    },
    [getFieldName]
  )

  const vacanciesToArray = data => ({
    ...data,
    crewmen: [...convertObjectToArray(data.crewmen)]
  })

  const editFormData = useCallback(
    (changedFields, values) => {
      form.setFieldsValue(calculateEstimate(values))
      setWasFormEdited(true)
    },
    [form, calculateEstimate]
  )

  /**
   * setting initialValues with async query
   */
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      !wasFormEdited && form.setFieldsValue(calculateEstimate(convertCrewmenToObject(data)))
    } else {
      form.resetFields()
    }
  }, [form, data, calculateEstimate, wasFormEdited])

  const isShowManagerButtons = useMemo(
    () => !readOnly && (isManager || isAdmin) && !isNewPlan && data?._id,
    [readOnly, isManager, isAdmin, isNewPlan, data?._id]
  )

  return (
    <Spin spinning={isLoading || isLoadingSysVacancies} size="large">
      <Form
        form={form}
        className="planningPersonal mt-3"
        onValuesChange={editFormData}
        onFinish={handleFinish}
        colon={false}
      >
        {/* TODO: скрыть на стейдж
        <CalculationNeedBlock readOnly={readOnly} isNewPlan={isNewPlan} fieldIsEditable={fieldIsEditable}/>
        <CalculationsBlock readOnly={readOnly}/>
        */}
        <div className="request-need-wrapper">
          <RequestNeedTable
            readOnly={readOnly}
            data={data.crewmen?.length ? data.crewmen : sysVacanciesData}
          />
        </div>

        <ExtraBlock
          plan={plan}
          form={form}
          interviewerDepartment={data?.interviewerDepartment}
          departmentForNewPlanId={departmentForNewPlanId}
          onEdit={setWasFormEdited}
        />

        <div className="btn-wrap mt-3">
          {!readOnly && (
            <Button
              htmlType="submit"
              type="primary"
              className="green"
              size="large"
              disabled={!wasFormEdited}
              loading={isLoading}
            >
              Сохранить
            </Button>
          )}
          {isShowManagerButtons && <ManagerButtons plan={data} planForm={form} />}
        </div>
      </Form>
    </Spin>
  )
}

PlanningPersonal.propTypes = {
  data: PropTypes.oneOfType([planShape.approved, planShape.current]),
  plan: planShape.isRequired,
  onSaveMutate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isNewPlan: PropTypes.bool,
  activePeriod: periodShape.isRequired,
  departmentForNewPlanId: PropTypes.string,
  departmentId: PropTypes.string
}

export default PlanningPersonal
