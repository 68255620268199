import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'

export const AGENCY_KEY = 'agency'
const AGENCY_PREFIX = 'agencies'

const fetchGetAgencies = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.get({ path: AGENCY_PREFIX, params: data })
}

const putCreateAgency = ({ data }) => {
  return api.put({ path: AGENCY_PREFIX, data })
}

const fetchGetAgencyById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: AGENCY_PREFIX + '/' + id })
}

const patchAgency = ({ id, data }) => {
  return api.patch({ path: `${AGENCY_PREFIX}/${id}`, data })
}

const postAgencyApiKeyMail = ({ id }) => {
  return api.post({ path: `${AGENCY_PREFIX}/${id}/sendEmail` })
}

export function useMutateEditAgency() {
  return useMutation(patchAgency, {
    onSuccess: () => {
      queryClient.refetchQueries(AGENCY_KEY, { active: true })
    }
  })
}

export function useMutateCreateAgency() {
  return useMutation(putCreateAgency, {
    onSuccess: () => {
      queryClient.refetchQueries(AGENCY_KEY, { active: true })
    }
  })
}

export function useMutationSendApiKeyAgency() {
  return useMutation(postAgencyApiKeyMail)
}

export function useAgenciesQuery(data, options) {
  return useQuery([AGENCY_KEY, data], fetchGetAgencies, options)
}

export function useAgencyQuery(id, options) {
  return useQuery([AGENCY_KEY, id], fetchGetAgencyById, options)
}
