import React, { useState, useEffect, useReducer, useMemo } from 'react'
import { Layout, message, Spin } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { isNoAuthToken, simpleReducer } from './helpers'
import MassRecruitment from './modules/massRecruitment/MassRecruitment'

import { Theme } from './components/Theme'
import { UserContext } from './contexts/userContext'
import { ConfigContext, initReferences } from './contexts/configContext'
import { AppointmentContext } from './contexts/appointmentContext'
import { useGetProfileMutate } from './api/users'
import { appConfig } from './constants/appConfig'
import { noAuthPrefix } from './constants'
import { useGetSettings } from './api/setting'
import { useQueryFeatures } from './api/features'
import setLanguage from './helpers/moment-ru'
import { FORGOT_PASSWORD, PASSWORD_RECOVERY, EXTERNAL_BUTTON } from './constants/routes'

function App() {
  const [user, setUser] = useState({})
  const [refsConfig, setRefsConfig] = useReducer(simpleReducer, initReferences)
  const [settingsConfig, setSettingsConfig] = useReducer(simpleReducer, {})
  const [featuresConfig, setFeaturesConfig] = useState(null)
  const [appointment, setAppointment] = useState({})
  const history = useHistory()
  const { pathname } = useLocation()

  const setUserAndSentryUser = user => {
    Sentry.setUser(user)
    setUser(user)
  }

  /**
   * Get Profile if token will saved in localStorage
   */
  const { isError, mutate } = useGetProfileMutate({
    onSuccess: data => setUserAndSentryUser(data.data),
    onError: () => {
      Object.keys(user).length && setUser({})
      // external button contain own simple auth page
      ![FORGOT_PASSWORD, PASSWORD_RECOVERY, EXTERNAL_BUTTON].includes(pathname) &&
        history.replace('/')
    }
  })

  useEffect(() => {
    if (pathname.includes(noAuthPrefix) && isNoAuthToken()) return
    /**
     * we load and save user only if have token (user is logged) and don't have user data
     * most popular events - reload page, open a new tab
     */
    if (!Object.keys(user).length) {
      mutate()
    }
  }, [setUser, history, mutate, user, pathname])

  const {
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    data: dataSettings
  } = useGetSettings({
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!Object.keys(user).length
  })

  useEffect(() => {
    if (isSuccessSettings && dataSettings?.data) {
      setSettingsConfig(dataSettings.data)
      setLanguage(dataSettings?.data?.language)
    } else if (isErrorSettings) {
      message.error('Ошибка получения настроек')
    }
  }, [dataSettings, isErrorSettings, isSuccessSettings])

  const {
    isSuccess: isSuccessFeatures,
    isError: isErrorFeatures,
    data: dataFeatures
  } = useQueryFeatures({
    enabled: !!Object.keys(user).length
  })
  useEffect(() => {
    if (isSuccessFeatures && dataFeatures?.data) {
      setFeaturesConfig(dataFeatures?.data || [])
    } else if (isErrorFeatures) {
      message.error('Ошибка получения списка фич')
      // В случае ошибки получения фич, считать что фич нет и показывать функционал по умолчанию
      setFeaturesConfig([])
    }
  }, [dataFeatures, isErrorFeatures, isSuccessFeatures])

  const userContextValue = useMemo(() => {
    return {
      user,
      setUser,
      isRecruiter: user.roles?.includes(appConfig.roles.recruiter),
      isHiringManager: user.roles?.includes(appConfig.roles.hiringManager),
      isManager: user.roles?.includes(appConfig.roles.manager),
      isAdmin: user.roles?.includes(appConfig.roles.admin),
      isPrioritySelector: user.roles?.includes(appConfig.roles.prioritySelector),
      isNoAuthorize: isError,
      //@TODO: проверить нужно ли еще?
      isOneRole: user.roles?.length === 1
    }
  }, [user, isError])

  const isLoadedFeatures = useMemo(() => Array.isArray(featuresConfig), [featuresConfig])

  const configContextValue = useMemo(
    () => ({
      references: { data: refsConfig, setData: setRefsConfig },
      features: {
        data: featuresConfig,
        setData: setFeaturesConfig,
        isLoaded: isLoadedFeatures
      },
      settings: { data: settingsConfig, setData: setSettingsConfig }
    }),
    [featuresConfig, refsConfig, settingsConfig, isLoadedFeatures]
  )

  const isShowLoader = useMemo(
    () =>
      ((!Object.keys(user).length && !pathname.includes(noAuthPrefix)) || !isLoadedFeatures) &&
      !isError,
    [isError, pathname, user, isLoadedFeatures]
  )

  return (
    <Theme>
      <div className="App">
        <UserContext.Provider value={userContextValue}>
          <ConfigContext.Provider value={configContextValue}>
            <AppointmentContext.Provider value={{ appointment, setAppointment }}>
              <Layout>
                {/* TODO: Добавить компонент модули в котором по условию подключать все доступные для лицензии модули
                 в том числе массРекрутмент тоже подключить там как модуль*/}
                {isShowLoader ? (
                  <Spin spinning={isShowLoader} size="large" className="full-screen-spin" />
                ) : (
                  <MassRecruitment />
                )}
              </Layout>
            </AppointmentContext.Provider>
          </ConfigContext.Provider>
        </UserContext.Provider>
      </div>
    </Theme>
  )
}

export default App
