import React, { useCallback } from 'react'
import { Button, Col, Divider, Row, Typography, Input } from 'antd'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import removeUserImg from '../../../../../img/orgStructure/remove-red.svg'
import OrgUnitTree from './OrgUnitTree'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

const { Text } = Typography
const { Search } = Input

function EditOrgUser({
  tree,
  onChangeAddress,
  onDeleteUser,
  onClose,
  onSave,
  isLoadingUpdateUser,
  isHeaderWithDeleteBtn,
  lastSelectedUnitChildrenIds,
  showSecondaryBlock,
  settingUser,
  onChangeUserScope
}) {
  const { departmentType } = useConfigContextData()
  const handleChecked = useCallback(
    ids => {
      onChangeUserScope({
        ...settingUser,
        scope: {
          ...settingUser.scope,
          newDepartments: ids
        }
      })
    },
    [settingUser, onChangeUserScope]
  )
  return (
    <>
      <Row justify="space-between" align="large">
        {isHeaderWithDeleteBtn ? (
          <Row onClick={onClose} align="middle" gutter={[16, 8]} className="cursor-pointer">
            <Col>
              <LeftOutlined />
            </Col>
            <Col className="block-title filters">{settingUser?.name}</Col>
          </Row>
        ) : (
          <div className="block-title filters">{settingUser?.name}</div>
        )}
        <Col>
          {settingUser ? (
            isHeaderWithDeleteBtn ? (
              <Button className="header-button" type="link" size="small" onClick={onDeleteUser}>
                <img src={removeUserImg} alt="button layers" />
                <Text type="danger">Удалить</Text>
              </Button>
            ) : (
              <>
                <Button type="link" size="medium" onClick={onClose} disabled={isLoadingUpdateUser}>
                  Отмена
                </Button>
                <Button type="primary" size="medium" onClick={onSave} loading={isLoadingUpdateUser}>
                  Сохранить
                </Button>
              </>
            )
          ) : (
            <Button type="link" size="medium" onClick={onClose}>
              Закрыть
            </Button>
          )}
        </Col>
      </Row>
      <Divider className="small" />
      <Row>
        <Search
          className="without-addon org-search mb-3"
          placeholder={`Адрес ${departmentType?.toLowerCase()}`}
          onChange={onChangeAddress}
          allowClear
          prefix={<SearchOutlined />}
        />
      </Row>
      <OrgUnitTree
        isVisible={showSecondaryBlock}
        tree={tree}
        initChecked={settingUser?.scope?.newDepartments}
        onChangeChecked={handleChecked}
        expanded={lastSelectedUnitChildrenIds}
      />
    </>
  )
}
export default EditOrgUser
