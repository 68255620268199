import './wdyr'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot, useRecoilSnapshot } from 'recoil'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { queryClient } from './api/api'
import { getSentryOptions } from './helpers/setting'
import { ErrorLayout } from './modules/massRecruitment/Screens/ErrorLayout/ErrorLayout'

Sentry.init(getSentryOptions())
function DebugObserver() {
  const snapshot = useRecoilSnapshot()
  useEffect(() => {
    console.debug('The following atoms were modified:')
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node))
    }
  }, [snapshot])

  return null
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <RecoilRoot>
        <DebugObserver />
        <Sentry.ErrorBoundary fallback={<ErrorLayout />}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Sentry.ErrorBoundary>
      </RecoilRoot>
    </React.StrictMode>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
