import { useContext, useMemo, useCallback } from 'react'

import { ConfigContext } from '../contexts/configContext'
import { appConfig, langCases } from '../constants/appConfig'
import { getDepartmentSingularList } from '../helpers/department'

export const useConfigContextData = () => {
  const {
    settings: { data: settingsData }
  } = useContext(ConfigContext)

  const departmentSingularList = useMemo(
    () =>
      getDepartmentSingularList(
        JSON.parse(settingsData[appConfig.settings.departmentTypes] || '[]')
      ),
    [settingsData]
  )
  const departmentLangData = useMemo(
    () =>
      JSON.parse(settingsData[appConfig.settings.departmentTypes] || '[]')?.find(
        item =>
          item.singular[langCases.nominative] === settingsData[appConfig.settings.departmentType]
      ),
    [settingsData]
  )
  const getDepartmentPluralName = useCallback(
    (langCase = langCases.nominative) => departmentLangData?.plural[langCase],
    [departmentLangData]
  )
  const getDepartmentSingularName = useCallback(
    (langCase = langCases.nominative) => departmentLangData?.singular[langCase],
    [departmentLangData]
  )

  return {
    departmentSingularList,
    getDepartmentPluralName,
    getDepartmentSingularName,
    lowerCaseDepartmentType: settingsData[appConfig.settings.departmentType]?.toLowerCase(),
    departmentType: settingsData[appConfig.settings.departmentType]
  }
}
