import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Button, Col, Collapse, Form, message, Row, Typography } from 'antd'
import { appConfig } from '../../../../constants/appConfig'
import { passedTimeRu } from '../../../../helpers/time'
import reloadImg from '../../../../img/reload.svg'
import { RequestTypeItem, UtmMediumItem, UtmSourceItem } from '../FormItems/CandidateProfileItems'
import { simpleReducer } from '../../../../helpers'
import { useReferences } from '../../../../hooks/useReferences'
import { useSaveUtms } from '../../../../api/integrations'

const { Panel } = Collapse
const { Text } = Typography
function IntegrationsStatus({ data, onRefresh, jobSiteData }) {
  const [activeKey, setActiveKey] = useState(null)
  const [form] = Form.useForm()
  const initUtms = {
    requestType: null,
    utm_medium: null
  }
  const [utms, setUtms] = useReducer(simpleReducer, initUtms)
  useReferences({
    requestTypes: true,
    utms: true,
    utmsQuery: utms.requestType
  })

  const {
    mutate: saveUtms,
    isSuccess: isSuccesSaveUtms,
    isError: isErrorSaveUtms,
    isLoading: isLoadingSaveUtms
  } = useSaveUtms()
  useEffect(() => {
    if (isSuccesSaveUtms) {
      message.success('Источники успешно сохранены')
    } else if (isErrorSaveUtms) {
      message.error('Ошибка сохранения источников')
    }
  }, [isSuccesSaveUtms, isErrorSaveUtms])

  useEffect(() => {
    setUtms({
      requestType: data?.settings?.tags?.requestType,
      utm_medium: data?.settings?.tags?.utmMedium
    })
    form.setFieldsValue({
      requestType: data?.settings?.tags?.requestType || null,
      utm_medium: data?.settings?.tags?.utmMedium || null,
      utm_source: data?.settings?.tags?.utmSource || null
    })
  }, [data?.settings?.tags, form])
  const handleSetRequestType = value => {
    setUtms({
      requestType: value,
      utm_medium: null
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null
    })
  }
  const handleSetUtmMedium = value => {
    setUtms({
      ...utms,
      utm_medium: value
    })
    form.setFieldsValue({
      utm_source: null
    })
  }

  const handleSaveUtms = useCallback(() => {
    const { requestType, utm_medium, utm_source } = form.getFieldsValue()
    saveUtms({
      integrationId: jobSiteData.id,
      data: { requestType, utmMedium: utm_medium, utmSource: utm_source }
    })
  }, [form, jobSiteData.id, saveUtms])
  return (
    <div className="IntegrationsStatus">
      <Row align="top" justify="space-between">
        <Col>Текущий статус</Col>
        <Col>
          {appConfig.integrationStatuses.online === data.status ? (
            <Text type="success">Онлайн</Text>
          ) : (
            <>
              <Text type="danger">Оффлайн</Text>
              <img src={reloadImg} alt="reload" className="cursor-pointer" onClick={onRefresh} />
            </>
          )}
        </Col>
      </Row>
      <Row align="top" justify="space-between">
        <Col>Последний ответ</Col>
        <Col>
          <Text type="secondary">{passedTimeRu(data.lastRespond)}</Text>
        </Col>
      </Row>
      <Row align="top" justify="space-between">
        <Col
          className="extra-preferences"
          onClick={({ target }) => {
            // проверяем что нажат заголовок колапса а не контент внутри
            if (target?.className?.includes?.('ant-collapse-header') || target?.viewBox) {
              setActiveKey(activeKey ? null : '1')
            }
          }}
        >
          <Collapse collapsible="header" expandIconPosition="right" activeKey={activeKey}>
            <Panel header="Настройки источника" key="1">
              <Form form={form}>
                <RequestTypeItem value={utms?.requestType} onChange={handleSetRequestType} />
                <UtmMediumItem
                  value={utms?.utm_medium}
                  onChange={value => handleSetUtmMedium(value)}
                />
                <UtmSourceItem utmMedium={utms?.utm_medium} />
                <Button
                  onClick={handleSaveUtms}
                  loading={isLoadingSaveUtms}
                  type="link"
                  className="button-left-text"
                >
                  Сохранить
                </Button>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  )
}
export default IntegrationsStatus
