import { Table, message, Spin, Checkbox, Button, Row, Col, Modal, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { appConfig } from '../../../../constants/appConfig'
import { useMutateCreateUser, useUsers } from '../../../../api/users'
import { renderNameWithEmail } from '../../../../helpers/department'
import { useLinkUsersJobSite } from '../../../../api/integrations'
import ModalPhoneNotice from '../../Screens/Integrations/ModalPhoneNotice'
import ModalPhoneForm from '../../Screens/Integrations/ModalPhoneForm'
import { formatPhoneNumberForApi, numberDeclension, simpleReducer } from '../../../../helpers'

const { confirm } = Modal
const { Text } = Typography

const modalTypes = {
  phoneNotice: 'phoneNotice',
  phoneForm: 'phoneForm'
}

const initialState = {
  modalType: null
}

function IntegrationsUsers({ users, jobSiteData }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)
  const [state, setState] = useReducer(simpleReducer, initialState)
  const {
    data: dataInternalUsers,
    isLoading: isLoadingInternalUsers,
    isError: isErrorInternalUsers
  } = useUsers()

  useEffect(() => {
    if (isErrorInternalUsers) {
      message.error('Ошибка получения данных о рекрутерах')
    }
  }, [isErrorInternalUsers, dataInternalUsers])

  const {
    mutate: mutateLinkUsersJobSite,
    isSuccess: isSuccessLinkUsersJobSite,
    isError: isErrorLinkUsersJobSite,
    isLoading: isLoadingLinkUsersJobSite
  } = useLinkUsersJobSite(jobSiteData?.id, {
    enabled: Boolean(jobSiteData?.id)
  })

  useEffect(() => {
    if (isErrorLinkUsersJobSite) {
      message.error('Ошибка привязки пользователей')
    } else if (isSuccessLinkUsersJobSite) {
      message.success('Пользователи успешно привязаны')
    }
  }, [isSuccessLinkUsersJobSite, isErrorLinkUsersJobSite])

  const {
    mutateAsync: saveUser,
    isError: isErrorSave,
    isLoading: isLoadingSave
  } = useMutateCreateUser()

  useEffect(() => {
    if (isErrorSave) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isErrorSave])

  const linkedList = useMemo(() => {
    const internalUsers = dataInternalUsers?.data?.filter(user =>
      users.find(extUser => extUser.external.email === user.email)
    )

    return users
      .map(user => {
        const internal = internalUsers?.find(
          internalUser =>
            internalUser._id === user.linkedId || internalUser.email === user.external.email
        ) || { name: '-' }
        return {
          ...user,
          internal,
          order:
            !user.linkedId && !internal?.email
              ? 0 //не связан и нет внутреннего пользователя
              : !user.linkedId &&
                internal.roles?.includes(appConfig.roles.recruiter) &&
                !internal.agency?._id
              ? 1 //не связан и есть внутренний свободный рекрутер
              : user.linkedId
              ? 2 //связан
              : internal.email &&
                (!internal.roles.includes(appConfig.roles.recruiter) || internal.agency?._id)
              ? 3 //внутренний пользователь не рекрутер либо привязан к агентству
              : 4 //все остальные которые могут быть
        }
      })
      .sort((a, b) => {
        if (a.order === b.order) {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        } else if (a.order > b.order) {
          return 1
        } else {
          return -1
        }
      })
  }, [dataInternalUsers?.data, users])

  const handleSelectUser = userId => {
    if (selectedRowKeys.includes(userId)) {
      setSelectedRowKeys(selectedRowKeys.filter(u => u !== userId))
      setSelectedAll(false)
    } else {
      setSelectedRowKeys([...selectedRowKeys, userId])
      if (linkedList.filter(record => !record.linkedId).length === selectedRowKeys.length + 1) {
        setSelectedAll(true)
      }
    }
  }
  const handleSelectedAll = ({ target }) => {
    setSelectedAll(target.checked)
    let rowKeys = []
    if (target.checked) {
      rowKeys = linkedList
        // выделяем только, если пользователи еще не залинкованы
        .filter(record => !record.linkedId)
        .reduce((prev, current) => [...prev, current.external.id], [])
    }
    setSelectedRowKeys(rowKeys)
  }

  const handleCancel = useCallback(() => {
    setSelectedRowKeys([])
    setSelectedAll(false)
  }, [])
  const columns = [
    {
      title: `${jobSiteData?.displayName} пользователи`,
      dataIndex: 'external',
      render: renderNameWithEmail
    },
    {
      title: 'Solvopro пользователи',
      dataIndex: 'internal',
      render: renderNameWithEmail
    },
    {
      title: (
        <Checkbox
          checked={selectedAll}
          className="checkbox-line-height-24"
          onChange={handleSelectedAll}
        >
          Выбрать все
        </Checkbox>
      ),
      dataIndex: 'linkedId',
      render: (linkedId, item) => (
        <>
          {!linkedId &&
            (!item.internal?.email ||
              (item.internal?.roles?.includes(appConfig.roles.recruiter) &&
                !item.internal?.agency?._id)) && (
              <Checkbox
                checked={selectedRowKeys.includes(item.external.id)}
                className="checkbox-line-height-24"
                onChange={() => handleSelectUser(item.external.id)}
              >
                {item.external?.email && item.internal?.email
                  ? 'Привязать'
                  : item.external?.email && !item.internal?.email
                  ? 'Создать пользователя'
                  : null}
              </Checkbox>
            )}
          {!linkedId &&
            item.internal?.email &&
            (!item.internal?.roles?.includes(appConfig.roles.recruiter) ||
              item.internal?.agency?._id) && (
              <Text type="secondary">У пользователя другая роль</Text>
            )}
          {linkedId && <Text type="secondary">Привязан</Text>}
        </>
      )
    }
  ]

  const usersWithoutPhone = useMemo(
    () =>
      linkedList.filter(
        user =>
          selectedRowKeys.includes(user.external.id) &&
          !(user.external.phone || user.internal.phone)
      ),
    [linkedList, selectedRowKeys]
  )
  const createAndLinkingUsers = useCallback(
    async list => {
      const newUsers = list.filter(user => !user.internal?._id)
      for (let i = 0; i < newUsers.length; i++) {
        await saveUser({
          ...newUsers[i].external,
          roles: [appConfig.roles.recruiter]
        }).then(({ data }) => {
          const user = list.find(user => user.external.id === newUsers[i].external.id)
          user.internal = data
          user.linkedId = data._id
        })
      }
      mutateLinkUsersJobSite({
        jobId: jobSiteData?.id,
        data: {
          users: list.map(user => ({
            externalId: user.external.id,
            linkedId: user.linkedId || user.internal._id
          }))
        }
      })
      setState({ modalType: null })
      handleCancel()
    },
    [mutateLinkUsersJobSite, saveUser, handleCancel, jobSiteData]
  )

  const handleSave = () => {
    if (usersWithoutPhone.length) {
      setState({ modalType: modalTypes.phoneNotice })
    } else {
      confirm({
        title: 'Привязка пользователей',
        content: `Вы собираетесь привязать ${selectedRowKeys?.length} ${numberDeclension(
          selectedRowKeys?.length,
          ['пользователя', 'пользователей', 'пользователей']
        )}`,
        okText: 'Подтвердить',
        cancelText: 'Отмена',
        onOk: async () => {
          const users = linkedList.filter(user => selectedRowKeys.includes(user.external.id))
          await createAndLinkingUsers(users)
        }
      })
    }
  }

  const handleSaveWithoutPhones = useCallback(async () => {
    const users = linkedList.filter(
      user => selectedRowKeys.includes(user.external.id) && user.external.phone
    )
    await createAndLinkingUsers(users)
  }, [createAndLinkingUsers, selectedRowKeys, linkedList])

  const handleCloseModal = useCallback(() => setState({ modalType: null }), [])
  const handleOkPhoneNotice = useCallback(() => setState({ modalType: modalTypes.phoneForm }), [])

  const handleOkPhoneForm = useCallback(
    async values => {
      console.log('phone values', values)
      const users = selectedRowKeys.map(id => {
        const user = linkedList.find(u => u.external.id === id)
        if (values[user.external.id]) {
          user.external.phone = formatPhoneNumberForApi(values[user.external.id])
        }
        return user
      })
      await createAndLinkingUsers(users)
    },
    [createAndLinkingUsers, linkedList, selectedRowKeys]
  )

  return (
    <Spin spinning={isLoadingInternalUsers}>
      <div className="content IntegrationsUsers">
        <Table
          size="middle"
          columns={columns}
          rowKey={item => item.external.id}
          dataSource={linkedList}
          pagination={false}
          scroll={{ x: '100%', y: '63vh' }}
        />
      </div>
      <Row justify="end" className="mt-3">
        <Col>
          <Button
            type="link"
            disabled={isLoadingSave || isLoadingLinkUsersJobSite}
            onClick={handleCancel}
          >
            Отмена
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={!selectedRowKeys.length}
            loading={isLoadingSave || isLoadingLinkUsersJobSite}
          >
            Сохранить
          </Button>
        </Col>
      </Row>
      <ModalPhoneNotice
        isVisible={state.modalType === modalTypes.phoneNotice}
        onClose={handleCloseModal}
        onOk={handleOkPhoneNotice}
        onSave={handleSaveWithoutPhones}
        list={usersWithoutPhone}
        loading={isLoadingSave || isLoadingLinkUsersJobSite}
      />
      <ModalPhoneForm
        isVisible={state.modalType === modalTypes.phoneForm}
        onOk={handleOkPhoneForm}
        onClose={handleCloseModal}
        list={usersWithoutPhone}
        loading={isLoadingSave || isLoadingLinkUsersJobSite}
      />
    </Spin>
  )
}
export default IntegrationsUsers
