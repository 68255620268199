import { useQuery } from 'react-query'
import { api } from './api'
import { defaultQueryOptions } from '../constants'

const FEATURES_KEY = 'features'

const fetchGetFeatures = () => {
  return api.get({ path: 'features' })
}

/**
 * By default features will be invariable so we keep they in cache without refetch
 */
export function useQueryFeatures(options = {}) {
  return useQuery([FEATURES_KEY], fetchGetFeatures, { ...defaultQueryOptions, ...options })
}
