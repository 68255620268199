import { useQuery } from 'react-query'
import { api } from './api'
import { defaultQueryOptions } from '../constants'

const VACANCY_SYS_KEY = 'systemvacancies'
export const VACANCIES_KEY = 'vacancies'

const getSystemVacancies = () => {
  return api.get({ path: 'systemvacancies' })
}

const getVacancies = () => {
  return api.get({ path: 'vacancies' })
}

export function useGetSystemVacancies(options = defaultQueryOptions) {
  return useQuery([VACANCY_SYS_KEY], getSystemVacancies, options)
}

export function useGetVacancies(options) {
  return useQuery([VACANCIES_KEY], getVacancies, options)
}
