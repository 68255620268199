import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Select, Row, Checkbox, Typography } from 'antd'
import moment from 'moment'
import { upperFirst, arrayFirst, arrayLast } from '../../../../../helpers'
import { appConfig } from '../../../../../constants/appConfig'

const { Title } = Typography
const { Option } = Select

function WorkTimeDayItem({ dayWeekIndex, initNotWork, intervalStart, intervalEnd }) {
  const [notWork, setNotWork] = useState(initNotWork)
  const dayName = moment()
    .day(dayWeekIndex + 1)
    .format('dddd')

  const timeList = useMemo(() => {
    const start = moment(intervalStart, appConfig.formats.shortTime).format('H')
    const end = moment(intervalEnd, appConfig.formats.shortTime).format('H')
    const list = []
    for (let i = parseInt(start); i <= parseInt(end); i++) {
      list.push(moment().hour(i).minute(0).format(appConfig.formats.shortTime))
    }
    return list
  }, [intervalStart, intervalEnd])

  const handleNotWork = value => {
    setNotWork(value.target.checked)
  }

  const rule = { required: true, message: 'Выберите время' }

  return (
    <div className="weekday">
      <Title level={5} className="weekdayLabel">
        {upperFirst(dayName)}
      </Title>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name={[dayWeekIndex, 'start']}
            label="Начало"
            rules={[rule]}
            initialValue={arrayFirst(timeList)}
          >
            <Select disabled={notWork}>
              {timeList.map(t => (
                <Option key={t} value={t}>
                  {t}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[dayWeekIndex, 'end']}
            label="Конец"
            rules={[rule]}
            initialValue={arrayLast(timeList)}
          >
            <Select disabled={notWork}>
              {timeList.map(t => (
                <Option key={t} value={t}>
                  {t}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={[dayWeekIndex, 'notWork']} valuePropName="checked">
        <Checkbox onChange={handleNotWork}>Нерабочий день</Checkbox>
      </Form.Item>
    </div>
  )
}

WorkTimeDayItem.defaultProps = {
  initNotWork: false
}

WorkTimeDayItem.propTypes = {
  dayWeekIndex: PropTypes.number.isRequired,
  initNotWork: PropTypes.bool,
  intervalStart: PropTypes.string,
  intervalEnd: PropTypes.string
}

export default WorkTimeDayItem
