import moment from 'moment'
import { appConfig } from '../constants/appConfig'
import { convertArrayToObject } from './index'

export function getPeriods({ previousCount = 3, forceGetNext = false } = {}) {
  const now = moment()
  const periods = [now]
  for (let i = 0; i < previousCount; i++) {
    periods.push(periods[i].clone().subtract(1, 'month'))
  }
  if (now.date() >= appConfig.nextPlanningAvailableDate || forceGetNext) {
    periods.unshift(now.clone().add(1, 'month'))
  }
  return periods.map(period => ({
    id: period.format('Y/M'),
    name: period.format('MMMM YYYY'),
    isCurrent: period === now,
    isPast: period.isBefore(now, 'month')
  }))
}

export function getPeriodByPlan(plan) {
  return getPeriods().find(period => period.id === `${plan?.year}/${plan?.month}`)
}

export function getPreviousPeriod(periodId) {
  const index = getPeriods()?.findIndex(period => period.id === periodId)
  if (index >= 0) {
    return getPeriods()[index + 1] || null
  }
  return null
}

export function isApproved(plan) {
  return plan?.state === appConfig.planStatuses.approved
}

export function isRejected(plan) {
  return plan?.state === appConfig.planStatuses.rejected
}

export function getApprovedDate(history) {
  return (
    [...history]?.reverse()?.find(item => item.name === appConfig.planStatuses.approved)
      ?.createdAt || null
  )
}

export function getVacanciesCount(vacancy, name) {
  return vacancy?.find(item => item.name === name)?.count || 0
}

export function getCrewmenCount(crewmen, name, type) {
  return crewmen?.find(item => item.name === name)?.[type] || 0
}

export function getTotalCrewmenCount(crewmen) {
  return crewmen?.reduce((prev, current) => {
    return prev + (current.fullTime || 0) + (current.partTime || 0)
  }, 0)
}

export function existsVacanciesTime(crewmen, name, time, title) {
  return crewmen?.find(item => item.name === name)?.time?.[time] ? title : ''
}

export function convertCrewmenToObject(data) {
  const newData = {
    ...data,
    crewmen: convertArrayToObject(data.crewmen?.length ? data.crewmen : [], 'name')
  }
  Object.keys(newData.crewmen).forEach(item => {
    if (Array.isArray(newData.crewmen[item].citizenship)) {
      newData.crewmen[item].citizenship = {
        cis: newData.crewmen[item].citizenship.includes('cis')
      }
    }
  })
  return newData
}
