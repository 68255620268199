import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'

const SETTINGS_KEY = 'settings'

const getSettings = () => {
  return api.get({ path: 'settings' })
}

const postSettingsByCode = ({ code, value }) => {
  return api.post({ path: 'settings/' + code, data: { value } })
}

const deleteSettingsByCode = ({ code }) => {
  return api.del({ path: 'settings/' + code })
}

export function useGetSettings(options) {
  return useQuery([SETTINGS_KEY], getSettings, options)
}

export function useMutateSettingsByCode() {
  return useMutation(postSettingsByCode, {
    onSuccess: () => {
      queryClient.refetchQueries(SETTINGS_KEY)
    }
  })
}

export function useMutateDeleteSettingsByCode() {
  return useMutation(deleteSettingsByCode, {
    onSuccess: () => {
      queryClient.refetchQueries(SETTINGS_KEY)
    }
  })
}
