import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const { Paragraph, Link } = Typography

function EditingParagraph({
  index,
  name,
  type,
  onEdit,
  onDelete,
  isEditing,
  wrapperClassName,
  isDeleting,
  maxLength,
  displayName
}) {
  const [editing, setEditing] = useState(isEditing)
  const handleChange = value => {
    setEditing(false)
    onEdit({ type, index, value })
  }

  const handleDelete = () => {
    onDelete(type, index)
  }

  const handleStart = () => {
    setEditing(true)
  }

  return (
    <div className={wrapperClassName}>
      <Paragraph
        editable={{
          tooltip: 'Редактировать',
          onChange: handleChange,
          editing,
          maxLength,
          onStart: handleStart
        }}
      >
        {editing ? name : displayName || name}
      </Paragraph>
      {isDeleting && (
        <Link title="Удалить" onClick={handleDelete} className="close">
          <CloseCircleOutlined />
        </Link>
      )}
    </div>
  )
}

EditingParagraph.defaultProps = {
  isEditing: false,
  maxLength: 60,
  isDeleting: false,
  displayName: '',
  name: ''
}

EditingParagraph.propTypes = {
  name: PropTypes.string,
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  type: PropTypes.string,
  wrapperClassName: PropTypes.string,
  index: PropTypes.number,
  maxLength: PropTypes.number,
  isEditing: PropTypes.bool,
  isDeleting: PropTypes.bool
}

export default EditingParagraph
