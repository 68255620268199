import React from 'react'
// import PropTypes from 'prop-types'
import { candidateShape } from '../../../../constants/propTypesShapes'
import { defaultTitle } from '../../../../constants'

function CurrentSituation({ candidate }) {
  return (
    <>
      <div className="item">Кем работает: {candidate.currentPosition || defaultTitle}</div>
      <div className="item">Где работает: {candidate.currentOrganization || defaultTitle}</div>
    </>
  )
}

CurrentSituation.propTypes = {
  candidate: candidateShape.isRequired
}

export default CurrentSituation
