import React from 'react'
import { Button, Checkbox, Col, Divider, Input, Row, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
const { Text, Title } = Typography
const { Search } = Input

function UserRelationList({
  onSelectAll,
  onSave,
  onCancel,
  isLoading = false,
  title = '',
  enabledUserEdit = false,
  onEditOrgUnitUser = () => {},
  lastSelectedUnitChildrenIds,
  onChangeSearch = () => {},
  onSelectUser = () => {},
  enabledSelectRelatedUsers = false,
  onSelectRelatedUser = () => {},
  enabledSettingUser = false,
  onSettingUser = () => {},
  departments,
  otherUsers,
  relatedUsers,
  selectedUsers,
  selectedRelatedUsers,
  okText = 'Сохранить',
  cancelText = 'Отмена'
}) {
  const { departmentType } = useConfigContextData()

  const handleSelectAll = () => onSelectAll?.()
  const handleCancel = () => onCancel?.()
  const handleSave = () => onSave?.()

  return (
    <>
      <Row justify="space-between" align="large">
        <div className="block-title filters">{title}</div>
        <Col>
          {selectedUsers?.length > 0 || selectedRelatedUsers?.length !== relatedUsers?.length ? (
            <>
              <Button type="link" size="medium" onClick={handleCancel} disabled={isLoading}>
                {cancelText}
              </Button>
              <Button type="primary" size="medium" onClick={handleSave} loading={isLoading}>
                {okText}
              </Button>
            </>
          ) : (
            <Button type="link" size="medium" onClick={handleSave}>
              Закрыть
            </Button>
          )}
        </Col>
      </Row>
      <Divider className="small" />
      <Row>
        <Search
          size="large"
          placeholder="Поиск пользователя"
          onChange={onChangeSearch}
          allowClear
        />
      </Row>
      <Title className="mt-3 title" level={4}>
        Пользователи
      </Title>
      {relatedUsers?.length > 0 ? (
        relatedUsers.map(user => (
          <Row key={user._id} justify="space-between" className="user" align="middle">
            <Col {...(enabledSelectRelatedUsers && { span: 15 })}>
              <Row align="middle" onClick={() => onSelectRelatedUser(user)}>
                {enabledSelectRelatedUsers && (
                  <Col>
                    <Checkbox
                      className="mr-3"
                      checked={selectedRelatedUsers?.find(e => e._id === user._id)}
                    />
                  </Col>
                )}
                <Col>
                  <Row>
                    <Text>{user.name}</Text>
                  </Row>
                  <Row>
                    <Text type="secondary">{user.email}</Text>
                  </Row>
                </Col>
              </Row>
            </Col>
            {enabledUserEdit && (
              <Col onClick={() => onEditOrgUnitUser(user)} className="stats">
                {
                  user.scope?.departmentReport?.filter(departmentId =>
                    lastSelectedUnitChildrenIds?.includes(departmentId)
                  )?.length
                }{' '}
                из {departments?.length} {departmentType?.toLowerCase()}
                <RightOutlined />
              </Col>
            )}
          </Row>
        ))
      ) : (
        <Text type="secondary">Не назначено</Text>
      )}
      <Title className="mt-3 title" level={4}>
        Добавить пользователя
      </Title>
      {otherUsers?.length > 0 && (
        <Row align="middle" onClick={handleSelectAll} className="select-all">
          <Col>
            <Checkbox className="mr-3" checked={selectedUsers.length === otherUsers?.length} />
          </Col>
          <Col>Выбрать всех</Col>
        </Row>
      )}
      {otherUsers?.length > 0 ? (
        otherUsers.map(user => (
          <Row key={user._id} justify="space-between" className="user" align="middle">
            <Col onClick={() => onSelectUser(user)}>
              <Row align="middle">
                <Col>
                  <Checkbox
                    className="mr-3"
                    checked={selectedUsers.find(e => e._id === user._id)}
                  />
                </Col>
                <Col>
                  <Row>
                    <Text>{user.name}</Text>
                  </Row>
                  <Row>
                    <Text type="secondary">{user.email}</Text>
                  </Row>
                </Col>
              </Row>
            </Col>
            {enabledSettingUser && selectedUsers.find(e => e._id === user._id) && (
              <Col onClick={() => onSettingUser(user)} className="stats">
                {selectedUsers.find(e => e._id === user._id)?.scope?.newDepartments?.length} из{' '}
                {departments?.length} {departmentType?.toLowerCase()}
                <RightOutlined />
              </Col>
            )}
          </Row>
        ))
      ) : (
        <Text type="secondary">Нет свободных пользователей</Text>
      )}
    </>
  )
}

export default UserRelationList
