import React, { useEffect, useMemo, useState } from 'react'
// import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import './PlanFactBlock.less'
import { /** @TODO Временно скрыть TEL-346 Button, */ Col, message, Row } from 'antd'
import { appConfig } from '../../../../constants/appConfig'
import { useGetPlan } from '../../../../api/plan'
import { PLANNING_PERIOD_PAGE } from '../../../../constants/routes'
import { periodShape } from '../../../../constants/propTypesShapes'
import { useMutateContinuePlan, useMutateStopPlan } from '../../../../api/flow/plan'
import { getTotalCrewmenCount } from '../../../../helpers/plan'

const PlanFactBlock = ({ period }) => {
  const [plan, setPlan] = useState({})
  const { data, isSuccess } = useGetPlan(period.id, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!period.id
  })
  const [year, month] = period.id?.split('/')

  const {
    /** @TODO Временно скрыть TEL-346 mutate: mutateStop, isLoading: isLoadingStop, */
    isError: isErrorStop
  } = useMutateStopPlan()
  const {
    /** @TODO Временно скрыть TEL-346 mutate: mutateContinue, isLoading: isLoadingContinue, */
    isError: isErrorContinue
  } = useMutateContinuePlan()

  /** @TODO Временно скрыть TEL-346
  const stopPlan = async () => {
    await mutateStop({ planId: plan._id, year: plan.year, month: plan.month })
  }
  const continuePlan = async () => {
    await mutateContinue({ planId: plan._id, year: plan.year, month: plan.month })
  }
  */

  useEffect(() => {
    if (isSuccess && data?.data) {
      setPlan(data.data)
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (isErrorStop || isErrorContinue) {
      message.error('Ошибка изменения статуса подбора персонала')
    }
  }, [isErrorStop, isErrorContinue])

  const workPlanned = useMemo(() => {
    // Текущий план по набору Кассиров и Поваров для полной и частичной загрузки
    return getTotalCrewmenCount(plan?.current?.crewmen)
  }, [plan])

  return (
    <Col span={6}>
      <div
        className={`plan-fact-block status-${
          plan.state?.name?.toLowerCase() || appConfig.statuses.values.rookie.toLowerCase()
        }`}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <div className="block-title">{period.name}</div>
            <div className="text-sm">
              {appConfig.planFactStatuses[plan.state?.name] || 'Ещё не создано'}
            </div>
          </Col>
          {period.isCurrent && Object.keys(plan).length > 0 && (
            <Col>
              {/** @TODO Временно скрыть TEL-346
              plan.stopSelection
                ? <Button type='primary' size="small" className="green text-sm" loading={isLoadingContinue}
                          onClick={() => continuePlan()}>Продолжить подбор</Button>
                : <Button type='danger' size="small" className="text-sm" loading={isLoadingStop}
                          onClick={() => stopPlan()}>Остановить подбор</Button>
            */}
            </Col>
          )}
        </Row>
        <Row className="content" justify="space-between">
          <Col>{workPlanned || 0}</Col>
          <Col>/</Col>
          <Col>{plan.workStarted || 0}</Col>
        </Row>
        <Row className="hints" justify="space-between">
          <Col>План по набору</Col>
          <Col>Факт по набору</Col>
        </Row>
        {period.isCurrent && Object.keys(plan).length > 0 && (
          <Link to={generatePath(PLANNING_PERIOD_PAGE, { year, month })} className="text-sm">
            Перепланировать
          </Link>
        )}
      </div>
    </Col>
  )
}

PlanFactBlock.propTypes = {
  period: periodShape.isRequired
}

export default PlanFactBlock
