import React, { useContext } from 'react'
import { Col, Row, Typography, Tag } from 'antd'
import moment from 'moment'

import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { appConfig } from '../../../../constants/appConfig'

const { Title, Text } = Typography

function History() {
  const { candidate } = useContext(CandidatePanelContext)

  const getDepartmentText = item => {
    if (item.name !== appConfig.statuses.values.interviewScheduled) return ''

    const { interview } = item
    const start = moment(interview.start)
      ?.clone()
      .utcOffset(interview?.timeOffset || 0)
      .format(appConfig.formats.shortDateAndTime)
    const end = moment(interview.end)
      ?.clone()
      .utcOffset(interview?.timeOffset || 0)
      .format(appConfig.formats.shortTime)
    return (
      interview?.departmentName && (
        <>
          {` в `}
          <b>{interview.departmentName} </b>
          <span>
            {start} - {end}
          </span>
        </>
      )
    )
  }
  const renderItem = item => (
    <Row key={item._id}>
      <Col span={5}>
        <Tag>{moment(item.createdAt).format(appConfig.formats.shortDate)}</Tag>
        <Tag>{moment(item.createdAt).format(appConfig.formats.shortTime)}</Tag>
      </Col>
      <Col span={19}>
        <Text>
          {appConfig.statuses.candidate.find(s => s.key === item.name)?.label || item.name}
          {getDepartmentText(item)}
          {item.rejectReason && `. Причина - ${item.rejectReason}`}
          {item.rejectReason && item.comment && `. Комментарий: "${item.comment}"`}
        </Text>
      </Col>
    </Row>
  )

  return (
    <div className="history">
      <Row className="mb-1">
        <Col span={5}>
          <Title level={5}>Дата</Title>
        </Col>
        <Col>
          <Title level={5}>Статус</Title>
        </Col>
      </Row>
      {candidate?.history?.map(renderItem)}
    </div>
  )
}

export default History
