import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Drawer, message } from 'antd'
import { CandidatePanel } from '../index'
import { useMutateUpdateCandidate } from '../../../../api/candidate'

function CandidateDrawer({
  onClose,
  isVisible,
  candidateId,
  candidateInfo,
  drawerClass,
  onlyView
}) {
  /**
   * Mutate Candidate and update queryById if update isSuccess
   */
  const { mutate, status: statusEdit } = useMutateUpdateCandidate()

  useEffect(() => {
    if (statusEdit === 'success') {
      message.success('Кандидат успешно обновлен!')
    }
    if (statusEdit === 'error') {
      message.error('Ошибка обновления данных кандидата')
    }
  }, [statusEdit])

  const handleEditPreferences = useCallback(
    e => mutate({ id: candidateId, data: e }),
    [candidateId, mutate]
  )

  const candidateInfoData = useMemo(
    () => ({
      ...candidateInfo,
      onEdit: candidateInfo.isEditPreferences ? handleEditPreferences : null
    }),
    [candidateInfo, handleEditPreferences]
  )
  return (
    <Drawer
      width={888}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={isVisible}
      className={drawerClass}
    >
      {candidateId && (
        <CandidatePanel
          candidateId={candidateId}
          onlyView={onlyView}
          candidateInfo={candidateInfoData}
        />
      )}
    </Drawer>
  )
}
export default CandidateDrawer

CandidateDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  candidateId: PropTypes.string,
  drawerClass: PropTypes.string,
  candidateInfo: PropTypes.shape({
    headerInfo: PropTypes.string,
    wrapperInfoClass: PropTypes.string,
    wrapperInfoSecondaryClass: PropTypes.string,
    isEditPreferences: PropTypes.bool
  }),
  onlyView: PropTypes.bool
}
CandidateDrawer.defaultProps = {
  isVisible: false,
  candidateInfo: {},
  candidateId: null,
  drawerClass: '',
  onlyView: false
}
