import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, Input, DatePicker } from 'antd'
import { appConfig } from '../../../constants/appConfig'
import { getAge } from '../../../helpers/time'
import { colSettings } from '../../../constants'

const BirthAndAgeItem = ({ form, birthdayColSpan, ageColSpan, onBirthdayChange }) => {
  const handleBirthdayChange = birthday => {
    if (birthday) {
      form.setFieldsValue({
        age: getAge(birthday)
      })
      onBirthdayChange(birthday)
    } else {
      form.setFieldsValue({
        age: null
      })
    }
  }

  return (
    <Row>
      <Col span={birthdayColSpan}>
        <Form.Item
          label="Дата рождения"
          labelCol={colSettings.full}
          wrapperCol={{ span: 22 }}
          name="birthday"
          rules={[
            {
              required: true,
              message: 'Укажите дату рождения'
            }
          ]}
        >
          <DatePicker
            size="middle"
            placeholder="дд.мм.гггг"
            format={appConfig.formats.shortDate}
            onChange={handleBirthdayChange}
            getPopupContainer={trigger => trigger.parentNode}
          />
        </Form.Item>
      </Col>
      <Col span={ageColSpan}>
        <Form.Item
          label="Возраст"
          name="age"
          labelCol={colSettings.full}
          wrapperCol={colSettings.full}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default BirthAndAgeItem

BirthAndAgeItem.propTypes = {
  form: PropTypes.shape().isRequired,
  birthdayColSpan: PropTypes.number,
  ageColSpan: PropTypes.number,
  onBirthdayChange: PropTypes.func
}

BirthAndAgeItem.defaultProps = {
  birthdayColSpan: 16,
  ageColSpan: 8,
  onBirthdayChange: () => {}
}
