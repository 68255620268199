import React from 'react'
import PropTypes from 'prop-types'
import { Form, InputNumber, Table, Checkbox } from 'antd'
import { appConfig } from '../../../../constants/appConfig'
import { crewmanShape } from '../../../../constants/propTypesShapes'
import { useSystemVacancies } from '../../../../hooks/useSystemVacancies'
import { upperFirst } from '../../../../helpers'

const codes = appConfig.needsVacancies

const defaultSize = {
  name: 'auto',
  partTime: 70,
  fullTime: 70,
  forAdultsOnly: 100,
  morning: 100,
  noon: 100,
  evening: 100,
  night: 100,
  weekend: 100,
  cis: 100,
  male: 50,
  female: 50
}
function RequestNeedTable({ readOnly = false, data, size = 'middle', columnsSize = defaultSize }) {
  const { data: sysVacanciesData, isLoading: isLoadingSysVacancies } = useSystemVacancies()

  const getRenderInput = key => {
    const cb = (text, el) => {
      return (
        <Form.Item name={['crewmen', el.name, key]}>
          {readOnly ? (
            <div>{text}</div>
          ) : (
            <InputNumber min={0} readOnly={readOnly} defaultValue={0} />
          )}
        </Form.Item>
      )
    }
    return cb
  }

  const getRenderCheckbox = arrayKeys => {
    const cb = (text, el) => (
      <Form.Item name={['crewmen', el.name].concat(arrayKeys)} valuePropName="checked">
        <Checkbox disabled={readOnly || disableCheckbox(arrayKeys[arrayKeys.length - 1], el)} />
      </Form.Item>
    )
    return cb
  }

  const disableCheckbox = (key, el) => {
    switch (el.name) {
      case codes.dayCleaner:
        return key === 'night'
      case codes.nightCleaner:
        return ['morning', 'day', 'noon', 'evening'].includes(key)
      default:
        return false
    }
  }

  const columns = [
    {
      title: 'Должность',
      dataIndex: 'name',
      width: columnsSize.name,
      render: text =>
        upperFirst(sysVacanciesData?.find(vacancy => vacancy.name === text)?.displayName)
    },
    {
      title: 'ПТ',
      dataIndex: 'partTime',
      align: 'center',
      width: columnsSize.partTime,
      render: getRenderInput('partTime')
    },
    {
      title: 'ФТ',
      dataIndex: 'fullTime',
      align: 'center',
      width: columnsSize.fullTime,
      render: getRenderInput('fullTime')
    },
    {
      title: 'До 18 лет',
      dataIndex: 'forAdultsOnly',
      width: columnsSize.forAdultsOnly,
      align: 'center',
      render: getRenderCheckbox(['forAdultsOnly'])
    },
    {
      title: 'График работы',
      children: [
        {
          title: 'Утро',
          dataIndex: ['time', 'morning'],
          align: 'center',
          width: columnsSize.morning,
          render: getRenderCheckbox(['time', 'morning'])
        },
        {
          title: 'День',
          dataIndex: ['time', 'noon'],
          align: 'center',
          width: columnsSize.noon,
          render: getRenderCheckbox(['time', 'noon'])
        },
        {
          title: 'Вечер',
          dataIndex: ['time', 'evening'],
          align: 'center',
          width: columnsSize.evening,
          render: getRenderCheckbox(['time', 'evening'])
        },
        {
          title: 'Ночь',
          dataIndex: ['time', 'night'],
          align: 'center',
          width: columnsSize.night,
          render: getRenderCheckbox(['time', 'night'])
        },
        {
          title: (
            <div className="text-ellipsis" style={{ width: `${columnsSize.weekend}px` }}>
              Выходные
            </div>
          ),
          dataIndex: ['time', 'weekend'],
          align: 'center',
          width: columnsSize.weekend,
          render: getRenderCheckbox(['time', 'weekend'])
        }
      ]
    },
    {
      title: (
        <div className="text-ellipsis" style={{ width: `${columnsSize.cis}px` }}>
          Гражданство
        </div>
      ),
      children: [
        {
          title: 'СНГ',
          dataIndex: ['citizenship', 'cis'],
          align: 'center',
          width: columnsSize.cis,
          render: getRenderCheckbox(['citizenship', 'cis'])
        }
      ]
    },
    {
      title: 'Пол',
      children: [
        {
          title: 'М',
          dataIndex: ['gender', 'male'],
          align: 'center',
          width: columnsSize.male,
          render: getRenderCheckbox(['gender', 'male'])
        },
        {
          title: 'Ж',
          dataIndex: ['gender', 'female'],
          align: 'center',
          width: columnsSize.female,
          render: getRenderCheckbox(['gender', 'female'])
        }
      ]
    }
  ]

  return (
    <Table
      rowKey="name"
      loading={isLoadingSysVacancies}
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
      size={size}
    />
  )
}

export default RequestNeedTable

RequestNeedTable.propTypes = {
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  data: PropTypes.arrayOf(crewmanShape)
}
