import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { candidateShape } from '../../../../constants/propTypesShapes'
import { UserContext } from '../../../../contexts/userContext'

const CandidateItem = ({
  interview,
  interviewerDepartment,
  candidate,
  showProfile,
  status,
  dueDate,
  department,
  isSearch // компонент используется на странице поиска
}) => {
  // const avaStyle = props.candidate?.profile?.photoUrl
  //   ? ({ background: `url("${props.candidate.profile.photoUrl}")` })
  //   : {}

  const { user, isHiringManager } = useContext(UserContext)
  const isDelegatingInterview =
    interviewerDepartment?._id && department?._id && interviewerDepartment?._id !== department?._id
  const isEmployerDepartment =
    isHiringManager &&
    interviewerDepartment?._id &&
    interviewerDepartment?._id !== user.department?._id

  return (
    <div key={candidate._id} className="candidates-item" onClick={() => showProfile(candidate)}>
      {/* <div className='ava' style={avaStyle}/> */}
      <div className="info">
        <div className="name">{candidate.name}</div>
        <div className="status">{status}</div>
        {dueDate?.msg && dueDate?.style && (
          <div className={`due_date ${dueDate.style}`}>{dueDate.msg}</div>
        )}
        {interview?._doc?.attendees?.length && (
          <div>
            Участники:{' '}
            {interview?._doc?.attendees
              ?.reduce((prev, current) => [...prev, current?.name], [])
              .join(', ')}
          </div>
        )}
        {isDelegatingInterview &&
          !isSearch &&
          (isEmployerDepartment ? (
            <div className="status">
              Кандидат будет собеседоваться в: <b>{interviewerDepartment?.name}</b>
            </div>
          ) : (
            <div className="status">
              Кандидат будет работать в: <b>{department?.name}</b>
            </div>
          ))}
      </div>
    </div>
  )
}

CandidateItem.propTypes = {
  candidate: candidateShape.isRequired,
  dueDate: PropTypes.shape({
    msg: PropTypes.string,
    style: PropTypes.string
  }),
  showProfile: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  interview: PropTypes.shape({
    _doc: PropTypes.shape({
      attendees: PropTypes.array
    })
  }),
  interviewerDepartment: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  }),
  isSearch: PropTypes.bool
}
CandidateItem.defaultProps = {
  dueDate: null,
  isSearch: false
}

export default CandidateItem
