import { Typography, Button, Drawer, message } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import orgUserImg from '../../../../../img/orgStructure/user.svg'
const { Text } = Typography
import { useMutateUser, useUsers } from '../../../../../api/users'
import { arrayEquals, debounce } from '../../../../../helpers'
import { appConfig } from '../../../../../constants/appConfig'
import HorizontalSlideTwoBlocks from '../../Animate/HorizontalSlideTwoBlocks'
import { useDepartmentsQuery } from '../../../../../api/department'
import EditOrgUser from './EditOrgUser'
import UserRelationList from '../../User/UserRelationList'
import { addDepartmentsToBranch } from '../../../../../helpers/orgStructure'

function OrgUnitUsers({ selectedUnits, lastSelected }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [departments, setDepartments] = useState([])
  const [search, setSearch] = useState('')

  //мэнеджеры кого можно назначиь на оргЕдиницу
  const [managers, setManagers] = useState([])
  //мэнеджеры которые уже назначены на оргЕдиницу
  const [orgUnitManagers, setOrgUnitManagers] = useState([])
  //менеджеры выбранные для добавления чекбоксами
  const [selectedUsers, setSelectedUsers] = useState([])
  // менеджер который выбран чекбоксом или изи списка эже назначенных которому настраивают скоуп
  const [settingUser, setSettingUser] = useState('')
  // настройка скоупа одному из выбраных менеджеров
  const [showSecondaryBlock, setShowSecondaryBlock] = useState(false)
  // дерево орг структуры с встроенными внутрь департаментами для возможности выбрать
  const [orgUnitWithDepartments, setOrgUnitWithDepartments] = useState({})
  const [filteredOrgUnitWithDepartments, setFilteredOrgUnitWithDepartments] = useState(null)

  const {
    mutate: mutateUser,
    isSuccess: isSuccessUpdateUser,
    isError: isErrorUpdateUser,
    isLoading: isLoadingUpdateUser
  } = useMutateUser()

  useEffect(() => {
    if (isSuccessUpdateUser) {
      message.success('Пользователь успешно обновлен')
    } else if (isErrorUpdateUser) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isSuccessUpdateUser, isErrorUpdateUser])

  const {
    data: dataManagers,
    isSuccess: isSuccessManagers,
    isError: isErrorManagers
  } = useUsers(
    {
      filters: { role: appConfig.roles.manager, text: search } // manager
    },
    {
      enabled: isDrawerOpen
    }
  )
  useEffect(() => {
    if (isSuccessManagers) {
      const list = dataManagers?.data
        .map(manager => {
          if (orgUnitManagers.find(user => user._id === manager._id)) {
            return null
          }
          return manager
        })
        .filter(Boolean)
      setManagers(list)
    } else if (isErrorManagers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [dataManagers, isSuccessManagers, isErrorManagers, orgUnitManagers])

  const {
    data: dataOrgUnitManagers,
    isSuccess: isSuccessOrgUnitManagers,
    isError: isErrorOrgUnitManagers
  } = useUsers(
    {
      filters: { orgUnit: lastSelected?._id, text: search }
    },
    {
      enabled: isDrawerOpen
    }
  )
  useEffect(() => {
    if (isSuccessOrgUnitManagers) {
      setOrgUnitManagers(dataOrgUnitManagers?.data)
    } else if (isErrorOrgUnitManagers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [dataOrgUnitManagers, isSuccessOrgUnitManagers, isErrorOrgUnitManagers])

  const {
    data: departmentsData,
    isSuccess: isSuccessDepartments,
    isError: isErrorDepartments
  } = useDepartmentsQuery(
    { parentOrganizationUnits: [lastSelected?._id] },
    { enabled: isDrawerOpen }
  )

  useEffect(() => {
    if (isSuccessDepartments && departmentsData?.data) {
      setDepartments(departmentsData?.data)
      const departmentsList = [...departmentsData?.data]
      const unit = JSON.parse(JSON.stringify(lastSelected))
      addDepartmentsToBranch(unit, departmentsList)
      setOrgUnitWithDepartments(unit)
    }
    if (isErrorDepartments) message.error('Ошибка загрузки орг. единиц')
  }, [departmentsData, isSuccessDepartments, isErrorDepartments, lastSelected])

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedUsers([])
    }
  }, [isDrawerOpen])

  const handleClose = useCallback(() => {
    if (!showSecondaryBlock) {
      setIsDrawerOpen(false)
    } else {
      const newDepartments = departments.map(item => item._id)

      setSettingUser('')
      setShowSecondaryBlock(false)
      // при отмене новым юзерам перетираем выбранные новые скоупы
      if (selectedUsers.find(user => user._id === settingUser._id)) {
        setSelectedUsers([
          ...selectedUsers.filter(user => user._id !== settingUser._id),
          {
            ...settingUser,
            scope: {
              ...settingUser.scope,
              newDepartments
            }
          }
        ])
      }
    }
  }, [showSecondaryBlock, selectedUsers, departments, settingUser])

  const saveUser = useCallback(
    user => {
      mutateUser({
        id: user._id,
        data: {
          ...user,
          scope: {
            ...user.scope,
            departmentReport: [
              ...(user.scope.departmentsFromOtherUnits || []),
              ...user.scope.newDepartments
            ]
          }
        }
      })
    },
    [mutateUser]
  )
  const handleSave = useCallback(() => {
    if (showSecondaryBlock) {
      // если редактируем ранее назначенного юзера то сразу сохраняем
      if (!selectedUsers.find(user => user._id === settingUser._id)) {
        saveUser(settingUser)
      }
      setShowSecondaryBlock(false)
      setSettingUser('')
    } else {
      selectedUsers.forEach(saveUser)
      setSelectedUsers([])
    }
  }, [settingUser, saveUser, selectedUsers, showSecondaryBlock])

  const handleSubmit = useCallback(search => setSearch(search), [])

  const debounceSearch = useMemo(() => debounce(handleSubmit), [handleSubmit])

  const handleChangeSearch = useCallback(
    ({ target }) => debounceSearch(target.value),
    [debounceSearch]
  )

  const handleChangeAddress = useCallback(
    ({ target }) => {
      const departmentsList = departments.filter(item => item.name.includes(target.value))
      if (!target.value) {
        return setFilteredOrgUnitWithDepartments(null)
      }
      if (!departmentsList.length) {
        return setFilteredOrgUnitWithDepartments({})
      }

      const unit = JSON.parse(JSON.stringify(lastSelected))
      const addDepartments = (parent, child) => {
        // parentOrganizationUnits - вся цепочка орг единиц(ищется по всем родителям)
        // а сам департамент принадлежит к последней орг единице
        const departments = departmentsList.filter(
          e => e?.parentOrganizationUnits?.[e?.parentOrganizationUnits?.length - 1] === child._id
        )
        // если орг единицы нет ни в одном из департаментов то отфильтровываем
        if (!departmentsList.find(e => e?.parentOrganizationUnits?.includes(child._id))) {
          parent.children = parent.children.filter(item => item._id !== child._id)
          return
        }
        // обогащаем орг структуру департаментами
        if (departments?.length) {
          child.children = child.children.concat(
            departments.map(department => ({
              ...department,
              title: department.name,
              isDepartment: true
            }))
          )
        }
        child.children
          .filter(item => !item.isDepartment)
          .forEach(item => addDepartments(child, item))
      }
      addDepartments(null, unit)
      setFilteredOrgUnitWithDepartments(unit)
    },
    [departments, lastSelected]
  )

  const lastSelectedUnitChildrenIds = useMemo(() => {
    if (!selectedUnits?.length) return null
    const ids = []
    const countChildren = list => {
      if (list.children?.length) {
        list.children.forEach(child => {
          ids.push(child._id)
          countChildren(child)
        })
      }
    }
    countChildren(orgUnitWithDepartments)
    return ids
  }, [orgUnitWithDepartments, selectedUnits])

  const handleSelectUser = useCallback(
    user => {
      if (selectedUsers.find(e => e._id === user._id)) {
        setSelectedUsers(selectedUsers.filter(e => e._id !== user._id))
      } else {
        const newDepartments = departments.map(item => item._id)
        setSelectedUsers([
          ...selectedUsers,
          {
            ...user,
            scope: {
              ...user.scope,
              newDepartments,
              departmentsFromOtherUnits: user.scope?.departmentReport?.filter(
                item => !newDepartments.includes(item._id) || []
              )
            }
          }
        ])
      }
    },
    [selectedUsers, departments]
  )
  const handleSelectAll = useCallback(() => {
    if (selectedUsers.length === managers?.length) {
      setSelectedUsers([])
    } else {
      const newDepartments = departments.map(item => item._id)
      const selectedUsersIds = selectedUsers.map(selectedUser => selectedUser._id)
      setSelectedUsers([
        ...selectedUsers,
        ...managers
          .filter(user => !selectedUsersIds.includes(user._id))
          .map(user => ({
            ...user,
            scope: {
              ...user.scope,
              newDepartments,
              departmentsFromOtherUnits: user.scope?.departmentReport?.filter(
                item => !newDepartments.includes(item._id) || []
              )
            }
          }))
      ])
    }
  }, [departments, managers, selectedUsers])

  const handleEditOrgUnitManager = useCallback(
    user => {
      const newDepartments = departments.map(item => item._id)

      setShowSecondaryBlock(true)
      setSettingUser({
        ...user,
        scope: {
          ...user.scope,
          newDepartments: user.scope?.departmentReport?.filter(
            departmentId => newDepartments.includes(departmentId) || []
          ),
          departmentsFromOtherUnits: user.scope?.departmentReport?.filter(
            departmentId => !newDepartments.includes(departmentId) || []
          )
        }
      })
    },
    [departments]
  )

  const handleChangeUserScope = useCallback(
    user => {
      if (selectedUsers.find(e => e._id === user._id)) {
        setSelectedUsers([...selectedUsers.filter(e => e._id !== user._id), user])
      } else {
        setSettingUser(user)
      }
    },
    [selectedUsers]
  )

  const handleSettingUser = useCallback(
    user => {
      setShowSecondaryBlock(true)
      setSettingUser(selectedUsers.find(e => e._id === user._id))
    },
    [selectedUsers]
  )
  const handleDeleteUser = useCallback(() => {
    mutateUser({
      id: settingUser._id,
      data: {
        ...settingUser,
        scope: {
          ...settingUser.scope,
          departmentReport: settingUser.scope.departmentsFromOtherUnits || []
        }
      }
    })
    setShowSecondaryBlock(false)
    setSettingUser('')
  }, [mutateUser, settingUser])

  const isHeaderWithDeleteBtn = useMemo(() => {
    const {
      departmentReport = [],
      departmentsFromOtherUnits = [],
      newDepartments = []
    } = settingUser?.scope || {}
    return (
      !!settingUser &&
      !selectedUsers.find(user => user._id === settingUser._id) &&
      arrayEquals(departmentReport, [...departmentsFromOtherUnits, ...newDepartments])
    )
  }, [selectedUsers, settingUser])

  const primaryBlock = useMemo(
    () => (
      <UserRelationList
        onSelectAll={handleSelectAll}
        onCancel={handleClose}
        onSave={handleSave}
        isLoading={isLoadingUpdateUser}
        title={lastSelected?.name}
        enabledUserEdit
        onEditOrgUnitUser={handleEditOrgUnitManager}
        lastSelectedUnitChildrenIds={lastSelectedUnitChildrenIds}
        onChangeSearch={handleChangeSearch}
        onSelectUser={handleSelectUser}
        enabledSettingUser
        onSettingUser={handleSettingUser}
        departments={departments}
        otherUsers={managers}
        relatedUsers={orgUnitManagers}
        selectedUsers={selectedUsers}
      />
    ),
    [
      handleSelectAll,
      handleClose,
      handleSave,
      isLoadingUpdateUser,
      lastSelected?.name,
      handleEditOrgUnitManager,
      lastSelectedUnitChildrenIds,
      handleChangeSearch,
      handleSelectUser,
      handleSettingUser,
      departments,
      managers,
      orgUnitManagers,
      selectedUsers
    ]
  )

  const tree = useMemo(
    () => [filteredOrgUnitWithDepartments || orgUnitWithDepartments],
    [filteredOrgUnitWithDepartments, orgUnitWithDepartments]
  )

  if (!selectedUnits?.length) return null

  return (
    <div>
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsDrawerOpen(true)}
      >
        <img src={orgUserImg} alt="button layers" />
        <Text>Пользователи</Text>
      </Button>
      <Drawer
        closable={false}
        width={500}
        placement="right"
        onClose={handleClose}
        visible={isDrawerOpen}
      >
        <div className="EditOrgUsers">
          <HorizontalSlideTwoBlocks
            showSecondaryBlock={showSecondaryBlock}
            primaryBlock={primaryBlock}
            secondaryBlock={
              <EditOrgUser
                tree={tree}
                onChangeAddress={handleChangeAddress}
                onDeleteUser={handleDeleteUser}
                onClose={handleClose}
                onSave={handleSave}
                isLoadingUpdateUser={isLoadingUpdateUser}
                isHeaderWithDeleteBtn={isHeaderWithDeleteBtn}
                lastSelectedUnitChildrenIds={lastSelectedUnitChildrenIds}
                showSecondaryBlock={showSecondaryBlock}
                settingUser={settingUser}
                onChangeUserScope={handleChangeUserScope}
              />
            }
          />
        </div>
      </Drawer>
    </div>
  )
}
export default OrgUnitUsers
