import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd'
// для подгрузки стилей к drawer
import '../styles/themes/default.less'

export const Theme = ({ children, name = '' }) => {
  let Theme = () => <></>

  if (location?.pathname !== '/') {
    // по дефолту грузим модуль MassRecruitment
    !name && (Theme = lazy(() => import('./MassRecruitmentTheme')))
  }

  return (
    <Suspense
      fallback={
        <div className="w-100 h-100 text-center">
          <Spin spinning size="large" className="full-screen-spin" />
        </div>
      }
    >
      <Theme />
      {children}
    </Suspense>
  )
}
