import React, { useEffect, useReducer } from 'react'
import { Breadcrumb, Col, Divider, Row, Typography } from 'antd'
import { FolderFilled } from '@ant-design/icons'

import OrgStructureUnit from '../Units/OrgStructureUnit'
import { simpleReducer } from '../../../../../helpers'
import orgLayersLightImg from '../../../../../img/orgStructure/layers-light-grey.svg'
import { EditLayers } from '../../index'
import EditUnit from '../Actions/EditUnit'
import DeleteUnit from '../Actions/DeleteUnit'
import OrgUnitUsers from '../User/OrgUnitUsers'
const { Text } = Typography

function OrgStructureUnits({ orgData }) {
  const initialState = {
    selectedUnits: []
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  //@TODO попробовать заюзать хук useOrgStructureUnits
  const handleSelect = (index, item) => {
    let selectedUnits = [...state.selectedUnits]
    selectedUnits.length = index
    selectedUnits.push(item)

    setState({ selectedUnits })
  }

  //@TODO попробовать заюзать хук useOrgStructureUnits
  useEffect(() => {
    //TODO: зависимость selectedUnits обновляет сама себя после ответа апи
    // возможно нужно вынести на уровень выше где получаем данные
    // или получение данных опустить сюда
    const selectedUnits = []
    state.selectedUnits.reduce((prev, cur) => {
      const unit = prev.find(item => item._id === cur._id)
      selectedUnits.push(unit)
      return unit?.children
    }, orgData.units)
    setState({ selectedUnits })
  }, [orgData])

  //@TODO попробовать заюзать хук useOrgStructureUnits
  const getLayerData = index => {
    const prevIndex = index - 1
    if (index === 0) {
      return orgData?.units
    } else if (state.selectedUnits?.[prevIndex]) {
      return state.selectedUnits?.[prevIndex]?.children
    } else {
      return null
    }
  }

  return (
    <div className="orgItems">
      <Row className="block-title" justify="space-between" align="middle">
        <div>ОРГСТРУКТУРА</div>
        <Col>
          <Row align="middle">
            <OrgUnitUsers
              selectedUnits={state.selectedUnits}
              lastSelected={state.selectedUnits?.[state.selectedUnits.length - 1]}
            />
            <EditUnit selectedUnits={state.selectedUnits} />
            <DeleteUnit selectedUnits={state.selectedUnits} />
            <Divider type="vertical" className="header-button-divider" />
            <EditLayers layers={orgData.layers} />
          </Row>
        </Col>
      </Row>
      <Divider />
      <div className="layers-wrapper">
        <div className="layers">
          {orgData?.layers?.map((layer, index) => (
            <OrgStructureUnit
              key={layer + index}
              layerIndex={index}
              selectedUnits={state.selectedUnits}
              data={getLayerData(index)}
              onSelect={handleSelect}
              lastSelectedId={state.selectedUnits?.[state.selectedUnits?.length - 1]?._id}
            />
          ))}
        </div>
      </div>
      {state.selectedUnits.length > 0 ? (
        <Breadcrumb separator=">">
          {state.selectedUnits?.map(item => (
            <Breadcrumb.Item key={item?._id}>
              <FolderFilled />
              {item?.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      ) : (
        <Breadcrumb className="default-item">
          <Breadcrumb.Item>
            <img src={orgLayersLightImg} alt="button layers" />
            <Text>Все уровни</Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
    </div>
  )
}

export default OrgStructureUnits
