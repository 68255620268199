import React, { useState, useEffect, useContext, useMemo } from 'react'
import MaterialIcon from 'material-icons-react'
import { Tabs, message, Spin, Typography } from 'antd'
import PropTypes from 'prop-types'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'

import './CandidatePanel.less'
import { CandidateInfo, CandidateProfileEdit, History, Resume } from '../index'
import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { useCandidateByIdQuery } from '../../../../api/candidate'
import { UserContext } from '../../../../contexts/userContext'
import { hiringManagerSettingsAtom, inboxSettingsAtom } from '../../../../recoil/atoms'
import { inboxSettingsSelector } from '../../../../recoil/selectors'

const { TabPane } = Tabs
const { Text } = Typography

const CandidatePanel = ({ candidateId, onlyView, candidateInfo }) => {
  const { isRecruiter } = useContext(UserContext)
  const setInboxSettings = useSetRecoilState(inboxSettingsAtom)
  const inboxSettings = useRecoilValue(inboxSettingsSelector)
  const [hiringManagerSettings, setHiringManagerSettings] =
    useRecoilState(hiringManagerSettingsAtom)
  const [settings, setSettings] = useMemo(
    () =>
      isRecruiter
        ? [inboxSettings, setInboxSettings]
        : [hiringManagerSettings, setHiringManagerSettings],
    [inboxSettings, setInboxSettings, hiringManagerSettings, setHiringManagerSettings, isRecruiter]
  )
  const [editMode, setEditMode] = useState(false)
  const [candidate, setCandidate] = useState({})
  const [activeTabNum, setActiveTabNum] = useState('0')
  const handleEditButton = () => setEditMode(!editMode)
  const handleCloseEdit = () => setEditMode(false)
  const handleSaveEdit = () => {
    refetchCandidateData()
    setEditMode(false)
  }
  const handleTabClick = key => setActiveTabNum(key)

  /**
   * Get Candidate by id
   */
  const {
    status,
    data,
    isLoading,
    refetch: refetchCandidateData
  } = useCandidateByIdQuery(candidateId, { enabled: !!candidateId })

  useEffect(() => {
    if (status === 'success') {
      setCandidate(data?.data)
      if (!settings?.activeTab) {
        setSettings(oldValues => ({
          ...oldValues,
          activeTab: data?.data?.state?.name
        }))
      }
    }
  }, [settings?.activeTab, setSettings, data, status])
  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (status === 'error') {
      message.error('Candidate data error')
    }
  }, [status])

  return (
    <CandidatePanelContext.Provider
      value={{ candidate, setCandidate, showPanelTab: setActiveTabNum }}
    >
      {!onlyView && (
        <>
          {status !== 'error' && editMode && (
            <div className="profile-close" onClick={handleCloseEdit}>
              <MaterialIcon icon="close" size={24} color="#afafaf" />
            </div>
          )}
          {!editMode && (
            <div className="profile-edit" onClick={handleEditButton}>
              <MaterialIcon icon="edit" size={24} color="#afafaf" />
            </div>
          )}
        </>
      )}
      <div className="block-title">АНКЕТА КАНДИДАТА</div>
      {status === 'error' ? (
        <Text>К сожалению, кандидат не найден</Text>
      ) : (
        <Spin spinning={isLoading} size="large">
          {editMode ? (
            <CandidateProfileEdit onClose={handleCloseEdit} onSave={handleSaveEdit} />
          ) : (
            <Tabs defaultActiveKey="0" activeKey={activeTabNum} onTabClick={handleTabClick}>
              <TabPane tab="Персональные данные" key={0}>
                <CandidateInfo {...candidateInfo} />
              </TabPane>
              {candidate?.experience?.length > 0 && (
                <TabPane tab="Резюме" key={1}>
                  <Resume />
                </TabPane>
              )}
              <TabPane tab="История кандидата" key={2}>
                <History />
              </TabPane>
            </Tabs>
          )}
        </Spin>
      )}
    </CandidatePanelContext.Provider>
  )
}

CandidatePanel.defaultProps = {
  onlyView: false
}

CandidatePanel.propTypes = {
  candidateId: PropTypes.string.isRequired,
  candidateInfo: PropTypes.shape({
    headerInfo: PropTypes.string,
    wrapperInfoClass: PropTypes.string,
    onEDIT: PropTypes.func,
    wrapperInfoSecondaryClass: PropTypes.string,
    isEditPreferences: PropTypes.bool
  }),
  onlyView: PropTypes.bool
}

export default CandidatePanel
