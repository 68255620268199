import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { ConfigProvider, message, Spin, Table, Typography, Button, Input } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'

import { useDepartmentFindWithApprovedDemand } from '../../../../api/department'
import { simpleReducer } from '../../../../helpers'
import DepartmentPlan from './DepartmentPlan'
import './Departments.less'
import { defaultQueryOptions } from '../../../../constants'
import { renderRestaurantName } from '../../../../helpers/department'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'

const { Title } = Typography
const { Search } = Input

function DepartmentsListWithPlans({
  withSearch,
  title,
  titleStyle,
  onAction,
  action,
  tableHeight,
  footer,
  address
}) {
  const initialState = {
    departments: [],
    search: '',
    hasPlan: false
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { departmentType } = useConfigContextData()

  /**
   * Get Departments list by address - dadata
   *                      by name - search
   *
   * useDepartmentFindWithApprovedDemand - метод возвращает все рестики и дает гарантию,
   * что если у него есть одобренный план, то он там будет,
   * если одобренного плана нет, то будет просто рестик.
   * @TODO обсудить с бэком название метода.
   */
  const {
    data: departmentApprovedDemandData,
    isSuccess: isSuccessDepartmentApprovedDemand,
    isError: isErrorDepartmentApprovedDemand,
    isLoading: isLoadingDepartmentApprovedDemand
  } = useDepartmentFindWithApprovedDemand(
    {
      address: address,
      search: state.search
    },
    {
      /**
       * Dadata is expensive request - so we disable all refetch for this query
       */
      ...defaultQueryOptions,
      enabled: !!address || !!state.search
    }
  )

  useEffect(() => {
    if (isSuccessDepartmentApprovedDemand) {
      setState({
        departments: departmentApprovedDemandData?.data?.map(department => ({
          ...department,
          // На списке рестиков при записи на собес нужно показывать только утвержденные данные плана по ресторану
          ...(department.plan?.approved && {
            plan: {
              ...department.plan?.approved
            }
          })
        })),
        hasPlan: !!departmentApprovedDemandData?.data?.find(department => department.plan?.crewmen)
      })
    } else if (isErrorDepartmentApprovedDemand) {
      message.error('Ошибка поиска орг. единиц')
    }
  }, [
    departmentApprovedDemandData,
    isSuccessDepartmentApprovedDemand,
    isErrorDepartmentApprovedDemand
  ])

  const renderPlan = plan => {
    if (!plan?.crewmen) return null

    return <DepartmentPlan plan={plan} />
  }

  const columns = [
    {
      title: 'Название и адрес',
      dataIndex: 'name',
      width: 150,
      render: (text, item) => renderRestaurantName(text, item, true)
    },
    {
      title: 'План',
      dataIndex: ['plan'],
      width: state.hasPlan ? 620 : 50,
      render: renderPlan
    }
  ]

  const handleSearch = search => setState({ search })

  const handleSelectRow = department => ({
    onClick: () => {
      onAction({ ...action, step: 2, department })
    }
  })
  const handleFooter = () => {
    return (
      footer || (
        <Button className="red" onClick={() => onAction(null, null)}>
          Назад
        </Button>
      )
    )
  }

  return (
    <div className="departmentList">
      <Title level={4} className={titleStyle}>
        {title || `Выберите ${departmentType?.toLowerCase()}:`}
      </Title>
      {withSearch && (
        <div className="mb-3">
          <Search
            placeholder="Поиск"
            enterButton="Поиск"
            allowClear
            loading={isLoadingDepartmentApprovedDemand}
            onSearch={handleSearch}
          />
        </div>
      )}
      <Spin spinning={isLoadingDepartmentApprovedDemand} size="large">
        <ConfigProvider locale={ruRu}>
          <Table
            size="small"
            bordered
            scroll={{ y: tableHeight }}
            columns={columns}
            loading={isLoadingDepartmentApprovedDemand}
            rowKey="_id"
            dataSource={state.departments}
            onRow={handleSelectRow}
            footer={handleFooter}
          />
        </ConfigProvider>
      </Spin>
    </div>
  )
}

export default DepartmentsListWithPlans

DepartmentsListWithPlans.propTypes = {
  withSearch: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.string,
  address: PropTypes.string,
  tableHeight: PropTypes.number,
  footer: PropTypes.node,
  action: PropTypes.shape().isRequired
}
DepartmentsListWithPlans.defaultProps = {
  withSearch: false,
  title: '',
  titleStyle: '',
  address: '',
  footer: null,
  tableHeight: 230
}
