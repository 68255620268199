import React, { useMemo, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { generatePath, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useRoutes } from '../../../../hooks/useRoutes'
import {
  PASSWORD_RECOVERY,
  FORGOT_PASSWORD,
  EXTERNAL_BUTTON,
  PLANNING_PERIOD_PAGE,
  PLANNING_DEPARTMENTS_PAGE
} from '../../../../constants/routes'
import { emptyRouteParams, modules } from '../../../../constants'
import LinkWrapper from '../../../../components/LinkWrapper'
import { UserContext } from '../../../../contexts/userContext'
import { ConfigContext } from '../../../../contexts/configContext'

const { Sider } = Layout

const Sidebar = ({ collapsed }) => {
  // Don't show on Auth page
  const { pathname } = useLocation()
  const { user } = useContext(UserContext)
  const {
    features: { data: features }
  } = useContext(ConfigContext)

  const { routes } = useRoutes(user, modules.massRecruitment)

  const selectedKeys = useMemo(() => {
    let name = `/${pathname.split('/')?.[1]}`
    const alias = generatePath(PLANNING_PERIOD_PAGE, emptyRouteParams)
    if (name === alias) {
      name = generatePath(PLANNING_DEPARTMENTS_PAGE, emptyRouteParams)
    }
    return name
  }, [pathname])

  const filteredRoutes = useMemo(
    () =>
      routes
        ?.filter(route => route.name)
        ?.filter(route =>
          route.featureToggle ? features?.some(feature => route.featureToggle === feature) : true
        ),
    [features, routes]
  )

  const getMenuRoutes = useCallback(route => {
    const linkProps = route.useHtmlLink
      ? { href: route.path }
      : { to: generatePath(route.path, emptyRouteParams) }
    return (
      <Menu.Item key={generatePath(route.path, emptyRouteParams)}>
        {route.name}
        <LinkWrapper {...linkProps} />
      </Menu.Item>
    )
  }, [])

  if (
    pathname === '/' ||
    pathname === PASSWORD_RECOVERY ||
    pathname === FORGOT_PASSWORD ||
    pathname === EXTERNAL_BUTTON
  )
    return null

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={0} width={250}>
      <div className="sidebar-logo" />
      <Menu mode="inline" selectedKeys={selectedKeys}>
        {filteredRoutes?.map(getMenuRoutes)}
      </Menu>
    </Sider>
  )
}
export default Sidebar

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired
}
