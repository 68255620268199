import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { ConfigContext } from '../../../../../contexts/configContext'
import { useReferences } from '../../../../../hooks/useReferences'
import { colSettings } from '../../../../../constants'

const { Option } = Select

const RequestTypeItem = ({ value = null, onChange = () => {}, disabled = false }) => {
  const { references } = useContext(ConfigContext)

  useReferences({
    utms: true,
    utmsQuery: value
  })

  return (
    <Form.Item
      label="Тип обращения"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="requestType"
    >
      <Select
        value={value}
        onChange={onChange}
        size="middle"
        placeholder="Выберите тип обращения"
        disabled={disabled}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {references.data.requestTypes?.map(c => (
          <Option key={c} value={c}>
            {c}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default RequestTypeItem

RequestTypeItem.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
