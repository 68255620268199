import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { concatUtmValuesByKey } from '../../../helpers'
import { ConfigContext } from '../../../contexts/configContext'
import { colSettings } from '../../../constants'

const { Option } = Select

const UtmMediumItem = ({ value = null, onChange = () => {}, disabled = false }) => {
  const { references } = useContext(ConfigContext)

  const list = useMemo(
    () => concatUtmValuesByKey(references.data.utms, 'utmMediums'),
    [references.data.utms]
  )
  return (
    <Form.Item
      label="Группа источников"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="utm_medium"
    >
      <Select
        value={value}
        onChange={onChange}
        size="middle"
        placeholder="Выберите значение"
        notFoundContent="Нет вариантов"
        disabled={disabled}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {list.map(c => (
          <Option key={c} value={c}>
            {c}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default UtmMediumItem

UtmMediumItem.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
