import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'

import { appConfig } from '../../../../../constants/appConfig'
import { colSettings } from '../../../../../constants'

const { Option } = Select

function DepartmentStatusItem({ disabled, label }) {
  return (
    <Form.Item
      label={label}
      name="status"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
    >
      <Select disabled={disabled} defaultValue={appConfig.departmentStatusesKeys.opened}>
        {appConfig.departmentStatuses.map(status => (
          <Option key={status.key} value={status.key}>
            {status.value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

DepartmentStatusItem.defaultProps = {
  disabled: false,
  label: 'Статус'
}

DepartmentStatusItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string
}

export default DepartmentStatusItem
