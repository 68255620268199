import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'

export const ORG_STRUCTURE_KEY = 'orgStructure'

const fetchGetOrgStructure = () => {
  return api.get({ path: ORG_STRUCTURE_KEY })
}
const fetchAddOrgStructure = data => {
  return api.put({ path: ORG_STRUCTURE_KEY, data })
}
const fetchDeleteOrgUnit = data => {
  return api.del({ path: `${ORG_STRUCTURE_KEY}/unit`, data })
}

export function useGetOrgStructure(options) {
  return useQuery([ORG_STRUCTURE_KEY], fetchGetOrgStructure, options)
}
export function useMutateAddOrgStructure() {
  return useMutation(fetchAddOrgStructure, {
    onSuccess: data => {
      queryClient.setQueryData(ORG_STRUCTURE_KEY, data)
    }
  })
}
export function useMutateDeleteOrgUnit() {
  return useMutation(fetchDeleteOrgUnit, {
    onSuccess: data => {
      queryClient.setQueryData(ORG_STRUCTURE_KEY, data)
    }
  })
}
