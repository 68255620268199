import { externalName } from './'

export const CANDIDATE_PAGE = '/candidates/:id?'
export const NEW_CANDIDATE_PAGE = '/newCandidate'
export const FIND_CANDIDATE_PAGE = '/findCandidate/:id?'
export const PLANNING_PAGE = '/planning/:id?'
export const PLANNING_DEPARTMENTS_PAGE = '/planningDepartments/:id?'
export const PLANNING_PERIOD_PAGE = '/planningPeriod/:year?/:month?'
export const REPORT_PAGE = '/report'
export const ANALYTICS_PAGE = '/analytics'
export const PROFILE_PAGE = '/profile'
export const SETTINGS_PAGE = '/settings'
export const USERS_PAGE = '/users'
export const DEPARTMENT_PAGE = '/departments/:id?'
export const PLANNING_EMPTY_PAGE = '/emptyPlanning/:year?/:month?/:departmentId?'
export const CALENDAR_PAGE = '/calendar'
export const ORG_STRUCTURE_PAGE = '/orgStructure'
export const AGENCIES_PAGE = '/agencies/:id?'
export const DEDUPLICATION_PAGE = '/deduplication/:id?'
export const INTEGRATIONS_PAGE = '/integrations'
export const SOURCES_PAGE = '/sources'

export const PASSWORD_RECOVERY = '/passwordRecovery'
export const FORGOT_PASSWORD = '/forgotPassword'

export const EXTERNAL_BUTTON = '/' + externalName
export const EXTERNAL_BUTTON_CANDIDATE_PAGE = '/' + externalName + '/:id?'

export const NO_AUTH_CANDIDATE_PAGE = '/noAuth/candidate/:id'
