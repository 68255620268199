import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form, Spin, message, Select } from 'antd'
import { appConfig } from '../../../../constants/appConfig'
import {
  RoleItem,
  NameItem,
  EmailItem,
  PhoneItem,
  ActiveFlagItem
} from '../../Components/FormItems/UserItems'
import './UserModalForm.less'
import { useMutateCreateUser, useMutateUser } from '../../../../api/users'
import { userShape } from '../../../../constants/propTypesShapes'
import { formatPhoneNumberForApi } from '../../../../helpers'
import { colSettings } from '../../../../constants'
import { UserContext } from '../../../../contexts/userContext'
import { getDisabledRolesForUser } from '../../../../helpers/action'
import AgencyItem from '../FormItems/UserItems/AgencyItem'
import { useAgenciesQuery } from '../../../../api/agency'
import { ConfigContext } from '../../../../contexts/configContext'

const modes = appConfig.userFormModes
const { Option } = Select

const modalTitle = {
  [modes.create]: 'Новый пользователь',
  [modes.edit]: 'Редактирование пользователя'
}

const validRoles = [
  appConfig.roles.manager,
  appConfig.roles.recruiter,
  appConfig.roles.prioritySelector,
  appConfig.roles.admin
]

const validRolesWithHM = [appConfig.roles.hiringManager, ...validRoles]

function UserModalForm({
  afterSave,
  onCreateMode,
  user,
  withoutButton = false,
  isOpen = false,
  roles,
  roleDisable = false,
  onClose,
  userPositions
}) {
  const [form] = Form.useForm()
  const { isAdmin, user: currentUser } = useContext(UserContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isRecruiter, setIsRecruiter] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const [isPrioritySelector, setIsPrioritySelector] = useState(false)
  const [disabledRoles, setDisabledRoles] = useState([])
  const mode = user?._id ? modes.edit : modes.create

  const {
    features: { data: features }
  } = useContext(ConfigContext)

  const isEditYourself = useMemo(
    () => isAdmin && currentUser._id === user?._id,
    [isAdmin, currentUser, user]
  )

  const {
    mutate: createUser,
    data: createData,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
    isLoading: isLoadingCreate,
    error: errorCreate
  } = useMutateCreateUser()

  const {
    features: { data: featureList, isLoaded: isLoadedFeatures }
  } = useContext(ConfigContext)
  const { data: agenciesData } = useAgenciesQuery(null, {
    enabled: isLoadedFeatures && featureList?.includes(appConfig.features.agencies)
  })

  const agencies = useMemo(() => {
    return agenciesData?.data?.filter(({ locked }) => !locked) || []
  }, [agenciesData?.data])

  useEffect(() => {
    if (isSuccessCreate) {
      afterSave(createData?.data)
      setIsModalVisible(false)
    } else if (isErrorCreate) {
      if (errorCreate.response?.status === 409) {
        message.error('Ошибка: пользователь с таким email уже существует')
      } else {
        message.error('Ошибка создания пользователя')
      }
    }
  }, [createData, isSuccessCreate, isErrorCreate, afterSave, errorCreate])

  const {
    mutate: saveUser,
    isSuccess: isSuccessSave,
    isError: isErrorSave,
    isLoading: isLoadingSave
  } = useMutateUser()

  useEffect(() => {
    if (isSuccessSave) {
      afterSave()
      setIsModalVisible(false)
    } else if (isErrorSave) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isSuccessSave, isErrorSave, afterSave])

  const prepareFormByRolesAndAgency = useCallback((roles, agencyId) => {
    setIsRecruiter(roles?.includes(appConfig.roles.recruiter))
    setIsManager(roles?.includes(appConfig.roles.manager))
    setIsPrioritySelector(roles?.includes(appConfig.roles.prioritySelector))
    setDisabledRoles(getDisabledRolesForUser(roles, agencyId))
  }, [])

  useEffect(() => {
    if (user?._id) {
      form.setFieldsValue({
        ...user,
        active: !user.locked,
        phone: user.phone ? formatPhoneNumberForApi(user.phone) : '',
        roles: user.roles,
        position: user.position?._id,
        /**
         * агентство у юзера может быть заблокировано. значит в общем списке агентств в селекте его не будет
         * в этом случае в селекте покажется идешка агенства. чтобы этого избежать, только в этом случае
         * передаем на форму в селект имя этого заблокированного агенства
         */
        agency:
          agenciesData?.data?.find(agency => agency._id === user.agency?._id && agency.locked)
            ?.name || user.agency?._id
      })
      prepareFormByRolesAndAgency(user.roles, user.agency?._id)
      setIsModalVisible(true)
    }
  }, [prepareFormByRolesAndAgency, user, form, agenciesData])

  useEffect(() => {
    if (roles?.length) {
      form.setFieldsValue({
        roles: roles
      })
      prepareFormByRolesAndAgency(roles)
    }
  }, [prepareFormByRolesAndAgency, roles, form])

  const showModal = () => {
    onCreateMode && onCreateMode()
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    onClose && onClose()
    setIsModalVisible(false)
  }

  const handleAfterClose = () => {
    setIsRecruiter(false)
    setIsManager(false)
    setIsPrioritySelector(false)
    setDisabledRoles([])
    form.resetFields()
  }

  const getAgencyIdForm = useCallback(
    agencyId => {
      if (!isRecruiter || !agencyId) {
        return null
      }
      return agencies?.find(agency => agency._id === agencyId)?._id || user.agency?._id
    },
    [isRecruiter, agencies, user]
  )

  const handleFinish = async values => {
    if (mode === modes.edit) {
      await saveUser({
        id: user._id,
        data: {
          name: values.name,
          phone: values.phone,
          position: values.position,
          locked: !values.active,
          ...(values.roles.length > 0 && { roles: values.roles }),
          agency: getAgencyIdForm(values.agency)
        }
      })
    } else {
      await createUser({
        ...values,
        login: values.email,
        locked: !values.active
      })
    }
  }

  const handleFormChange = (changedValues, allValues) => {
    prepareFormByRolesAndAgency(allValues.roles, allValues.agency)
  }

  const isShownAgencyItem = useMemo(() => {
    if (user?.agency?._id && !features.includes(appConfig.features.agencies)) {
      return true
    } else if (
      features.includes(appConfig.features.agencies) &&
      isRecruiter &&
      !isPrioritySelector
    ) {
      return true
    }
    return false
  }, [isRecruiter, isPrioritySelector, features, user?.agency?._id])

  return (
    <div>
      {!withoutButton && (
        <Button type="primary" onClick={showModal}>
          + Добавить пользователя
        </Button>
      )}
      <Modal
        width={480}
        title={modalTitle[mode]}
        visible={isModalVisible || isOpen}
        cancelText="Отмена"
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoadingCreate || isLoadingSave}
        className="userModalForm"
        afterClose={handleAfterClose}
      >
        <Spin spinning={isLoadingCreate || isLoadingSave}>
          <Form form={form} onFinish={handleFinish} onValuesChange={handleFormChange}>
            <RoleItem
              roles={
                mode === modes.edit && user?.roles?.includes(appConfig.roles.hiringManager)
                  ? validRolesWithHM
                  : validRoles
              }
              disabled={roleDisable}
              labelCol={colSettings.full}
              size="large"
              adminOptionDisabled={isEditYourself}
              disabledRoles={disabledRoles}
            />
            {isManager && (
              <Form.Item
                label="Позиция менеджера"
                labelCol={colSettings.full}
                wrapperCol={colSettings.full}
                labelAlign="left"
                required={isManager}
                name="position"
                rules={[
                  {
                    required: isManager,
                    message: 'Выберите позицию'
                  }
                ]}
              >
                <Select size="large" placeholder="Выберите позицию">
                  {userPositions?.map(userPosition => (
                    <Option key={userPosition._id} value={userPosition._id}>
                      {userPosition.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <NameItem labelCol={colSettings.full} size="large" />
            <EmailItem size="large" labelAlign="left" required disabled={mode === modes.edit} />
            {isShownAgencyItem && <AgencyItem agencies={agencies} labelCol={colSettings.full} />}
            {isRecruiter && <PhoneItem className="ant-input-lg" labelAlign="left" required />}
            <ActiveFlagItem />
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

UserModalForm.propTypes = {
  afterSave: PropTypes.func.isRequired,
  onCreateMode: PropTypes.func,
  user: userShape
}

export default UserModalForm
