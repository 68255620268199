import { api, queryClient } from '../api'
import { useMutation } from 'react-query'
import { PLAN_KEY, PLANS_KEY } from '../plan'
import { DEPARTMENT_APPROVED_DEMAND_KEY, DEPARTMENT_KEY } from '../department'

const createOrUpdatePlan = data => {
  return api.post({ path: 'flow/plan/createOrUpdate', data })
}

export function useMutatePlan() {
  return useMutation(createOrUpdatePlan, {
    onSuccess: data => {
      // Один и тот же план может запрашиваться по ид или периоду. Обновим сразу 2 кэша для одного плана.
      queryClient.setQueryData([PLAN_KEY, data.data.plan._id], { data: data.data.plan })
      queryClient.setQueryData([PLAN_KEY, `${data.data.plan.year}/${data.data.plan.month}`], {
        data: data.data.plan
      })
      // @TODO setQueryData([PLANS_KEY, ...
      queryClient.refetchQueries(PLANS_KEY)
      queryClient.refetchQueries([DEPARTMENT_KEY, 'noDemand'])
    }
  })
}

const stopPlan = data => {
  return api.post({ path: 'flow/plan/stop', data })
}

export function useMutateStopPlan() {
  return useMutation(stopPlan, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([PLAN_KEY, `${variables.year}/${variables.month}`], {
        data: data.data.plan
      })
    }
  })
}

const continuePlan = data => {
  return api.post({ path: 'flow/plan/continue', data })
}

export function useMutateContinuePlan() {
  return useMutation(continuePlan, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([PLAN_KEY, `${variables.year}/${variables.month}`], {
        data: data.data.plan
      })
    }
  })
}

const approvePlan = data => {
  return api.post({ path: 'flow/plan/approve', data })
}

export function useMutateApprovePlan() {
  return useMutation(approvePlan, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([PLAN_KEY, variables.planId], { data: data.data.plan })
      queryClient.refetchQueries(PLANS_KEY)
    }
  })
}

const rejectPlan = data => {
  return api.post({ path: 'flow/plan/reject', data })
}

export function useMutateRejectPlan() {
  return useMutation(rejectPlan, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([PLAN_KEY, variables.planId], { data: data.data.plan })
      queryClient.refetchQueries(PLANS_KEY)
    }
  })
}

const switchPriority = data => {
  return api.post({ path: 'flow/plan/switchPriority', data })
}

export function useMutateSwitchPriority() {
  return useMutation(switchPriority, {
    onSuccess: () => {
      // TODO: сделать обновление кэша таблицы (пока перезапрашиваем)
      queryClient.refetchQueries(DEPARTMENT_APPROVED_DEMAND_KEY)
    }
  })
}
