import React, { useContext } from 'react'
import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { Typography } from 'antd'
import moment from 'moment'
import { sortBy } from '../../../../helpers'
import { appConfig } from '../../../../constants/appConfig'
import { nbsp } from '../../../../constants'

const { Title, Text } = Typography

function Resume() {
  const { candidate } = useContext(CandidatePanelContext)

  const renderItem = item => {
    return (
      <div key={item._id} className="mb-3">
        <Title level={5}>{item.company}</Title>
        {item.from && item.to && (
          <>
            <Text>{moment(item.from).format(appConfig.formats.shortDate)}</Text>
            {nbsp}-{nbsp}
            <Text>{moment(item.to).format(appConfig.formats.shortDate)}</Text>
          </>
        )}
        <div>
          <Text strong>{item.position}</Text>
        </div>
        <div className="description">{item.description}</div>
      </div>
    )
  }

  return (
    <div className="resume">
      {candidate?.experience?.sort(sortBy('from')).reverse().map(renderItem)}
    </div>
  )
}

export default Resume
