import React, { useCallback } from 'react'
import { Button, Modal } from 'antd'
import { numberDeclension } from '../../../../helpers'

export default function ModalPhoneNotice({
  isVisible = false,
  onClose,
  onOk,
  list,
  onSave,
  loading = false
}) {
  const handleOk = useCallback(() => onOk?.(), [onOk])

  const handleCancel = useCallback(() => onClose?.(), [onClose])

  return (
    <Modal
      title="Добавьте номер телефона"
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="skip" type="link" onClick={onSave} loading={loading}>
          Пропустить {list?.length}{' '}
          {numberDeclension(list?.length, ['пользователя', 'пользователей', 'пользователей'])}
        </Button>,
        <Button key="add" type="primary" onClick={handleOk} loading={loading}>
          Добавить {list?.length > 1 ? 'телефоны' : 'телефон'}
        </Button>
      ]}
    >
      <p>
        У{' '}
        <b>
          {list?.length}{' '}
          {numberDeclension(list?.length, ['пользователя', 'пользователей', 'пользователей'])}
        </b>{' '}
        отсутствует номер телефона. Пожалуйста, добавьте номер телефона в...
      </p>
    </Modal>
  )
}
