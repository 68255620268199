import moment from 'moment-timezone'
//TODO: при добавдении языков переделать ленивую загрузку
import 'moment/locale/ru' // обязательно импорт, просто указать нельзя!

// moment.tz.setDefault('Europe/Moscow')
const setLanguage = locale => {
  switch (locale) {
    case 'en': {
      moment.locale('en')
      break
    }
    default:
      moment.locale('ru')
  }
}
export default setLanguage
