import React, { useReducer, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, AutoComplete, message, Spin, Col, Row } from 'antd'

import { debounce, simpleReducer } from '../../../../../helpers'
import { colSettings } from '../../../../../constants'
import { EmailItem } from '../CandidateProfileItems'
import { appConfig } from '../../../../../constants/appConfig'
import { useUsers } from '../../../../../api/users'

const ManagerItem = ({
  onChange = () => {},
  onSelect = () => {},
  onChangeEmail = () => {},
  name = '',
  email = '',
  role,
  inputName,
  required = false
}) => {
  const [state, setState] = useReducer(simpleReducer, {
    email,
    name,
    suggestions: []
  })

  const {
    data: usersData,
    isSuccess: isSuccessUsers,
    isError: isErrorUsers,
    isLoading: isLoadingUsers
    // refetch: refetchUsers
  } = useUsers(
    {
      filters: {
        email: state.email,
        name: state.name,
        role
      }
    },
    {
      enabled: !!state.name
    }
  )

  useEffect(() => {
    if (isSuccessUsers) {
      setState({
        suggestions: usersData?.data?.map(data => ({ data, value: data.name })) || []
      })
    } else if (isErrorUsers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [usersData, isSuccessUsers, isErrorUsers])

  const handleSelect = value => {
    const data = state.suggestions?.find(s => s.value === value)?.data
    onSelect(data)
  }

  const handleChange = value => {
    // data необходима чтобы понять был ли выбор из селектора или имя введено вручную
    const data = state.suggestions?.find(s => s?.data?.name === value)
    onChange(value, data)
  }

  const getSuggestions = useMemo(
    () =>
      debounce(query => {
        setState({ name: query })
      }, 800),
    []
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name={inputName}
            value={state.name}
            rules={[
              {
                required,
                message: 'Введите фамилию'
              }
            ]}
          >
            <AutoComplete
              options={state.suggestions}
              onSelect={handleSelect}
              onChange={handleChange}
              onSearch={getSuggestions}
              placeholder="Введите фамилию"
              dropdownMatchSelectWidth={false}
              notFoundContent={isLoadingUsers ? <Spin size="small" /> : null}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <EmailItem
            name={role + 'Email'}
            required={required}
            onChange={onChangeEmail}
            label={''}
          />
        </Col>
      </Row>
    </>
  )
}

export default ManagerItem

ManagerItem.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onChangeEmail: PropTypes.func,
  role: PropTypes.oneOf([appConfig.roles.hiringManager, appConfig.roles.manager]).isRequired,
  inputName: PropTypes.string.isRequired,
  email: PropTypes.string,
  name: PropTypes.string
}
