import { lazy } from 'react'

export const Auth = lazy(() => import('./Auth/Auth'))
export const PasswordRecovery = lazy(() => import('./PasswordRecovery/PasswordRecovery'))
export const ForgotPassword = lazy(() => import('./ForgotPassword/ForgotPassword'))
export const NewCandidate = lazy(() => import('./NewCandidate/NewCandidate'))
export const CandidateList = lazy(() => import('./CandidatesList/CandidatesList'))
export const DepartmentsList = lazy(() => import('./DepartmentsList/DepartmentsList'))
export const FindCandidateList = lazy(() => import('./FindCandidateList/FindCandidateList'))
export const DeduplicationList = lazy(() => import('./DeduplicationList/DeduplicationList'))
/**
 * Recruiter screens
 */
export const RecruiterStatusGroupMenu = lazy(() =>
  import('./CandidatesList/Recruiter/RecruiterStatusGroupMenu')
)
/**
 * Settings screens
 */
export const Settings = lazy(() => import('./Settings/Settings'))
export const Users = lazy(() => import('./Users/Users'))

/**
 * Profile screens
 */
export const Profile = lazy(() => import('./Profile/Profile'))
/**
 * Planning screens
 */
export const Planning = lazy(() => import('./Planning/Planning'))
export const PlanningDepartments = lazy(() => import('./Planning/PlanningDepartments'))
export const PlanningTable = lazy(() => import('./Planning/PlanningTable'))
export const DepartmentNoPlanningTable = lazy(() => import('./Planning/DepartmentNoPlanningTable'))
/**
 * Report screens
 */
export const ReportTable = lazy(() => import('./Report/ReportTable'))
/**
 * Calendar screens
 */
export const Calendar = lazy(() => import('./Calendar/Calendar'))
/**
 * Analytics screens
 */
export const Analytics = lazy(() => import('./Analytics/Analytics'))
/**
 * OrgStructure screens
 */
export const OrgStructure = lazy(() => import('./OrgStructure/OrgStructure'))
/**
 * AgencyList screens
 */
export const AgencyList = lazy(() => import('./AgencyList/AgencyList'))
/**
 * Integrations screens
 */
export const Integrations = lazy(() => import('./Integrations/Integrations'))
/**
 * Sources screens
 */
export const Sources = lazy(() => import('./Sources/Sources'))
