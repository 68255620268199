import { createContext } from 'react'
import PropTypes from 'prop-types'
import { referencesShape, settingsShape } from '../constants/propTypesShapes'
import { appConfig } from '../constants/appConfig'

export const initReferences = {
  citizenships: [],
  utms: [], // utm метки полученные по определенному значению поля requestType
  requestTypes: [],
  sources: [],
  mediums: [],
  inboxStates: Object.values(appConfig.statuses.values),
  cities: []
}
export const ConfigContext = createContext({
  references: {
    data: {},
    setData: () => {}
  },
  settings: {
    data: {},
    setData: () => {}
  },
  features: {
    data: null,
    setData: () => {},
    isLoaded: false
  }
})

ConfigContext.Provider.propTypes = {
  references: PropTypes.shape({
    data: referencesShape,
    setData: PropTypes.func
  }),
  settings: PropTypes.shape({
    data: settingsShape,
    setData: PropTypes.func
  }),
  features: PropTypes.shape({
    data: PropTypes.array,
    setData: PropTypes.func,
    isLoaded: PropTypes.bool
  })
}
