import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, message, Modal } from 'antd'
import { useMutateApprovePlan, useMutateRejectPlan } from '../../../../api/flow/plan'
import { isApproved, isRejected } from '../../../../helpers/plan'
import { planShape } from '../../../../constants/propTypesShapes'

function ManagerButtons({ plan = {}, planForm = null }) {
  const { confirm } = Modal
  const {
    mutate: mutateApprove,
    isLoading: isLoadingApprove,
    isError: isErrorApprove
  } = useMutateApprovePlan()
  const {
    mutate: mutateReject,
    isLoading: isLoadingReject,
    isError: isErrorReject
  } = useMutateRejectPlan()

  useEffect(() => {
    if (isErrorApprove || isErrorReject) {
      message.error('Ошибка изменения статуса планирования')
    }
  }, [isErrorApprove, isErrorReject])

  const approvePlan = async () => {
    confirm({
      title: 'Утвердить планирование?',
      content: 'Отменить действие будет нельзя',
      okText: 'Утвердить',
      cancelText: 'Отмена',
      onOk: async () => {
        await mutateApprove({
          ...planForm?.getFieldsValue(['comment']),
          planId: plan._id
        })
      }
    })
  }
  const rejectPlan = async () => {
    confirm({
      title: 'Отклонить планирование?',
      content: 'Отменить действие будет нельзя',
      okText: 'Отклонить',
      cancelText: 'Отмена',
      onOk: async () => {
        await mutateReject({
          ...planForm?.getFieldsValue(['comment']),
          planId: plan._id
        })
      }
    })
  }

  if (isApproved(plan) || isRejected(plan) || !Object.keys(plan).length) {
    return null
  }

  return (
    <>
      <Button
        type="primary"
        className="green"
        size="large"
        loading={isLoadingApprove}
        onClick={approvePlan}
      >
        Утвердить
      </Button>
      <Button
        type="primary"
        className="red"
        size="large"
        loading={isLoadingReject}
        onClick={rejectPlan}
      >
        Отклонить
      </Button>
    </>
  )
}

ManagerButtons.propTypes = {
  plan: planShape,
  planForm: PropTypes.object
}

export default ManagerButtons
