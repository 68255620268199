import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, message, Col, Row, Select } from 'antd'

import { colSettings } from '../../../../../constants'
import { EmailItem } from '../CandidateProfileItems'
import { appConfig } from '../../../../../constants/appConfig'
import { useUsers } from '../../../../../api/users'
import UserModalForm from '../../User/UserModalForm'

const { Option } = Select
const ManagerItemSelect = ({ role = appConfig.roles.manager, form }) => {
  const [isOpenNewManagers, setIsOpenNewManagers] = useState(false)
  const {
    data: usersData,
    isSuccess: isSuccessUsers,
    isError: isErrorUsers,
    isLoading: isLoadingUsers
    // refetch: refetchUsers
  } = useUsers({
    filters: {
      role: [role]
    }
  })

  useEffect(() => {
    if (isErrorUsers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [usersData, isSuccessUsers, isErrorUsers])

  const handleSelect = id => {
    if (id) {
      if (id === 'new') {
        setIsOpenNewManagers(true)
      } else {
        const data = managerList?.find(user => user._id === id)
        form.setFieldsValue({ managerEmail: data.email, manager: data._id })
      }
    }
  }

  const managerList = useMemo(() => {
    if (isSuccessUsers) {
      const newManager = {
        _id: 'new',
        name: '+ Создать нового'
      }
      return [newManager, ...usersData?.data]
    }
    return []
  }, [usersData?.data, isSuccessUsers])
  const handleAfterSave = data => {
    form.setFieldsValue({ managerEmail: data?.email, manager: data?._id })
    setIsOpenNewManagers(false)
  }
  const handleClose = () => {
    setIsOpenNewManagers(false)
    form.resetFields([role, role + 'Email'])
  }
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item labelCol={colSettings.full} wrapperCol={colSettings.full} name={role}>
            <Select loading={isLoadingUsers} onChange={handleSelect}>
              {managerList.map(user => (
                <Option key={user._id} value={user._id}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <EmailItem name={role + 'Email'} required disabled label={''} />
        </Col>
      </Row>
      <UserModalForm
        roleDisable
        roles={[role]}
        withoutButton
        isOpen={isOpenNewManagers}
        afterSave={handleAfterSave}
        onClose={handleClose}
      />
    </>
  )
}

export default ManagerItemSelect

ManagerItemSelect.propTypes = {
  role: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string
}
