import { Col, Row, Typography } from 'antd'
import React from 'react'
import { langCases } from '../constants/appConfig'

const { Text } = Typography

export const renderRestaurantName = (text, item, priority) => {
  return (
    <Col>
      <Row>
        <Text>{text}</Text>
      </Row>
      <Row>
        <Text type="secondary">{item?.address}</Text>
      </Row>
      {priority && item?.plan?.priority && (
        <Row>
          <Text type="success">Приоритет</Text>
        </Row>
      )}
    </Col>
  )
}
export const renderNameWithEmail = text => {
  return (
    <Col>
      <Row>
        <Text>{text.name}</Text>
      </Row>
      <Row>
        <Text type="secondary">{text.email}</Text>
      </Row>
    </Col>
  )
}

export const getDepartmentSingularList = (departmentTypes = []) => {
  return departmentTypes.reduce((prev, curr) => {
    prev.push(curr.singular[langCases.nominative])
    return prev
  }, [])
}
