import { createContext } from 'react'
import PropTypes from 'prop-types'
import { appointmentShape } from '../constants/propTypesShapes'

export const AppointmentContext = createContext({
  appointment: {},
  setAppointment: () => {}
})

AppointmentContext.Provider.propTypes = {
  appointment: appointmentShape,
  setAppointment: PropTypes.func
}
